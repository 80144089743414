import React, { useContext, useEffect } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import NavigateButton from "../../../components/buttonsNavigate";
import hocStarredPage from "../../../components/hocStarredPage";

const ChangeStatusPage = () => {
  const history = useNavigate();

  return (
    <>
      <div className=" h-full bg-white">
        <div className=" mb-5 sticky top-0 flex flex-col bg-white">
          {/* header */}
          <div className="w-full bg-dbase h-16">
            <div className="container flex relative gap-7 flex-row justify-start w-full text-white h-full my-auto">
              <button onClick={() => history(-1)}>
                <FaArrowLeft />
              </button>
              <h2 className="text-xl my-auto">Change Status</h2>
            </div>
          </div>
        </div>

        <div className="  grid grid-cols-2 container gap-x-6 gap-y-4">
          <NavigateButton
            name={"GIVEN"}
            color={"bg-[rgb(7,89,133)]"}
            onclick={() => history("given")}
          />
          <NavigateButton
            name={"RECEIVED FROM SELLER"}
            color={"bg-[rgb(147,51,234)]"}
            onclick={() => history("receivedfromseller")}
          />
          <NavigateButton
            name={"GIVEN TO SELLER"}
            color={"bg-[rgb(107,114,128)]"}
            onclick={() => history("giventoseller")}
          />
          <NavigateButton
            name={"FAILED"}
            color={"bg-[#bf1b26]"}
            onclick={() => history("failed")}
          />
          {/* <NavigateButton
            name={"RECEIVE IN STORE"}
            color={"bg-[rgb(34,197,94)]"}
            onclick={() => history("receivedinstore")}
          />
          <NavigateButton
            name={"COMPLETE STORE"}
            color={"bg-[rgb(245,158,11)]"}
            onclick={() => history("completestore")}
          />
          <NavigateButton
            name={"BACK TO STOCK"}
            color={"bg-[rgb(253,224,71)]"}
            onclick={() => history("backtostock")}
          />
          <NavigateButton
            name={"RECEIVED IN STOCK"}
            color={"bg-[rgb(23,37,84)]"}
            onclick={() => history("receivedinstock")}
          /> */}
          <NavigateButton
            name={"READY"}
            color={"bg-[rgb(147,51,234)]"}
            onclick={() => history("ready")}
          />
        </div>
      </div>
    </>
  );
};

export default hocStarredPage(ChangeStatusPage);
