import React, { useContext, useEffect, useRef, useState } from "react";
import {
  FaBox,
  FaCheckCircle,
  FaCheckDouble,
  FaEllipsisV,
  FaHistory,
  FaListUl,
  FaPaperPlane,
  FaPlusCircle,
  FaQrcode,
  FaSearch,
  FaShoppingCart,
  FaSyncAlt,
  FaWifi,
} from "react-icons/fa";
import { IoSettingsSharp } from "react-icons/io5";
import NavigateButton from "../components/buttonsNavigate";
import { useNavigate } from "react-router-dom";
import { TabBarContext } from "../contexts/TabBarContext";
import { HiArrowPath } from "react-icons/hi2";
import { CompanyContext } from "../contexts/companyCotext";
import { MdOutlineWifiOff } from "react-icons/md";
import * as serviceWorkerRegistration from "../serviceWorkerRegistration";
import { BiTransferAlt } from "react-icons/bi";
import { LiaTruckPickupSolid } from "react-icons/lia";
import hocStarredPage from "../components/hocStarredPage";
import Cookies from "js-cookie";
import NavigateToFavorite from "../components/navigateToFavorite";
import { GoVersions } from "react-icons/go";
import { TiArrowSortedDown } from "react-icons/ti";
import BurgerMenu from "../components/burgerMenu";
import { GiHamburgerMenu } from "react-icons/gi";

const Home = () => {
  const [stateTab, dispatchTab] = useContext(TabBarContext);
  const [stateCom, dispatchCom] = useContext(CompanyContext);
  const [openMenu, setOpenMenu] = useState(false);
  const menuRefButton = useRef(null);
  const [wifiOn, setWifiOn] = useState(false);
  const [wifiOnRemove, setWifiOnRemove] = useState(true);
  const [isFavorite, setIsFavorite] = useState(false);
  const [version, setVersion] = useState("");
  const favoritePage = Cookies.get("favoritePage");
  const navigate = useNavigate();
  const [hasNavigated, setHasNavigated] = useState(false);
  const [availableUpdate, setAvailbleUpdate] = useState(false);
  const [host, setHost] = useState(window.location.host);
  const [storeMenu, setStoreMenu] = useState(true);
  const [selectedStore, setSelectedStore] = useState(null);
  const [parsedStores, setParsedStores] = useState([]);
  const store_name = localStorage.getItem("store_name");

  useEffect(()=>{
    if(host.startsWith("localhost") ||  host === "pda.ishtari.com"|| host === "pda.ishtari.com.gh") {
      setStoreMenu(false)
    }
  },[])
 
    

  useEffect(() => {
    setHost(window.location.host);
  }, [host]);

const showChangeOptionUpc = host.startsWith("localhost") || host === "pda.flo-lebanon.com" || host === "pda.englishhome.com.lb";
const showTransfer = host.startsWith("localhost") || host === "pda.flo-lebanon.com" || host === "pda.englishhome.com.lb";
const Dontshowfor_flo_eh =  host.startsWith("localhost") ||  host === "pda.ishtari.com"|| host === "pda.ishtari.com.gh";


  NavigateToFavorite();

useEffect(() => {
  if ( 
   
host === 'pda.flo-lebanon.com' || host === "pda.englishhome.com.lb"
  ) {
   
    const storedStoreList = JSON.parse(localStorage.getItem("stores"));
    if (storedStoreList) {
      // alert(storedStoreList[0].store_name)
      setParsedStores(storedStoreList);
    } 

    const storedStoreName = localStorage.getItem("store_name");
    const storedStoreId = localStorage.getItem("store_id");
     
    if (storedStoreName && storedStoreId) {
      dispatchCom({ type: "setCompany", payload: storedStoreName });
      dispatchCom({ type: "setStoreId", payload: storedStoreId });
    } else {
      navigate("/", { replace: true });
    }
  }
}, [host, dispatchCom]);


const handleStoreSelection = (store) => {
  dispatchCom({ type: "setCompany", payload: store.store_name });
  localStorage.setItem("store_id", store.store_id);
  localStorage.setItem("store_name", store.store_name);
  setStoreMenu(!storeMenu);
};


  useEffect(() => {
    const handleOnlineStatusChange = () => {
      setWifiOn(navigator.onLine);
      if (navigator.onLine) {
        setWifiOnRemove(false);
        setTimeout(() => {
          setWifiOnRemove(true);
        }, 3000);
      }
    };
    window.addEventListener("online", handleOnlineStatusChange);
    window.addEventListener("offline", handleOnlineStatusChange);
    setWifiOn(navigator.onLine);
    return () => {
      window.removeEventListener("online", handleOnlineStatusChange);
      window.removeEventListener("offline", handleOnlineStatusChange);
    };
  }, []);
  useEffect(() => {
    if (
      stateCom.company === null ||
      stateCom.company === "" ||
      stateCom.company === undefined
    ) {
      history("/", { replace: true });
    }
  }, []);
  function logout() {
    localStorage.clear();
    history("/", { replace: true });
  }

  const history = useNavigate();
  return (
    <>
      <div className=" h-full bg-white">
        <div className="  sticky top-0 flex flex-col bg-white">
          <div
            // onClick={() => {
            //   setStoreMenu(!storeMenu)
            // }}
            className={` ${
              openMenu || storeMenu ? "block" : "hidden"
            }  w-screen absolute z-10 top-0 left-0 bottom-0 right-0 bg-dblackOverlay h-screen`}
          ></div>
          {/* header */}
          <div className=" w-full bg-dbase h-16">
            <div className=" container flex relative flex-row justify-between w-full text-white  h-full my-auto">

{  host === "pda.englishhome.com.lb" || host === "pda.flo-lebanon.com" ? (
  <button
    onClick={() => setStoreMenu(true)}
    className="flex my-auto flex-col"
  >
    <div className="flex items-center">
      <h2 className="text-xl my-auto">{store_name}</h2>
      <TiArrowSortedDown
        className={`ml-2${storeMenu ? " rotate-180" : ""}`}
      />
    </div>
    <h2 className="text-start text-sm max-w-[100px] truncate">
      {stateCom.username}
    </h2>
  </button>
) : (
  <div className="flex flex-col container">
    <h2 className="text-xl my-auto">{stateCom.company}</h2>
    <h2 className="text-sm max-w-[100px] truncate my-auto">
      {stateCom.username}
    </h2>
  </div>
)}
{storeMenu && (
  <div
    className={`${
      storeMenu ? "h-fit" : "h-0"
    } overflow-y-auto transition-all overflow-hidden absolute w-40 z-50 left-32 top-10 shadow-md bg-white text-dblackk rounded-md`}
    style={{ scrollbarWidth: "thin", scrollbarColor: "#ccc #f5f5f5" }}
  >
    <div>
      {parsedStores && parsedStores.map((store) => (
        <button
        className="block w-full text-left px-2 py-1 border-b border-dgreySeller"
        key={store.store_id}
        onClick={() => handleStoreSelection(store)}
      >
          {store.store_name}
        </button>
      ))}
    </div>
  </div>
)}

              <div className=" flex flex-row justify-center gap-5">
{ (window.location.host.startsWith("localhost") || host === "pda.ishtari.com" || host === "pda.ishtari.com.gh") &&
  <button
    className="h-fit my-auto p-1 border rounded-full"
    onClick={() => history("v2home")}
  >
    <p className="text-sm">V1</p>
  </button>
}
              </div>
              <div className="flex justify-end p-4 mt-1">
                <BurgerMenu />
              </div>
              <div
                className={`${
                  openMenu ? "h-24" : "h-0"
                }  transition-all overflow-hidden  absolute  w-28 z-30 right-2 top-12 shadow-md bg-white rounded-md `}
              >
                <div
                  className={` divide-y-2 divide-dplaceHolder px-4 py-3  flex   flex-col gap-3 `}
                >
                  <button

                    className=" outline-none text-dblack  text-md   bg-transparent "
                  >
                    {version}
                  </button>
                  <button
                    onClick={() => logout()}
                    className=" outline-none text-dblack pt-2  text-md bg-transparent "
                  >
                    {" "}
                    Logout
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* tab bar */}
          <div className=" w-screen  bg-white my-4 container ">
            <div className=" flex w-full justify-center gap-2">
              <button
                onClick={() =>
                  dispatchTab({ type: "setActiveTab", payload: "home" })
                }
                className={`${
                  stateTab.activeTab === "home"
                    ? "bg-dbase text-white"
                    : "bg-white text-dblack"
                }  transition-all w-full py-2  rounded-full`}
              >
                {" "}
                Home
              </button>
              <button
                onClick={() =>
                  dispatchTab({ type: "setActiveTab", payload: "return" })
                }
                className={`${
                  stateTab.activeTab === "return"
                    ? "bg-dbase text-white"
                    : "bg-white text-dblack"
                }  transition-all w-full py-2 rounded-full`}
              >
                {" "}
                Return
              </button>
            </div>
          </div>
        </div>
        {/* buttons navigations */}
        <div className=" grid grid-cols-2 container gap-x-6 gap-y-4">
          {stateTab.activeTab === "home" && (
            <>
              <NavigateButton
                icon={<FaHistory />}
                name={"CHANGE STATUS"}
                arname={"تغيير الحالة"}
                color={"bg-Orangeflo"}
                onclick={() => history("changestatus")}
              />
              <NavigateButton
                icon={<FaSearch />}
                name={"PRODUCT"}
                arname={" المنتج"}
                color={"bg-[#BF1B26]"}
                onclick={() => history("product")}
              />
              {/* <NavigateButton
                icon={<FaSyncAlt />}
                name={"MANAGE PRODUCT"}
                arname={" تعديل المنتج"}
                color={"bg-[rgb(8,51,68)]"}
                onclick={() => history("manageproduct")}
              /> */}
              <NavigateButton
                icon={<FaSyncAlt />}
                name={"CHANGE UPC"}
                arname={"تغيير الخانة "}
                color={"bg-[rgb(37,99,235)]"}
                onclick={() => history("changeupc")}
              />
{showChangeOptionUpc && (
        <NavigateButton
          icon={<FaSyncAlt />}
          name={"CHANGE OPTION UPC"}
          arname={"تغيير الخانة "}
          color={"bg-[rgb(216,180,254)]"}
          onclick={() => history("changeoptionupc")}
        />
      )}
              {Dontshowfor_flo_eh && (
              <NavigateButton
                icon={<FaPaperPlane />}
                name={"STOCK TO ENTRY"}
                arname={" "}
                color={"bg-[rgb(217,70,239)]"}
                onclick={() => history("stocktoentry")}
              />
              )}
              <NavigateButton
                icon={<FaSyncAlt />}
                name={"CHANGE BIG UPC"}
                arname={"تغيير الخانة الكبيرة "}
                color={"bg-[rgb(242,113,113)]"}
                onclick={() => history("changebigupc")}
              />
              <NavigateButton
                icon={<FaSyncAlt />}
                name={"CHANGE MAIN UPC"}
                arname={" تغيير الخانة الرئيسية"}
                color={"bg-[rgb(147,51,234)]"}
                onclick={() => history("changemainupc")}
              />

              <NavigateButton
                icon={<FaShoppingCart />}
                name={"ORDER"}
                arname={"الطلبية"}
                color={"bg-[rgb(14,165,233)]"}
                onclick={() => history("order")}
              />
              <NavigateButton
                icon={<FaShoppingCart />}
                name={"RECEIVE PRODUCT QTY"}
                // arname={"إعادة التعبئة "}
                color={"bg-[rgb(9,60,600)]"}
                onclick={() => history("receiveproductquantity")}
              />
              <NavigateButton
                icon={<FaShoppingCart />}
                name={"UPC RESTOCK"}
                arname={"إعادة التعبئة "}
                color={"bg-[rgb(99,102,241)]"}
                onclick={() => history("upcrestock")}
              />
              <NavigateButton
                icon={<FaShoppingCart />}
                name={"CHANGE UPC Quantity"}
                arname={" "}
                color={"bg-[rgb(23,37,84)]"}
                onclick={() => history("changeupcquantity")}
              />
              <NavigateButton
                icon={<FaShoppingCart />}
                name={"MANAGE UPC Quantity"}
                arname={" "}
                color={"bg-[rgb(232,121,249)]"}
                onclick={() => history("manageupcquantity")}
              />
              <NavigateButton
                icon={<FaCheckCircle />}
                name={"Check Upc"}
                color={"bg-[rgb(34,197,94)]"}
                onclick={() => history("confirmrestock")}
              />

              <NavigateButton
                icon={<FaListUl />}
                name={"ORDER PRODUCT"}
                arname={" تحضير المنتج"}
                color={"bg-[rgb(245,158,11)]"}
                onclick={() => history("orderproduct")}
              />
              <NavigateButton
                icon={<FaHistory />}
                name={"ORDERS HISTORY"}
                arname={" تاريخ الطلبات"}
                color={"bg-[rgb(7,89,133)]"}
                onclick={() => history("ordershistory")}
              />
              <NavigateButton
                icon={<FaPlusCircle />}
                name={"JARDEH"}
                arname={"جردة "}
                color={"bg-[rgb(88,28,135)]"}
                onclick={() => history("jardeh")}
              />
              {showTransfer && (
                <NavigateButton
                icon={<BiTransferAlt />}
                name={"Transfer"}
                arname={" تحويل"}
                color={"bg-Orangeflo"}
                onclick={() => history("transfer")}
              />
      )}
              <NavigateButton
                icon={<LiaTruckPickupSolid />}
                name={"PickUp Order"}
                arname={" استلام الطلبية"}
                color={"bg-[rgb(145,170,255)]"}
                onclick={() => history("pickuporder")}
              />
            </>
          )}
          {stateTab.activeTab === "return" && (
            <>
              <NavigateButton
                icon={<FaQrcode />}
                name={"ARRIVED"}
                arname={" وصل"}
                color={"bg-[rgb(5,150,105)]"}
                onclick={() => history("arrived")}
              />
              <NavigateButton
                icon={<FaPaperPlane />}
                name={"Send To Control"}
                arname={"إرسال للتحكم "}
                color={"bg-[rgb(37,99,235)]"}
                onclick={() => history("sendtocontrol")}
              />
              <NavigateButton
                icon={<FaCheckDouble />}
                name={"Received in Control"}
                arname={"تم استلامه في التحكم "}
                color={"bg-[rgb(8,145,178)]"}
                onclick={() => history("receivedincontrol")}
              />
              <NavigateButton
                icon={<IoSettingsSharp />}
                name={"Control"}
                arname={" التحكم"}
                color={"bg-[rgb(96,165,250)]"}
                onclick={() => history("control")}
              />
            </>
          )}
        </div>

        {!wifiOn && (
          <div className=" fixed bg-dblackOverlay w-full h-full z-[999] left-0 bottom-0 right-0 top-0">
            <div className=" fixed  container  bottom-5 w-full h-16 ">
              <div className=" relative px-2  bg-dbase w-full h-full flex justify-start divide-x-2 divide-dgreyRate rounded-md text-white ">
                <div className=" my-auto px-5">
                  {" "}
                  <MdOutlineWifiOff className=" text-3xl" />
                </div>
                <p className=" my-auto px-3">Your Offline </p>{" "}
              </div>
            </div>
          </div>
        )}
        {wifiOn && !wifiOnRemove && (
          <div className=" fixed h-fit w-fit z-[999] left-0 bottom-0 right-0 top-0">
            <div className=" fixed  container  bottom-5 w-full h-16 ">
              <div className=" relative px-2 bg-dgreen2 w-full h-full flex justify-start divide-x-2 divide-dgreyRate rounded-md text-white ">
                <div className=" my-auto px-5">
                  {" "}
                  <FaWifi className=" text-3xl" />
                </div>
                <p className=" my-auto px-3">Back Online </p>{" "}
              </div>
            </div>
          </div>
        )}

        {/* show store list using company reducer */}
        {/* {storeMenu && 
  <div className={`${storeMenu ? "h-34" : "h-0"} overflow-y-auto transition-all overflow-hidden absolute w-40 z-50 left-2 top-10 shadow-md bg-white rounded-md`} style={{ scrollbarWidth: 'thin', scrollbarColor: '#ccc #f5f5f5' }}>
    {Object.keys(stateCom.storeList).map((storeId, index) => (
      <button className={`block w-full text-left px-2 py-1 ${index !== Object.keys(stateCom.storeList).length - 1 ? 'border-b border-dgreySeller' : ''}`} key={storeId}>
        {stateCom.storeList[storeId].store_name}
      </button>
    ))}
  </div>
} */}
        {/* show store list using local storage */}

        {/* {storeMenu &&
        ( 
        
          <div
            className={`${
              storeMenu ? "h-40" : "h-0"
              
            } overflow-y-auto transition-all overflow-hidden absolute w-40 z-50 left-32 top-10 shadow-md bg-white rounded-md`}
            style={{ scrollbarWidth: "thin", scrollbarColor: "#ccc #f5f5f5" }}
          >
            <div>
            {parsedStores && parsedStores.map((store) => (
              <button
                className="block w-full text-left px-2 py-1 border-b border-dgreySeller"
                key={store.store_id}
              >
                <span
                  onClick={() => {
                    dispatchCom({ type: "setCompany", payload: store.store_name });
                    // console.log("companyyyyy changed to :", store.store_name)
                    localStorage.setItem("store_id", store.store_id);
                    localStorage.setItem("store_name", store.store_name);
                    // console.log("Store ID changed to:", store.store_id);
                    // console.log("Store name changed to:", store.store_name);
                    setStoreMenu(!storeMenu);
                  }}
                >
                  {store.store_name}
                </span>
              </button>
            ))}
            </div>
          </div>
        )} */}
      </div>
      {/* {state.loged && <NavigateToFavorite />} */}
    </>
  );
};
export default Home;
