import React, { useState, useEffect } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { IoIosWarning } from "react-icons/io";

const MessageAlert = ({ showMessg, type, message }) => {
  const [closeMessage, setCloseMessage] = useState(false);

  useEffect(() => {
    if (showMessg) {
      setCloseMessage(false);
    }
  }, [showMessg]);

  const handleClose = () => {
    setCloseMessage(true);
  };

  return (
    <div
    onClick={handleClose}
      className={` ${
        showMessg && !closeMessage ? "top-1 scale-x-100 opacity-100" : "-top-36 scale-x-0 opacity-0"
      } container fixed z-50 transition-all left-0 right-0  w-full`}
    >
      <div
        className={`w-full h-fit py-1  px-5 rounded-md ${
          type === "succ" ? "bg-dgreen2" : "bg-Energyplus"
        }  flex flex-row gap-5 justify-start`}
      >
       
        <div className="flex flex-col">
          <div className=" flex flex-row gap-2">
          <div className="my-auto">
          {type === "succ" ? (
            <FaCheckCircle className="text-2xl" />
          ) : (
            <IoIosWarning className="text-2xl" />
          )}
        </div>
          <h2 className="text-lg font-bold">
            {type === "succ" ? "Success. " : "Warning !!!"}{" "}
          </h2>
          </div>
          <h2 className="text-sm">{message}</h2>
        </div>
      
      </div>
    </div>
  );
};

export default MessageAlert;
