import React, { useContext, useEffect, useRef, useState } from "react";
import { FaArrowLeft, FaArrowRight, FaList } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { axiosServer } from "../../../axiosServer";
import buildLink from "../../../urls";
import axios from "axios";
import { CompanyContext } from "../../../contexts/companyCotext";
import MessageAlert from "../../../components/messageAlert";
import { PlaySounds } from "../../../functions/playSounds";
import Cookies from "js-cookie";

const ScanPickUpOrder = () => {
  const hiddenProductId = useRef(null);
  const { id } = useParams();
  const history = useNavigate();
  const [hasproductFocus, setHasproductFocus] = useState(false);
  const [hasbinFocus, setHasBinFocus] = useState(false);
  const {playErrorSound,playSuccessSound} = PlaySounds();
  const [list, setList] = useState();
  const scannedValue = useRef();
  const [showMessgSucc, setShowMessageSucc] = useState(false);
  const [message, setMessage] = useState("");
  const [showMessgErr, setShowMessageErr] = useState(false);
  const [products, setProducts] = useState(null);
  const [stateCom, dispatchCom] = useContext(CompanyContext);
  const [ispicked, setIsPicked] = useState(false);
  const [productsTemp, setProductsTemp] = useState([]);
  const [indexBin, setIndexBin] = useState(0);
  const biggerThanTen = useRef();
  const [quantityInput, setQuantityInput] = useState();
  const [indexProduct, setindexProduct] = useState(0);

  const searchPickupOrderProducts = async () => {
    try {
      const url = buildLink("getPickupOrderProducts") + `&order_id=${id}`;
      const response = await axios.get(url);
      // console.log(response.data.data);
      // if (response.data.success) {
      //   setList(response.data.data.list);
      //   if (response.data.data.list[indexBin].picked) {
      //     setIsPicked(true);
      //   }
      //   scannedValue.current.focus();
      // }

      if (response.data.success) {
        const newList = response.data.data.list;
        setList(newList);
      
        if (newList.length > 0 && indexBin < newList.length && newList[indexBin].picked) {
          setIsPicked(true);
        }
      
        scannedValue.current.focus();
      }

      

    } catch (e) {
      console.log("error:", e);
    }
  };
  useEffect(() => {
    searchPickupOrderProducts();
  }, [ispicked]);

  async function checkShelfLabel(e) {
    e.preventDefault();
    const order_id = id;
    const label = scannedValue.current.value;
    const type = list[indexBin].key;

    try {
      const url =
        buildLink("checkShelfLabel") +
        `&order_id=${order_id}&label=${label}&type=${type}`;
      const response = await axiosServer.get(url);
      if (response.data.success) {
        setProducts(response.data.data.products);
        hiddenProductId.current.focus();
      }
    } catch (e) {
      console.log('-------------------------------------------------')
      playErrorSound()
      setMessage(e.response.data.message);
      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
    }
    scannedValue.current.value = "";
  }

  useEffect(() => {
    if (list && list[indexBin].picked) {
      setIsPicked(true);
    } else {
      setIsPicked(false);
    }
  }, [indexBin]);

  const getUserIdFromLocalStorage = () => {
    return localStorage.getItem("userID");
  };
  
  async function scanProduct(e) {
    e.preventDefault();
    const userID = getUserIdFromLocalStorage()
    const putBiggerThanTen = biggerThanTen.current.value;
    const updatedQuantity =
      products[indexProduct].quantity > 10
        ? putBiggerThanTen
        : products[indexProduct].quantity;

    const obj = {
      order_id: id,
      label: hiddenProductId.current.value,
      quantity: updatedQuantity,
      user_id: userID,
      big_upc: products[indexProduct].big_upc,
    };
    try {
      const queryParams = new URLSearchParams(obj).toString();
      await axiosServer
        .post(buildLink("pickupOrderProduct") + "&" + queryParams)
        .then((res) => {
          console.log(res.data.data);

          const productScaned = document.getElementById(
            res.data.data.order_product_id
          );
          productScaned.classList.remove("bg-dgreen2");
          productScaned.classList.remove("bg-[#FEDFC9]");
          if (res.data.data.pickedup) {
            setindexProduct(indexProduct + 1);
            productScaned.classList.add("bg-dgreen2");
            document
              .getElementById(products[indexProduct].order_product_id)
              .classList.add("bg-[#FEDFC9]");
          } else {
            productScaned.classList.add("bg-[#FEDFC9]");
          }
        });
    } catch (e) {
      // console.log('-------------------------------------------------')
      playErrorSound()
      setMessage(e.response.data.message);
      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
      // console.log(e)
    }
    hiddenProductId.current.value = "";
  }

  useEffect(() => {
    const handleBinFocus = () => {
      setHasBinFocus(document.activeElement === scannedValue.current);
    };

    const handleBinBlur = () => {
      setHasBinFocus(false);
    };
    const handleProductFocus = () => {
      setHasproductFocus(document.activeElement === hiddenProductId.current);
    };

    const handleProductBlur = () => {
      setHasproductFocus(false);
    };
    if (scannedValue.current) {
      scannedValue.current.addEventListener("focus", handleBinFocus);
      scannedValue.current.addEventListener("blur", handleBinBlur);
    }
    if (hiddenProductId.current) {
      hiddenProductId.current.addEventListener("focus", handleProductFocus);
      hiddenProductId.current.addEventListener("blur", handleProductBlur);
    }

    return () => {
      if (scannedValue.current) {
        scannedValue.current.removeEventListener("focus", handleBinFocus);
        scannedValue.current.removeEventListener("blur", handleBinBlur);
      }
      if (hiddenProductId.current) {
        hiddenProductId.current.removeEventListener("focus", handleProductBlur);
        hiddenProductId.current.removeEventListener("blur", handleProductBlur);
      }
    };
  }, [scannedValue, hiddenProductId]);

  return (
    <>
      <form
        className=" fixed left-[900px]"
        onSubmit={(e) => {
          checkShelfLabel(e);
        }}
      >
        <input ref={scannedValue} />
      </form>

      <form
        className=" fixed left-[900px]"
        onSubmit={(e) => {
          scanProduct(e);
        }}
      >
        {" "}
        <input ref={hiddenProductId} />
      </form>

      {/* header */}
      <div className=" mb-5 sticky top-0 flex flex-col bg-white z-50">
        <div className=" w-full flex flex-row justify-between  bg-dbase h-16">
          <div className=" container flex relative gap-7 flex-row justify-start w-full text-white  h-full my-auto">
            <button onClick={() => history(-1)}>
              <FaArrowLeft />
            </button>
            <h2 className=" text-xl my-auto"> Scan PickUp Order</h2>
          </div>
          <div className=" px-5 flex justify-center text-center">
            <div
              className={` w-4 h-4 ${
                hasproductFocus ? "bg-dgreen" : "bg-Orangeflo"
              }  my-auto rounded-full`}
            ></div>
          </div>
        </div>
      </div>
      {list && (
        <div className=" flex flex-col  h-[86vh] justify-center gap-3 ">
          <div className=" w-full bg-dgrey py-3 ">
            <h2
              className={`mx-auto ${
                hasbinFocus ? "text-dgreen" : "text-dbase"
              }  w-fit `}
            >
              {list[indexBin].picked ? "Skip" : "Scan"} {list[indexBin].key}
            </h2>
          </div>
          <div
            onClick={() => scannedValue.current.focus()}
            className={` w-full ${
              list[indexBin].picked ? "border-dgreen2 " : "border-dbase"
            }  bg-dgrey border-2 py-3 `}
          >
            <h2 className=" text-4xl text-dblackk mx-auto w-fit">
              {list[indexBin].value}
            </h2>
          </div>

          <div className="  flex-grow  overflow-y-auto ">
            {/* {products &&
              products.map((product) => {
                const isQuantityGreaterThan10 = product.quantity > 10;

                return (
                  <div
                    // onClick={() => hiddenProductId.current.focus()}

                    className="px-1 py-2 mb-4 flex flex-row justify-between w-full"
                    onClick={(e) => {
                      // Only focus on the input if the click target is not the input itself
                      if (!e.target.matches("input")) {
                        hiddenProductId.current.focus();
                      }
                    }}
                  >
                    <div className="container h-14 text-center text-2xl ">
                      {isQuantityGreaterThan10 && (
                        <div>
                          <input
                            type="text"
                            ref={biggerThanTen}
                            className="border border-dhotPink rounded-lg h-14 text-center text-2xl "
                            placeholder="Set Quantity"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                );
              })} */}
            {products &&
              products.map((product) => {
                const isQuantityGreaterThan10 = product.quantity > 10;
                const quantityInputValue = isQuantityGreaterThan10
                  ? true
                  : false;
                return (
                  <div
                    onClick={(e) => {
                      if (!e.target.matches("input")) {
                      hiddenProductId.current.focus()
                      }
                    }}
                    id={product.order_product_id}
                    className={`${
                      product.picked && "bg-dgreen2"
                    } shadow-md border-dlabelColor border px-1 py-2 flex flex-row justify-between w-full `}
                  >
                    {products && isQuantityGreaterThan10 && (
                      
                      <div
                        // onClick={() => hiddenProductId.current.focus()}
                        className=" flex flex-row justify-between w-12 relative z-50 mt-52 "
                        onClick={(e) => {
                          // Only focus on the input if the click target is not the input itself
                          if (!e.target.matches("input")) {
                            quantityInput.current.focus();
                          }
                        }}
                      >
                        <div className="text-center text-2xl ">
                          {isQuantityGreaterThan10 && (
                            <div>
                              <input
                                type="text"
                                ref={biggerThanTen}
                                className="border border-dhotPink text-center rounded-lg  "
                                placeholder="Set Quantity"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    <ul className="flex flex-col gap-1 max-w-md list-disc list-inside">
                      <li>
                        <strong>Sku: </strong>
                        {product.sku}
                      </li>
                      <li className="">
                        {" "}
                        <strong>Name: </strong>
                        {product.name}
                      </li>
                      <li>
                        <strong>Qty: </strong>
                        {product.quantity}
                      </li>
                      <li>
                        <strong>Stand Qty: </strong>
                        {product.in_stand_quantity}
                      </li>
                      <li>
                        <strong>Option: </strong>
                        {product.option[0]}
                      </li>
                    </ul>
                    <div className="object-cover">
                      <img
                        src={product.mobile_image}
                        className="object-cover h-36 w-40 rounded-md m-auto container"
                      />
                    </div>
                  </div>
                );
              })}
          </div>
          <div className=" w-full justify-end flex  ">
            <button
              className={` w-full  flex flex-row justify-center gap-2 ${
                ispicked
                  ? " bg-dbase text-white"
                  : "bg-dplaceHolder text-dblack"
              } px-4   py-1`}
              onClick={() => {
                if (ispicked) {
                  if (indexBin == list.length - 1) {
                    history(-1);
                  } else {
                    setProducts([]);
                    setindexProduct(0);
                    setIndexBin(indexBin + 1);
                  }
                }
              }}
            >
              <span>Next </span>:
              <span>
                {indexBin == list.length - 1
                  ? "Last Bin"
                  : list[indexBin + 1].value}
              </span>{" "}
              <FaArrowRight className=" my-auto  text-sm" />
            </button>
          </div>
        </div>
      )}
      <MessageAlert
          message={message}
          type={showMessgErr ? "err" : "succ"}
          showMessg={showMessgErr || (showMessgSucc && true)}
        />
    </>
  );
};

export default ScanPickUpOrder;
