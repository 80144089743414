import React, { useContext, useEffect, useRef, useState } from "react";
import {
  FaArrowLeft,
  FaCheckCircle,
  FaSearch,
  FaTrash,
  FaTrashRestore,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import { axiosServer } from "../../../axiosServer";
import buildLink from "../../../urls";
import MessageAlert from "../../../components/messageAlert";
import { BsBookshelf } from "react-icons/bs";
import { CompanyContext } from "../../../contexts/companyCotext";
import Loader from "../../../components/loader";
import { IoMdRemoveCircleOutline } from "react-icons/io";
import Cookies from "js-cookie";
import { PlaySounds } from "../../../functions/playSounds";
import hocStarredPage from "../../../components/hocStarredPage";
import axios from "axios";
import { FaExchangeAlt } from "react-icons/fa";
const BinPagev2 = () => {
  const { playErrorSound, playSuccessSound } = PlaySounds();
  const [disabledInputs, setDisabledInputs] = useState(true);
  const [loadingAssign, setLoadingAssign] = useState(false);
  const productId = useRef(null);
  const binId = useRef(null);
  const binqtyinput = useRef(null);
  const qty = useRef(null);
  const [products, setProducts] = useState(null);
  const [info, setInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [productInfo, setProductInfo] = useState(null);
  const [showMessgErr, setShowMessageErr] = useState(false);
  const [message, setMessage] = useState("");
  const [showMessgSucc, setShowMessageSucc] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [action, setAction] = useState();
  const [Action, setaction] = useState();
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [loadingSearch1, setLoadingSearch1] = useState(false);
  const [start, setStart] = useState(false);
  const [assign, setAssign] = useState(false);
  const scanRef = useRef(null);
  const [stateCom, dispatchCom] = useContext(CompanyContext);
  const [counterOnScan, setcounterOnScan] = useState();
  const [requestSuccess, setRequestSuccess] = useState(false);
  const [productInf, setProductInf] = useState(null);
  const [keyboardType, setKeyboardType] = useState("text");
  const [processingRequest, setProcessingRequest] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [allowContinuecheckBin, setAllowContinueCheckBin] = useState(false);
  const Oldbinid = useRef(null);
  const [fetchedOldBinId, setFetchedOldBinId] = useState(""); // Store the old bin ID from the API
  const [ShowProductInput, setShowProductInput] = useState(false);
  const [ShowBinQuantityInput, setShowBinQuantityInput] = useState(false);
  const [quantityCounter, setQuantityCounter] = useState(0);
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const NewBinId = useRef(null);
  // useEffect(()=>{
  //   console.log("this is user id from bin",stateCom.userID)
  // },[])

  // useEffect(()=>{
  //         console.log(fetchedOldBinId)

  // },[fetchedOldBinId])
  useEffect(() => {
    if (productInf && productInf.address && allowContinuecheckBin) {
      setOpenAlert(false);
      getBinInfo();
    }
  }, [allowContinuecheckBin, productInf]);

  const getBinInfoCheck = (e) => {
    e.preventDefault();
    getBinInfo();
  };
  // const [showBinQuantity, setShowBinQuantity] = useState(false);
  const [binQuantity, setBinQuantity] = useState(null);
  const [ShowChangedQuantityInput, setShowChangedQuantityInput] =
    useState(false);
  const changedquantity = useRef(null);

  const [standQuantity, setstandQuantity] = useState(null);

  const searchProduct = async (e) => {
    e.preventDefault();
    setAllowContinueCheckBin(false);
    setLoadingSearch(true);

    const item = productId.current.value;

    try {
      const url =
        buildLink("productInfo") +
        `&item=${item}&v2=true` +
        `&user_id=${stateCom.userID}`;
      const response = await axiosServer.get(url);

      setLoadingSearch(false);
      setKeyboardType("none");

      if (response.data.success) {
        const productData = response.data.data;
        setProductInf(productData);
        // setBinQuantity(productData.bin_quantity);
        // setFetchedOldBinId(productData.bin_id?productData.bin_id:0);
        setisBinForm(true);
        setDisabledInputs(false);

        if (activeTab !== 3) {
          setTimeout(() => {
            binId.current.focus();
          }, 0);
        } else {
          setTimeout(() => {
            Oldbinid.current.focus();
          }, 0);
        }
      } else {
        playErrorSound();
        setMessage(response.data.message);
        setShowMessageErr(true);
        setTimeout(() => {
          setShowMessageErr(false);
        }, 3000);
      }
    } catch (e) {
      setLoadingSearch(false);
      productId.current.focus();
      productId.current.value = "";
      playErrorSound();
      setMessage(e.response.data.message);
      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
    }
  };

  const fetchBinQuantity = async () => {
    setLoadingSearch1(true);
    const oldBinId = Oldbinid.current.value;
    const newBinId = NewBinId.current.value;
    const fetchedoldbinid = productInf.bin_id != "" ? productInf.bin_id : 0;

    if (oldBinId != fetchedoldbinid) {
      setLoading(false);
      playErrorSound();
      setMessage("Incorrect old bin ID");
      setShowMessageErr(true);
      setLoadingSearch1(false);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
      return;
    }

    if (oldBinId == newBinId) {
      setLoading(false);
      playErrorSound();
      setMessage("Old bin ID and new bin ID cannot be the same");
      setShowMessageErr(true);
      setLoadingSearch1(false);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
      return;
    }

    try {
      const url =
        buildLink("updateBin") +
        `&user_id=${stateCom.userID}&old_bin_id=${oldBinId}&new_bin_id=${newBinId}&product_id=${productId.current.value}&bin_quantity=${productInf.bin_quantity}`;
      const response = await axiosServer.get(url);

      setLoadingSearch1(false);

      if (response.data.success) {
        setMessage(response.data.message);
        setShowMessageSucc(true);
        playSuccessSound();
        setShowChangedQuantityInput(true);
        setstandQuantity(response.data.standQuantity);

        Oldbinid.current.disabled = true;
        NewBinId.current.disabled = true;
        productId.current.disabled = true;

        setTimeout(() => {
          changedquantity.current?.focus();
        }, 0);

        setTimeout(() => {
          setShowMessageSucc(false);
        }, 3000);
        setLoadingSearch1(false);
      } else {
        setLoadingSearch1(false);
        setShowProductInput(false);
        setMessage(response.data.message);
        setShowMessageErr(true);
        playErrorSound();
        setTimeout(() => {
          setShowMessageErr(false);
        }, 3000);
        setLoadingSearch1(false);
      }
    } catch (e) {
      setLoadingSearch1(false);

      setShowProductInput(false);
      setMessage("The New Bin Doesn't Exist");
      setShowMessageErr(true);
      playErrorSound();
      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
    }
  };

  const [productIdInput, setProductIdInput] = useState("");

  const submitBinChange = async (e) => {
    e.preventDefault();
    setLoading(true);

    const oldBinId = Oldbinid.current.value;
    const newBinId = NewBinId.current.value;
    const changedQty = parseInt(changedquantity.current.value, 10) || 1;

    if (oldBinId === newBinId) {
      setLoading(false);
      playErrorSound();
      setMessage("Old bin ID and new bin ID cannot be the same");
      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
      return;
    }

    const finalChangedQty = changedQty > 30 ? changedQty : 1;

    const obj = {
      product: productIdInput,
      user_id: stateCom.userID,
      old_bin_id: oldBinId,
      new_bin_id: newBinId,
      changed_qty: finalChangedQty,
    };

    try {
      const url =
        buildLink("updateBin") +
        `&user_id=${stateCom.userID}&old_bin_id=${oldBinId}&new_bin_id=${newBinId}`;
      const response = await axiosServer.post(url, obj);

      setLoading(false);

      if (response.data.success) {
        setQuantityCounter(quantityCounter - finalChangedQty);
        setMessage(e.response?.data?.message || "Bin changed succefully");
        setShowMessageSucc(true);
        playSuccessSound();
        setTimeout(() => {
          setShowMessageSucc(false);
        }, 3000);
        setProductIdInput("");
      } else {
        setLoading(false);
        playErrorSound();
        setMessage(e.response?.data?.message || "An error occurred");
        setShowMessageErr(true);
        setTimeout(() => {
          setShowMessageErr(false);
        }, 3000);
      }
    } catch (e) {
      setLoading(false);
      playErrorSound();
      setMessage(e.response?.data?.message || "An error occurred");
      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
    }
  };

  useEffect(() => {
    if (quantityCounter === 0) {
      if (productId.current) {
        productId.current.value = "";
        productId.current.disabled = false;
      }
      if (Oldbinid.current) {
        Oldbinid.current.value = "";
      }
      if (NewBinId.current) {
        NewBinId.current.value = "";
      }
      setisBinForm(false);
      setProductIdInput("");
      setShowProductInput(false);
      setShowBinQuantityInput(false);
      setShowChangedQuantityInput(false);
      productId.current.focus();
    }
  }, [quantityCounter]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    setDisabledInputs(true);
  }, [activeTab]);

  async function getBinInfo() {
    if (productInf.address == "" || allowContinuecheckBin) {
      setAllowContinueCheckBin(false);
      setLoading(true);
      const bin_id = binId.current.value;
      const product = productId.current.value;
      const quantity = qty.current.value;
      const obj = JSON.stringify({
        bin_id,
        product,
        quantity,
      });

      try {
        const url = buildLink("getBinInfo") + `&v2=true`;
        await axiosServer.post(url, JSON.parse(obj)).then((response) => {
          setLoading(false);
          if (response.data.success) {
            setDisabledInputs(true);
            const productInfo = response.data.data.product;
            setAction(response.data.data.action);
            setaction(response.data.data.action);
            setInfo(productInfo);
          }
        });

        Cookies.set("productInfo", JSON.stringify(info), { expires: 7 });
        Cookies.set("action", action, { expires: 7 });
      } catch (e) {
        setLoading(false);
        console.log(e);
        playErrorSound();
        setMessage(e.response.data.message);
        setShowMessageErr(true);
        setTimeout(() => {
          setShowMessageErr(false);
        }, 3000);
      }
    } else {
      setOpenAlert(true);
    }
  }
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const getUserIdFromLocalStorage = () => {
    return localStorage.getItem("userID");
  };

  // useEffect(() => {
  //   console.log("counterOnScan:", counterOnScan);
  // }, [counterOnScan]);

  async function assignProductToBin(e) {
    e.preventDefault();
    if (processingRequest) return;
    setProcessingRequest(true);
    const userID = getUserIdFromLocalStorage();
    if (scanRef.current.value === productId.current.value) {
      if (Action === "count" && counterOnScan >= qty.current.value) {
        playSuccessSound();
        setMessage("Successfully Assigned To Bin");

        setShowMessageSucc(true);
        setTimeout(() => {
          setShowMessageSucc(false);
        }, 3000);
      } else {
        const getcookies = Cookies.get("productInfo");
        const savedData = JSON.parse(getcookies);
        // console.log(savedData);

        const user_id = userID;
        const new_bin_id = binId.current.value;
        const upc = savedData.upc;
        const big_upc = savedData.big_upc;
        const bin_id = savedData.bin_id;
        const quantity = savedData.quantity;
        const action = Action;
        const counter = counterOnScan || 0;

        const obj = {
          user_id,
          new_bin_id,
          upc,
          big_upc,
          bin_id,
          quantity,
          action,
          counter,
        };

        if (typeof info === "object") {
          if (info.product_option_value_id === "") {
            obj.id = info.product_id;
            obj.type = "product";
          } else {
            obj.id = info.product_option_value_id;
            obj.type = "option";
          }
        }
        if (Action === "count") {
          obj.quantity = "1";
          obj.total_qty = qty.current.value;
        } else {
          obj.quantity = qty.current.value;
          obj.total_qty = qty.current.value;
        }
        // if (Action === "assign") {
        //   obj.quantity = quantity.current.value;
        // }

        try {
          const url = buildLink("assignProductToBin") + `&v2=true`;
          await axiosServer.post(url, obj).then((response) => {
            // console.log(response);
            if (response.data.success) {
              setcounterOnScan(response.data.data.counter);
              setRequestSuccess(true);
              scanRef.current.value = "";
              setProcessingRequest(false);
              if (scanRef.current) {
                scanRef.current.disabled = false;
                scanRef.current.focus();
              }
            }
            if (response.data.success && Action === "assign") {
              playSuccessSound();
              setMessage("Successfully Assigned To Bin");

              setShowMessageSucc(true);
              setTimeout(() => {
                setShowMessageSucc(false);
              }, 3000);
            }
            if (
              response.data.data.done === true ||
              response.data.data.done === "true"
            ) {
              setDisabledInputs(true);
              productId.current.value = "";
              binId.current.value = "";
              qty.current.value = "";
              setcounterOnScan(0);
              productId.current.focus();
              setStart(false);
              setaction();
              setAction();
              setProductInf(null);
              playSuccessSound();
              setMessage("Successfully Assigned To Bin");
              setShowMessageSucc(true);
              setTimeout(() => {
                setShowMessageSucc(false);
              }, 3000);
            }
          });
        } catch (e) {
          // setMessage(e.response.message);
          setShowMessageErr(true);
          setTimeout(() => {
            setShowMessageErr(false);
          }, 3000);
          console.log(e);
          setRequestSuccess(false);
          setProcessingRequest(false);
          if (scanRef.current) {
            scanRef.current.disabled = false;
            scanRef.current.focus();
          }
        }
      }
    } else {
      playErrorSound();
      setMessage("Please Scan The Correct Product ID");
      setShowMessageErr(true);

      setTimeout(() => {
        setShowMessageErr(false);

        if (scanRef.current) {
          scanRef.current.disabled = false;
          scanRef.current.focus();
        }
      }, 1000);
      setProcessingRequest(false);
    }
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  async function assignProductToBin_forassign(e) {
    e.preventDefault();
    const userID = getUserIdFromLocalStorage();
    const getcookies = Cookies.get("productInfo");
    const savedData = JSON.parse(getcookies);
    const user_id = userID;
    const new_bin_id = binId.current.value;
    const upc = savedData.upc;
    const big_upc = savedData.big_upc;
    const bin_id = savedData.bin_id;
    const quantity = savedData.quantity;
    const action = Action;
    const counter = counterOnScan || 0;
    const obj = {
      user_id,
      new_bin_id,
      upc,
      big_upc,
      bin_id,
      quantity,
      action,
      counter,
    };

    if (typeof info === "object") {
      if (info.product_option_value_id === "") {
        obj.id = info.product_id;
        obj.type = "product";
      } else {
        obj.id = info.product_option_value_id;
        obj.type = "option";
      }
    }
    if (Action === "count") {
      obj.quantity = "1";
      obj.total_qty = qty.current.value;
    } else {
      obj.quantity = qty.current.value;
      obj.total_qty = qty.current.value;
    }
    try {
      const url = buildLink("assignProductToBin") + `&v2=true`;
      setLoadingAssign(true);
      await axiosServer.post(url, obj).then((response) => {
        setLoadingAssign(false);
        if (response.data.success) {
          setcounterOnScan(response.data.data.counter);
          setRequestSuccess(true);
        }
        if (response.data.success && Action === "assign") {
          playSuccessSound();
          setMessage("Successfully Assigned To Bin");

          setShowMessageSucc(true);
          setTimeout(() => {
            setShowMessageSucc(false);
          }, 3000);
        }
      });
    } catch (e) {
      setLoadingAssign(false);
      setMessage(e.response.message);
      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
      console.log(e);
      setRequestSuccess(false);
    }
  }
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  // async function getBinProducts(e) {
  //   e.preventDefault();
  //   await globalGetBinProducts();
  // }

  // async function globalGetBinProducts() {
  //   const url = buildLink("getBinProducts");
  //   try {
  //     await axiosServer
  //       .get(url + `&bin_id=${binId.current.value}`)
  //       .then((res) => {
  //         setLoading(false);
  //         if (res.data.success) {
  //           setProducts(res.data.products);
  //         }
  //       });
  //   } catch (e) {
  //     setLoading(false);
  //     setMessage(e.response.data.message);
  //     setShowMessageErr(true);
  //     setTimeout(() => {
  //       setShowMessageErr(false);
  //     }, 3000);
  //   }
  // }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  // async function assignBin(e) {
  //   e.preventDefault();
  //   const url = buildLink("assignBin");
  //   const obj = {
  //     bin_id: binId.current.value,
  //     product: productId.current.value,
  //   };
  //   try {
  //     await axiosServer.post(url, obj).then((res) => {
  //       setLoading(false);
  //       if (res.data.success) {
  //         setMessage(res.data.message);
  //         setShowMessageSucc(true);
  //         setTimeout(() => {
  //           setShowMessageSucc(false);
  //         }, 3000);
  //       }
  //     });
  //   } catch (e) {
  //     setLoading(false);
  //     setMessage(e.response.data.message);
  //     setShowMessageErr(true);
  //     setTimeout(() => {
  //       setShowMessageErr(false);
  //     }, 3000);
  //   }
  // }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  async function unassignfromBin(e) {
    e.preventDefault();
    const url = buildLink("unassignProductFromBin") + `&v2=true`;
    const obj = {
      user_id: getUserIdFromLocalStorage(),
      bin_id: binId.current.value,
      product: productId.current.value,
    };

    try {
      await axiosServer.post(url, obj).then((res) => {
        // console.log(res);
        if (res.data.success) {
          playSuccessSound();
          // globalGetBinProducts();
          setMessage(res.data.message);
          setShowMessageSucc(true);
          setTimeout(() => {
            setShowMessageSucc(false);
          }, 3000);
          setTimeout(() => {
            binId.current.value = "";
            productId.current.value = "";
            binId.current.focus();
          }, 0);
        }
      });
    } catch (e) {
      playErrorSound();
      console.log(e);
      setLoading(false);
      setMessage(e.response.data.message);
      console.log(message);
      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
    }
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const history = useNavigate();

  useEffect(() => {
    productId.current.focus();
  }, []);

  useEffect(() => {
    Cookies.set("productInfo", JSON.stringify(info), { expires: 7 });
    Cookies.set("action", action, { expires: 7 });
    // console.log(Cookies);
  }, [info, action]);

  const handleTrashClick = () => {
    Oldbinid.current.value = "";
    Oldbinid.current.focus();
  };

  const trashformschangebin = () => {
    productId.current.value = "";
    Oldbinid.current.value = "";
    NewBinId.current.value = "";
    changedquantity.current.value = "";
    productId.current.focus();
  };

  const handleProductIdTrashClick = () => {
    productId.current.value = "";
    productId.current.focus();
  };

  // useEffect(() => {
  //   if (start) {
  //     scanRef.current.focus();
  //     scanRef.current.setSelectionRange(
  //       scanRef.current.value.length,
  //       scanRef.current.value.length
  //     );
  //   }
  // }, [start]);
  useEffect(() => {
    setProducts(null);
    setInfo(null);
    setLoading(false);
    setShowMessageErr(false);
    setMessage("");
    setShowMessageSucc(false);
    setAction(null);
    setaction(null);
    setLoadingSearch(false);
    setStart(false);
    setAssign(false);
    setProductInf(null);
    setcounterOnScan(undefined);
    setRequestSuccess(false);
    setKeyboardType("text");
  }, [activeTab]);

  const [isBinForm, setisBinForm] = useState(false);

  const productIdInputRef = useRef(null);

  const submitchangedqty = (e) => {
    e.preventDefault();

    const enteredQuantity = parseInt(changedquantity.current.value, 10);

    if (!isNaN(enteredQuantity)) {
      setQuantityCounter(enteredQuantity);
      setShowProductInput(true);
      changedquantity.current.disabled = true;

      if (activeTab === 3) {
        setTimeout(() => {
          productIdInputRef.current.focus();
        }, 0);
      }
    } else {
      console.error("Invalid quantity entered.");
    }
  };

  return (
    <>
      <div className=" h-full bg-white">
        {/* header */}
        <div className=" mb-5 sticky top-0  z-30 flex flex-col bg-white">
          <div className=" w-full bg-dbase h-16">
            <div className=" container flex relative gap-7 flex-row justify-start w-full text-white  h-full my-auto">
              <button onClick={() => history(-1)}>
                <FaArrowLeft />
              </button>
              <h2 className=" text-xl my-auto">Bin</h2>
            </div>
          </div>
        </div>

        <div className=" w-screen  bg-white my-4 container overflow-x-auto">
          <div className=" flex w-full justify-start gap-3">
            <button
              onClick={() => setActiveTab(1)}
              className={`${
                activeTab === 1 ? "bg-dbase text-white" : "bg-white text-dblack"
              }  transition-all w-full py-2  rounded-full text-nowrap px-3`}
            >
              {" "}
              Assign to bin
            </button>
            <button
              onClick={() => setActiveTab(2)}
              className={`${
                activeTab === 2 ? "bg-dbase text-white" : "bg-white text-dblack"
              }  transition-all w-full py-2 rounded-full text-nowrap px-3`}
            >
              {" "}
              Unassign from bin
            </button>
            <button
              onClick={() => setActiveTab(3)}
              className={`${
                activeTab === 3 ? "bg-dbase text-white" : "bg-white text-dblack"
              }  transition-all w-full py-2 rounded-full text-nowrap px-3`}
            >
              {" "}
              Change Bin
            </button>
          </div>
        </div>

        {activeTab === 3 && (
          <div className="container">
            <form
              onSubmit={(e) => searchProduct(e)}
              className="flex flex-row justify-center gap-3"
            >
              <div className="relative flex-1">
                <input
                  required
                  ref={productId}
                  type="text"
                  className="border border-dlabelColor disabled:bg-dplaceHolder outline-dbase w-full rounded-md py-3 pr-8 pl-2"
                  placeholder="Product ID or SKU"
                />
                <div onClick={() => trashformschangebin()}>
                  <FaTrash className="absolute top-1/2 transform -translate-y-1/2 right-3 cursor-pointer opacity-20" />
                </div>
              </div>

              <button
                type="submit"
                className="bg-dbase rounded-md text-white px-5"
              >
                {loadingSearch ? <Loader /> : <FaSearch />}
              </button>
            </form>

            {isBinForm && (
              <form onSubmit={submitBinChange} className="mt-5">
                <div className="flex flex-row justify-center gap-3">
                  <div className="relative flex-1">
                    <input
                      required
                      ref={Oldbinid}
                      type="text"
                      className="border border-dlabelColor outline-dbase w-full rounded-md py-3 pr-8 pl-2"
                      placeholder="Old Bin ID"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          NewBinId.current.focus();
                        }
                      }}
                    />
                    <FaTrash
                      onClick={() => (Oldbinid.current.value = "")}
                      className="absolute top-1/2 transform -translate-y-1/2 right-3 cursor-pointer opacity-20"
                    />
                  </div>
                </div>

                <div className="flex flex-row justify-center gap-3 mt-5">
                  <div className="relative flex-1">
                    <input
                      required
                      ref={NewBinId}
                      type="text"
                      className="border border-dlabelColor outline-dbase w-full rounded-md py-3 pr-8 pl-2"
                      placeholder="New Bin ID"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          fetchBinQuantity();
                        }
                      }}
                    />
                    <FaTrash
                      onClick={() => (NewBinId.current.value = "")}
                      className="absolute top-1/2 transform -translate-y-1/2 right-3 cursor-pointer opacity-20"
                    />
                  </div>

                  <button
                    type="button"
                    onClick={fetchBinQuantity}
                    className="bg-dbase rounded-md text-white px-5"
                  >
                    {loadingSearch1 ? <Loader /> : <FaSearch />}
                  </button>
                </div>

                {/* Quantity Input Section */}
                {ShowChangedQuantityInput && (
                  <div className="flex flex-col justify-center gap-3 mt-5">
                    <div className="relative flex-1">
                      <input
                        required
                        ref={changedquantity}
                        type="text"
                        className="border border-dlabelColor outline-dbase w-full rounded-md py-3 pr-8 pl-2"
                        placeholder="Quantity"
                      />
                      <FaTrash
                        onClick={() => (changedquantity.current.value = "")}
                        className="absolute top-1/2 transform -translate-y-1/2 right-3 cursor-pointer opacity-20"
                      />
                    </div>

                    {/* Submit Button */}
                    <button
                      type="submit"
                      onClick={submitchangedqty}
                      className="bg-dbase rounded-md text-white px-5 mt-3"
                    >
                      Submit Quantity
                    </button>

                    {/* Display the counter */}
                    {quantityCounter > 0 && (
                      <div className="mt-3">
                        Quantity set: {quantityCounter}
                      </div>
                    )}
                  </div>
                )}

                {/* Additional content like Stand Quantity and Product Info */}
                {standQuantity && (
                  <div className="mt-5 p-4 bg-blue-100 rounded-md text-blue-700">
                    Stand Quantity: {standQuantity}
                  </div>
                )}
                {ShowProductInput && (
                  <div className="flex flex-col items-center mt-5">
                    <div className="flex flex-row gap-3 w-1/2 items-center">
                      <div className="relative flex-1">
                        <input
                          required
                          ref={productIdInputRef}
                          type="text"
                          value={productIdInput}
                          onChange={(e) => setProductIdInput(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter" && productIdInput) {
                              submitBinChange(e);
                            }
                          }}
                          className="border border-dlabelColor outline-dbase w-full rounded-md py-3 pr-8 pl-2"
                          placeholder="Product ID Or SKU"
                        />
                      </div>
                    </div>
                    <button
                      type="submit"
                      onClick={submitBinChange}
                      className="bg-dbase rounded-md text-white px-5 mt-3"
                    >
                      {loading ? <Loader /> : <p>Change</p>}
                    </button>
                  </div>
                )}

                {productInf && (
                  <div className="h-full flex flex-col justify-center text-center gap-3">
                    <div className="flex gap-2 justify-center  flex-row my-4">
                      <div className="h-full my-auto w-full">
                        <img
                          className="object-cover w-full h-full"
                          src={productInf.image}
                        />
                      </div>
                      <div>
                        <div>
                          <h2 className="text-start font-bold">Product :</h2>
                          <table className="w-full ">
                            <tbody>
                              <tr>
                                <td
                                  colSpan={2}
                                  className="border text-center border-dlabelColor px-2 py-2 whitespace-pre-wrap"
                                >
                                  Sku: {productInf.sku}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  colSpan={2}
                                  className="border text-center border-dlabelColor px-2 py-2 whitespace-pre-wrap"
                                >
                                  Quantity: {productInf.quantity}
                                </td>
                              </tr>
                              <tr>
                                {!productInf.option_name && (
                                  <td
                                    colSpan={2}
                                    className="border text-center border-dlabelColor px-2 py-2"
                                  >
                                    Big Upc: {productInf.big_upc || "_"}
                                  </td>
                                )}
                              </tr>
                              {productInf.address && (
                                <tr>
                                  <td
                                    colSpan={2}
                                    className="border flex flex-col justify-center text-center border-dlabelColor px-2 py-2"
                                  >
                                    <strong>Address:</strong>{" "}
                                    <span>{productInf.address}</span>
                                  </td>
                                </tr>
                              )}
                              {productInf.upc && !productInf.bin && (
                                <div className="flex flex-col">
                                  <td className="border text-center border-dlabelColor px-2 py-2">
                                    Upc: {productInf.upc}
                                  </td>
                                  <td className="border text-center border-dlabelColor px-2 py-2">
                                    Min Upc:{" "}
                                    {productInf.min_upc_quantity || "_"}
                                  </td>
                                  <td className="border text-center border-dlabelColor px-2 py-2">
                                    Max Upc:{" "}
                                    {productInf.max_upc_quantity || "_"}
                                  </td>
                                  <td className="border text-center border-dlabelColor px-2 py-2">
                                    Upc Qty: {productInf.upc_quantity || "_"}
                                  </td>
                                </div>
                              )}
                              {!productInf.upc && productInf.bin && (
                                <>
                                  <td className="border text-center border-dlabelColor px-2 py-2">
                                    Bin: {productInf.bin}
                                  </td>
                                  <td className="border text-center border-dlabelColor px-2 py-2">
                                    Min Bin:{" "}
                                    {productInf.min_bin_quantity || "_"}
                                  </td>
                                  <td className="border text-center border-dlabelColor px-2 py-2">
                                    Max Bin:{" "}
                                    {productInf.max_bin_quantity || "_"}
                                  </td>
                                  <td className="border text-center border-dlabelColor px-2 py-2">
                                    Bin Qty: {productInf.bin_quantity || "_"}
                                  </td>
                                  <td className="border text-center border-dlabelColor px-2 py-2">
                                    Address: {productInf.address || "_"}
                                  </td>
                                </>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div className="w-full rounded-lg border   bg-dplaceHolder py-3">
                      In Stand Qty : {productInf.in_stand_quantity}
                    </div>
                  </div>
                )}
              </form>
            )}
          </div>
        )}

        {activeTab === 1 && (
          <>
            <div className="container">
              <form
                onSubmit={(e) => searchProduct(e)}
                className="flex flex-row justify-center gap-3 "
              >
                <div className="relative flex-1">
                  <input
                    required
                    ref={productId}
                    type="text"
                    className="border border-dlabelColor disabled:bg-dplaceHolder outline-dbase w-full rounded-md  py-3 pr-8  pl-2"
                    placeholder="Product ID or SKU"
                  />
                  <div onClick={() => handleProductIdTrashClick()}>
                    <FaTrash className="absolute top-1/2 transform -translate-y-1/2 right-3 cursor-pointer opacity-20 " />
                  </div>
                </div>

                <button
                  type="submit"
                  className=" bg-dbase rounded-md text-white px-5 "
                >
                  {" "}
                  {loadingSearch ? <Loader /> : <FaSearch />}{" "}
                </button>
              </form>

              <form onSubmit={(e) => getBinInfoCheck(e)}>
                <div className=" flex flex-row justify-center gap-3  mt-5">
                  <div className="relative flex-1">
                    <input
                      disabled={disabledInputs}
                      required
                      ref={binId}
                      type="text"
                      className="border border-dlabelColor outline-dbase w-full rounded-md  py-3 pr-8  pl-2"
                      placeholder="Bin ID"
                    />
                    <div>
                      <FaTrash
                        onClick={() => handleTrashClick()}
                        className="absolute top-1/2 transform -translate-y-1/2 right-3 cursor-pointer opacity-20 "
                      />
                    </div>
                  </div>
                </div>
                <div className=" flex flex-row justify-center gap-3  mt-5">
                  <div className="relative flex-1">
                    <input
                      disabled={disabledInputs}
                      required
                      ref={qty}
                      type="text"
                      className="border border-dlabelColor outline-dbase w-full rounded-md  py-3 pr-8  pl-2"
                      placeholder="Quantity"
                    />
                    <div onClick={() => (productId.current.value = "")}>
                      <FaTrash className="absolute top-1/2 transform -translate-y-1/2 right-3 cursor-pointer opacity-20 " />
                    </div>
                  </div>
                  <button
                    type="submit"
                    className=" bg-dbase rounded-md text-white px-5 "
                  >
                    {" "}
                    {loading ? <Loader /> : <FaSearch />}{" "}
                  </button>
                </div>
              </form>

              {productInf && (
                <div className="h-full flex flex-col justify-center text-center gap-3">
                  <div className="flex gap-2 justify-center  flex-row my-4">
                    <div className="h-full my-auto w-full">
                      <img
                        className="object-cover w-full h-full"
                        src={productInf.image}
                      />
                    </div>
                    <div>
                      <div>
                        <h2 className="text-start font-bold">Product :</h2>
                        <table className="w-full ">
                          <tbody>
                            <tr>
                              <td
                                colSpan={2}
                                className="border text-center border-dlabelColor px-2 py-2 whitespace-pre-wrap"
                              >
                                Sku: {productInf.sku}
                              </td>
                            </tr>
                            <tr>
                              <td
                                colSpan={2}
                                className="border text-center border-dlabelColor px-2 py-2 whitespace-pre-wrap"
                              >
                                Quantity: {productInf.quantity}
                              </td>
                            </tr>
                            <tr>
                              {!productInf.option_name && (
                                <td
                                  colSpan={2}
                                  className="border text-center border-dlabelColor px-2 py-2"
                                >
                                  Big Upc: {productInf.big_upc || "_"}
                                </td>
                              )}
                            </tr>
                            {productInf.address && (
                              <tr>
                                <td
                                  colSpan={2}
                                  className="border flex flex-col justify-center text-center border-dlabelColor px-2 py-2"
                                >
                                  <strong>Address:</strong>{" "}
                                  <span>{productInf.address}</span>
                                </td>
                              </tr>
                            )}
                            {productInf.upc && !productInf.bin && (
                              <div className="flex flex-col">
                                <td className="border text-center border-dlabelColor px-2 py-2">
                                  Upc: {productInf.upc}
                                </td>
                                <td className="border text-center border-dlabelColor px-2 py-2">
                                  Min Upc: {productInf.min_upc_quantity || "_"}
                                </td>
                                <td className="border text-center border-dlabelColor px-2 py-2">
                                  Max Upc: {productInf.max_upc_quantity || "_"}
                                </td>
                                <td className="border text-center border-dlabelColor px-2 py-2">
                                  Upc Qty: {productInf.upc_quantity || "_"}
                                </td>
                              </div>
                            )}
                            {!productInf.upc && productInf.bin && (
                              <>
                                <td className="border text-center border-dlabelColor px-2 py-2">
                                  Bin: {productInf.bin}
                                </td>
                                <td className="border text-center border-dlabelColor px-2 py-2">
                                  Min Bin: {productInf.min_bin_quantity || "_"}
                                </td>
                                <td className="border text-center border-dlabelColor px-2 py-2">
                                  Max Bin: {productInf.max_bin_quantity || "_"}
                                </td>
                                <td className="border text-center border-dlabelColor px-2 py-2">
                                  Bin Qty: {productInf.bin_quantity || "_"}
                                </td>
                                <td className="border text-center border-dlabelColor px-2 py-2">
                                  Address: {productInf.address || "_"}
                                </td>
                              </>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div className="w-full rounded-lg border   bg-dplaceHolder py-3">
                    In Stand Qty : {productInf.in_stand_quantity}
                  </div>
                </div>
              )}

              {/* {info && (
             <div className={`flex container flex-row  mt-4 border rounded-md ${start ? 'bg-Energyplus bg-opacity-60' : ''} ${successBackground ? 'bg-dgreen2' : ''}`}>
                {info && <DisplayProductInfo productInfo={info} />}

                {info && (
                  <div className="object-cover h-fit w-fit rounded-md m-auto container">
                    {info.mobile_image && <img src={info.mobile_image} />}
                    {!info.mobile_image && info.popup && (
                      <img src={info.popup} />
                    )}
                    {!info.mobile_image && !info.popup && info.thumb && (
                      <img src={info.thumb} />
                    )}
                  </div>
                )}
              </div>)} */}
            </div>
            <div>
              {!start && action && action === "count" && (
                <button
                  onClick={() => setStart(true) && scanRef.current.focus()}
                  className="bg-dbase border border-dblack container rounded-md p-3 text-white mt-3"
                >
                  Start
                </button>
              )}
              <form
                onSubmit={(e) => assignProductToBin(e)}
                className="container"
              >
                {start && action && action === "count" && (
                  <div className="flex flex-row border-dblack rounded-lg text-center max-w-sm mx-auto container ">
                    <input
                      ref={scanRef}
                      type="text"
                      // value={yourValue}
                      // onChange={(e) => handleChange(e)}
                      autoFocus
                      className="border border-dblack rounded-lg block p-2.5 max-w-sm mx-auto mt-4 text-center w-full"
                      placeholder="Scan Item"
                      required
                      disabled={processingRequest}
                    />
                    {/* <button className="border border-dblack bg-dbase rounded-lg block max-w-sm mx-auto text-white px-5 ">
                      {" "}
                      {loading ? <Loader /> : <FaSearch />}{" "}
                    </button> */}
                  </div>
                )}
              </form>

              {action && action === "assign" && (
                <button
                  onClick={(e) => {
                    assignProductToBin_forassign(e);
                  }}
                  className={` flex flex-row justify-center text-center gap-2 border border-dblack container rounded-md p-3 text-white mt-3 ${
                    loadingAssign
                      ? "  bg-dplaceHolder text-dblack "
                      : "bg-dbase"
                  }`}
                  disabled={loadingAssign}
                >
                  <span>
                    {requestSuccess ? "Assigned Successfully" : "Assign"}
                  </span>
                  {loadingAssign && (
                    <div className=" w-4 h-4 relative">
                      <Loader />
                    </div>
                  )}
                </button>
              )}
              {start && (
                <div class="  flex flex-col max-w-sm mx-auto mt-5">
                  <label className=" mb-1 container text-center h-fit  bg-white font-bold text-dbase border rounded-md w-fit ">
                    Counter:
                  </label>
                  <input
                    className="border-2 border-dblack rounded-lg block w-full p-2.5 text-center text-2xl"
                    placeholder="0"
                    readOnly={true}
                    value={counterOnScan}
                  />
                </div>
              )}
            </div>
          </>
        )}
        {activeTab === 2 && (
          <>
            <div className="container">
              <form onSubmit={(e) => unassignfromBin(e)}>
                <div className=" flex flex-row justify-center gap-3 ">
                  <div className="relative flex-1">
                    <input
                      ref={binId}
                      type="text"
                      className="border border-dlabelColor outline-dbase w-full rounded-md  py-3 pr-8  pl-2"
                      placeholder="Bin ID"
                      required
                    />
                    <div>
                      <FaTrash
                        onClick={() => handleTrashClick()}
                        className="absolute top-1/2 transform -translate-y-1/2 right-3 cursor-pointer opacity-20 "
                      />
                    </div>
                  </div>
                </div>

                <div className=" flex flex-row justify-center gap-3  mt-5">
                  <div className="relative flex-1">
                    <input
                      ref={productId}
                      type="text"
                      className="border border-dlabelColor outline-dbase w-full rounded-md  py-3 pr-8  pl-2"
                      placeholder="Product ID or SKU"
                      required
                    />
                    <div onClick={() => (productId.current.value = "")}>
                      <FaTrash className="absolute top-1/2 transform -translate-y-1/2 right-3 cursor-pointer opacity-20 " />
                    </div>
                  </div>
                  <button
                    type="submit"
                    className=" bg-dbase rounded-md text-white px-5 "
                  >
                    {loading ? <Loader /> : <IoMdRemoveCircleOutline />}{" "}
                  </button>
                </div>
              </form>
            </div>
          </>
        )}
        <div className="container mt-4  px-3 gap-1 flex-col  flex rounded-md ">
          {products &&
            products.map((product) => {
              return (
                <div className="container  border rounded-md border-dlabelColor flex flex-row">
                  <div>
                    <img className="  w-32" src={product.image}></img>
                  </div>
                  <div className=" flex p-3 flex-col text-start gap-1">
                    <p>
                      <strong>Sku: </strong>
                      {product.sku}
                    </p>
                    <p>
                      <strong>Barcode: </strong>
                      {product.product_id}
                    </p>
                    <p>
                      <strong>Quantity: </strong>
                      {product.quantity}
                    </p>
                  </div>
                </div>
              );
            })}
        </div>

        {openAlert && (
          <div
            onClick={() => setOpenAlert(false)}
            className=" fixed w-full h-full inset-0  bg-dblackk  bg-opacity-35 z-40 "
          ></div>
        )}
        {openAlert && (
          <div className=" fixed w-[70%] rounded-md  top-[40%] px-4 py-3 z-50 left-[13%] bg-white">
            <div className=" relative w-full h-full flex flex-col  justify-center">
              <h2 className=" text-xl text-dblackk font-bold">
                Note!. This item is in another address please{" "}
                <span className=" text-dbase">unassign </span> before assigning
                again!
              </h2>
              <div className=" w-full flex flex-row mt-5 justify-center gap-5">
                <button
                  onClick={() => {
                    setOpenAlert(false);
                  }}
                  className=" w-full py-2 bg-dbase1  text-white text-lg font-bold rounded-md"
                >
                  {" "}
                  Ok{" "}
                </button>
                {/* <button onClick={()=>{
                  setAllowContinueCheckBin(true);
                
                }} className=" w-full py-2 bg-dgreen text-white text-lg font-bold rounded-md">Yes</button> */}
              </div>
            </div>
          </div>
        )}

        <MessageAlert
          message={message}
          type={showMessgErr ? "err" : "succ"}
          showMessg={showMessgErr || (showMessgSucc && true)}
        />
      </div>
    </>
  );
};

export default hocStarredPage(BinPagev2);
