import React from 'react'
import { FaArrowLeft, FaBox } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import NavigateButton from '../../../components/buttonsNavigate'

const OtherPage = () => {
    const history = useNavigate()
  return (
    <>
     {/* header */}
     <div className=" mb-5 sticky top-0 flex flex-col bg-white">
     <div className=" w-full bg-dbase h-16">
       <div className=" container flex relative gap-7 flex-row justify-start w-full text-white  h-full my-auto">
         <button onClick={() => history(-1)}>
           <FaArrowLeft />
         </button>
         <h2 className=" text-xl    my-auto">Other Page</h2>
       </div>
     </div>
   </div>
   <div className="  grid grid-cols-2 container gap-x-6 gap-y-4">
          
          
          <NavigateButton
            icon={<FaBox />}
            name={"PALLET"}
            arname={" "}
            color={"bg-[rgb(204,204,0)]"}
            onclick={() => history("pallet")}
          />
           
        </div>
   </>
  )
}

export default OtherPage