export const LogisticReducer = (state, action) => {
  switch (action.type) {
    case "setLogistic":
      return {
        ...state,
        logisticList: action.payload,
      };

    default:
      return state;
  }
};

export const initialState = {
  logisticList: [],
};
