import { useReducer, createContext } from "react"
import { LogisticReducer,initialState } from "../reducers/logisticReducer"

export const LogisticContext = createContext({
    state: initialState,
    dispatch: () => null
})

export const LogisticProvider = ({ children }) => {
    const [state, dispatch] = useReducer(LogisticReducer, initialState)

    return (
        <LogisticContext.Provider value={[state, dispatch]}>
            {children}
        </LogisticContext.Provider>
    )
}