import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import NavigateButton from "../../../components/buttonsNavigate";
import hocStarredPage from "../../../components/hocStarredPage";

const Transfer = () => {
  const history = useNavigate();
  var host = window.location.host;
  return (
    <>
      <div className=" h-full bg-white">
        <div className=" mb-5 sticky top-0 flex flex-col bg-white">
          {/* header */}

          <div className=" w-full bg-dbase h-16">
            <div className=" container flex relative gap-7 flex-row justify-start w-full text-white  h-full my-auto">
              <button onClick={() => history(-1)}>
                <FaArrowLeft />
              </button>
              <h2 className=" text-xl    my-auto">Transfer</h2>
            </div>
          </div>
        </div>

        <div className="  grid grid-cols-2 container gap-x-6 gap-y-4">
     
          { (host.startsWith("localhost") ||  host === "pda.ishtari.com"|| host === "pda.ishtari.com.gh") && (
   <NavigateButton
   name={"READY"}
   color={"bg-[rgb(147,51,234)]"}
   onclick={() => history("ready")}
 />
)} 
          <NavigateButton
            name={"GIVEN"}
            color={"bg-[rgb(7,89,133)]"}
            onclick={() => history("given")}
          />
{ (host.startsWith("localhost") || host === "pda.flo-lebanon.com" || host === "pda.englishhome.com.lb") && (
  <NavigateButton
    name={"RECEIVE"}
    color={"bg-dbluedark"}
    onclick={() => history("receive")}
  />
)}   
             <NavigateButton
            name={"FAILED"}
            color={"bg-dbase"}
            onclick={() => history("failed")}
          />
           
    
        </div>
      </div>
    </>
  );
};

export default hocStarredPage(Transfer);
