import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { FaArrowLeft, FaEye, FaPlus, FaTrash } from "react-icons/fa";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import buildLink from "../../../urls";
import { CompanyContext } from "../../../contexts/companyCotext";
import MessageAlert from "../../../components/messageAlert";
import { PlaySounds } from "../../../functions/playSounds";
import Cookies from "js-cookie";

const JardehSinglePage = () => {


  const {playErrorSound,playSuccessSound} = PlaySounds();
const history = useNavigate();
const{idJardeh}= useParams();
const [batchList,setBatchList]=useState([])
const location= useLocation();
const {name}= location.state;
const [stateCom,dispatchCom]=useContext(CompanyContext);
const [showMessgErr, setShowMessageErr] = useState(false);
const [message, setMessage] = useState("");
const [showMessgSucc, setShowMessageSucc] = useState(false);

const getUserIdFromLocalStorage = () => {
  return localStorage.getItem("userID");
};

async function getJardehBatch() {
  const userID = getUserIdFromLocalStorage()
 try{ await axios.get(buildLink("getJardehBatch")+`&jardeh_id=${idJardeh}&user_id=${userID}`).then((res) => {
    setBatchList(res.data.data);
  });}
  catch(e){
    playErrorSound()
    setMessage(e.response.data.message);
    setShowMessageErr(true);
    setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
  }
}

useEffect(()=>{getJardehBatch()},[])



async function deleteBatch(id){
  const userID = getUserIdFromLocalStorage()
  const obj={
    batch_id:id,
    user_id: userID
  }
  try{
  await axios.delete(buildLink("deleteBatch"),{ data: obj }).then((res)=>{
    playSuccessSound()
    setMessage(res.data.message);
    setShowMessageSucc(true);
    setTimeout(() => {
      setShowMessageSucc(false);
    }, 3000);
})
}catch(e){
  playErrorSound()
setMessage(e.response.data.message);
setShowMessageErr(true);
setTimeout(() => {
    setShowMessageErr(false);
  }, 3000);
}
}

  return (
    <>
      <div className=" h-full ">
        {/* header */}
        <div className=" mb-5 sticky top-0 flex flex-col bg-white">
          <div className=" w-full bg-dbase h-16">
            <div className=" container px-3  relative flex justify-between w-full text-white  h-full my-auto">
              <div className=" flex  gap-7 flex-row justify-start">
              <button onClick={() => history(-1)}>
                <FaArrowLeft />
              </button>
              <h2 className=" text-xl    my-auto">Jardeh Details ({idJardeh})</h2>
            </div>
            <div className=" flex flex-row justify-end text-end gap-10 ">
            <button onClick={()=>{history(`/home/jardeh/jardehAddBadge/${name}/${idJardeh}`)}} ><FaPlus/></button>
            <button onClick={()=>history(`/home/jardeh/viewall/${idJardeh}`)}><FaEye/></button>
            </div>
           </div>
          </div>
        </div>
        <div className=" flex flex-col gap-2 px-2">
       {batchList&& batchList.map((batch)=>{
        return <><div className=" w-full bg-white shadow-md  flex flex-row justify-between px-3 py-2">
          <div onClick={()=>history(`/home/jardeh/viewall/${idJardeh}?batch_id=${batch.batch_id}`)} className=" flex flex-col text-start">
            
            <p><strong>Batch:</strong>{" "}{batch.name}</p>
            <p><strong>User:</strong>{" "}{batch.username}</p>
            <p><strong>Date:</strong>{" "}{batch.date_added}</p>
          
          
          </div>
          <div className="  ">
            <button onClick={()=>deleteBatch(batch.batch_id)} className=" h-full my-auto">
            <FaTrash className=" text-dbase h-fit my-auto"/>
            </button>
          </div>
          </div></>
       })}
       </div>
      </div>
      <MessageAlert
          message={message}
          type={showMessgErr ? "err" : "succ"}
          showMessg={showMessgErr || (showMessgSucc && true)}
        />
    </>
  );
};

export default JardehSinglePage;