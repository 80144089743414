import { useContext, useEffect, useState } from "react";
import axios from "axios";
import buildLink from "../urls";
import { CompanyContext } from "../contexts/companyCotext";
import Cookies from "js-cookie";
import { format } from "date-fns";
import { PlaySounds } from "./playSounds";

export const OroderStatusHook = () => {
  const [loading, setLoading] = useState(false);
  const { playErrorSound, playSuccessSound } = PlaySounds();
  const [logisticSelect, setLogisticSelect] = useState(null);
  const [stateCom, dispatchCom] = useContext(CompanyContext);
  const [showMessgSucc, setShowMessageSucc] = useState(false);
  const [message, setMessage] = useState("");
  const [showMessgErr, setShowMessageErr] = useState(false);
  const [constNumPack, setConstNumPack] = useState(0);
  const [orderList, setOrdersList] = useState([]);
  const [orderStatus, setorderStatus] = useState("normal");
  const [numPackage, setNumPackage] = useState(1);
  const [OldOrderId, setOldOrderId] = useState(null);

  let orderListCookies =
    Cookies.get("ordersHistory") === undefined
      ? []
      : JSON.parse(Cookies.get("ordersHistory"));
  var orderListVar = orderListCookies;

  const getUserIdFromLocalStorage = () => {
    return localStorage.getItem("userID");
  };

  const updateOrderStatus = async (e = "", type = "", orderId = "") => {
    // if(!OldOrderId || orderId === OldOrderId && numPackage ===1){
    //   setOldOrderId(orderId);
    // console.log("BK");

    const userID = getUserIdFromLocalStorage();
    const currentDate = new Date();
    const formattedDate = format(currentDate, "dd-MM-yy HH:mm:ss");
    e.preventDefault();
    console.log(OldOrderId);
    console.log(orderId);
    if (numPackage > 1) {
      if (OldOrderId === orderId || !OldOrderId) {
        setNumPackage(numPackage - 1);

        setOldOrderId(orderId);
      } else {
        setMessage("Order Ids Doesn't Match");
        playErrorSound();
        setShowMessageErr(true);
        setTimeout(() => {
          setShowMessageErr(false);
        }, 3000);
      }
    } else if (numPackage === 1 && (OldOrderId === orderId || !OldOrderId)) {
      setLoading(true);
      const obj = {
        logistic_id: logisticSelect,
        user_id: userID,
        type: type,
        nb_of_pack: constNumPack,
        upc_id: "",
        item: "",
      };
      var url = "";
      if (orderStatus === "normal") {
        obj.order_id = orderId;
        url = buildLink("updateOrder");
      } else {
        obj.return_order_id = orderId;
        url = buildLink("updateReturnOrder");
      }
      try {
        const response = await axios.put(url, obj);
        if (response.data.success) {
          setMessage(response.data.message);
          playSuccessSound();
          setShowMessageSucc(true);
          const objtable = {
            date: formattedDate,
            user: stateCom.username,
            id: orderId,
            status: response.data.current_status,
            reason: response.data.message,
            success: true,
          };
          setOrdersList((prevProducts) => [objtable, ...prevProducts]);

          orderListVar.push(objtable);
          handleSetCookies(orderListVar);
          setTimeout(() => {
            setShowMessageSucc(false);
          }, 3000);
          setLoading(false);

          return true;
        }
        setLoading(false);
      } catch (e) {
        if (
          e.response.data.data === undefined ||
          e.response.data.data == null
        ) {
        } else {
          const objtable = {
            date: formattedDate,
            user: stateCom.username,
            id: orderId,
            status: e.response.data.data.current_status,
            reason: e.response.data.message,
            success: false,
          };
          setOrdersList((prevProducts) => [...prevProducts, objtable]);
          orderListVar.push(objtable);
          // console.log(orderListVar);
          handleSetCookies(orderListVar);
          setLoading(false);
        }
        playErrorSound();
        // }
        setMessage(e.response.data.message);
        setShowMessageErr(true);
        setTimeout(() => {
          setShowMessageErr(false);
        }, 3000);
        setLoading(false);

        return false;
      }

      setLoading(false);
      setOldOrderId(null);
    }
  };
  const handleRadioChange = (event) => {
    setNumPackage(Number(event.target.value));
    setConstNumPack(Number(event.target.value));
  };

  const handleSetCookies = (list) => {
    const listString = JSON.stringify(list);
    Cookies.set("ordersHistory", listString);
  };

  return {
    updateOrderStatus,
    showMessgErr,
    setorderStatus,
    numPackage,
    setNumPackage,
    setLogisticSelect,
    setOrdersList,
    orderList,
    message,
    showMessgSucc,
    loading,
    handleRadioChange,
  };
};
