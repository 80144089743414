import React, { useContext, useEffect, useRef, useState } from "react";
import { FaArrowLeft, FaSearch, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import buildLink from "../../../urls";
import axios from "axios";
import { CompanyContext } from "../../../contexts/companyCotext";
import { IoIosWarning } from "react-icons/io";
import Loader from "../../../components/loader";
import MessageAlert from "../../../components/messageAlert";
import { PlaySounds } from "../../../functions/playSounds";
import hocStarredPage from "../../../components/hocStarredPage";

const ReturnReasonforProductv2 = () => {
  const { playErrorSound, playSuccessSound } = PlaySounds();
  const history = useNavigate();
  const codePr = useRef(null);
  const upcRef = useRef(null);
  const [stateCom, dispatchCom] = useContext(CompanyContext);
  const [loading, setLoading] = useState(false);
  const [productInf, setProductInf] = useState(null);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [showMessgErr, setShowMessageErr] = useState(false);
  const [message, setMessage] = useState("");
  const [showMessgSucc, setShowMessageSucc] = useState(false);

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const searchProduct = async (e) => {
    setLoadingSearch(true);
    e.preventDefault();

    const return_order_product_id = codePr.current.value;

    try {
      const url =
        buildLink("returnReasonforProduct") +
        `&return_order_product_id=${return_order_product_id}&v2=true` +
        `&user_id=${stateCom.userID}`;
      const response = await axios.get(url);
      if (response.data.success) {
        // console.log('Response Data:', response.data);
        setProductInf(response.data.data);
        // console.log('Product Info:', response.data.data);
        // upcRef.current.focus();
      }
    } catch (e) {
      console.error('Error:', e);
      playErrorSound();
      setMessage(e?.response?.data?.message || 'An error occurred');
      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
    } finally {
      setLoadingSearch(false);
    }
  };


  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    codePr.current.focus();
  }, []);

  const handleTrashClick = () => {
    codePr.current.value = "";
    codePr.current.focus();
  };

  return (
    <>
      <div className="h-full bg-white">
        {/* header */}
        <div className="mb-5 sticky top-0 flex flex-col bg-white">
          <div className="w-full bg-dbase h-16">
            <div className="container flex relative gap-7 flex-row justify-start w-full text-white h-full my-auto">
              <button onClick={() => history(-1)}>
                <FaArrowLeft />
              </button>
              <h2 className="text-xl my-auto">Return Reason for Product</h2>
            </div>
          </div>
        </div>
        <div className="container flex flex-col gap-5">
          <form onSubmit={(e) => searchProduct(e)}>
            <div className="flex flex-row justify-center gap-3">
              <div className="relative flex-1">
                <input
                  ref={codePr}
                  type="text"
                  className="border border-dlabelColor outline-dbase w-full rounded-md py-3 pr-8 pl-2"
                  placeholder="Scan Return Product ID"
                />
                <div>
                  <FaTrash
                    onClick={() => handleTrashClick()}
                    className="absolute top-1/2 transform -translate-y-1/2 right-3 cursor-pointer opacity-20"
                  />
                </div>
              </div>
              <button className="bg-dbase rounded-md text-white px-5">
                {loadingSearch ? <Loader /> : <FaSearch />}
              </button>
            </div>
          </form>
        </div>

        {productInf && (
  <div className="container mx-auto my-5 p-5">
    <div className="bg-gray-100 bg-dinputBorder rounded-lg shadow-md p-6">
      <h2 className="text-xl font-semibold mb-4">Return Reason</h2>
      <div className="flex items-center gap-4">
        <div className="flex-1">
          <div className="p-4 w-full relative overflow-hidden bg-white rounded-lg shadow-inner border border-dgrey1">
            <p className="text-dbase1  text-wrap whitespace-pre-wrap font-extrabold text-d20 text-base">
              {productInf.comment}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
)}



        <MessageAlert
          message={message}
          type={showMessgErr ? "err" : "succ"}
          showMessg={showMessgErr || showMessgSucc}
        />
      </div>
    </>
  );
};

export default hocStarredPage(ReturnReasonforProductv2);
