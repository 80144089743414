import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { FaArrowLeft, FaSync, FaTrash } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import buildLink from "../../../urls";
import { CompanyContext } from "../../../contexts/companyCotext";
import Loader from "../../../components/loader";
import MessageAlert from "../../../components/messageAlert";
import { PlaySounds } from "../../../functions/playSounds";
import { useLocation } from "react-router-dom";
import { FaSearch } from "react-icons/fa";

const ChangeSingleProductStatusv2 = () => {
  const { playErrorSound, playSuccessSound } = PlaySounds();
  const history = useNavigate();
  const [productInf, setProductInf] = useState([]);
  const [showMessgSucc, setShowMessageSucc] = useState(false);
  const [message, setMessage] = useState("");
  const [showMessgErr, setShowMessageErr] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [orderIds, setOrderIds] = useState([]);
  const [showBinInput, setShowBinInput] = useState(false); // State to control bin_id input visibility
  const codePr = useRef(null);
  const { name, id } = useParams();
  const decodedName = decodeURIComponent(name);
  const [stateCom] = useContext(CompanyContext);
  const binid = useRef(null);
  const location = useLocation();

  // Counter to track the number of successfully updated products
  const successfulUpdates = productInf.filter(
    (product) => product.success
  ).length;

  const handleDeleteProduct = (productId) => {
    const indexToDelete = productInf.findIndex(
      (prod) => prod.product_id === productId
    );

    if (indexToDelete !== -1) {
      const updatedProducts = [
        ...productInf.slice(0, indexToDelete),
        ...productInf.slice(indexToDelete + 1),
      ];

      setProductInf(updatedProducts);
    }
  };
  const handleReset = () => {
    setProductInf([]);
    setShowBinInput(false);
  };

  const getproductinfo = async (e) => {
    e.preventDefault();
    setLoading(true);
    const item = codePr.current.value;
    codePr.current.blur();

    try {
      const url = `${buildLink("productInfo")}&product_id=${
        codePr.current.value
      }&v2=true&user_id=${stateCom.userID}`;

      const response = await axios.get(url);
      if (response.data.success) {
        const fetchedData = response.data.data;

        if (
          fetchedData &&
          fetchedData.bin_id &&
          Object.keys(fetchedData).length > 0
        ) {
          // console.log(fetchedData.bin_id);
          setShowBinInput(true);
        } else {
          playErrorSound();
          setShowBinInput(false);
          setShowMessageErr(true);
          setMessage(
            "This Product Not Assigned To An Address . Assign It."
          );
          setTimeout(() => {
            setShowMessageErr(false);
          }, 3000);
        }
      } else {
        setShowBinInput(false);
      }
    } catch (e) {
      playErrorSound();
      setLoading(false);

      const errorMessage =
        e.response && e.response.data
          ? e.response.data.message
          : "Something went wrong";
      setMessage(errorMessage);
      setShowMessageErr(true);

      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);

      setShowBinInput(false);
    }

    setLoading(false);
  };

  const [selectedOrderId, setSelectedOrderId] = useState(null);

  async function updateProductStatus(e, selectedOrderId) {
    e && e.preventDefault();
    setLoading(true);

    const productId = codePr.current ? codePr.current.value : "";
    const binIdValue = binid.current ? binid.current.value : null;

    const obj = {
      product_id: productId,
      status_id: id,
      user_id: stateCom.userID,
      bin_id: location.pathname.includes("Restock") ? binIdValue : null,
    };

    let fetchedBinId;

    try {
      const productInfoResponse = await axios.get(
        buildLink("productInfo") +
          `&product_id=${productId}&v2=true&user_id=${stateCom.userID}`
      );

      if (productInfoResponse.data.success) {
        const fetchedData = productInfoResponse.data.data;
        fetchedBinId = fetchedData.bin_id;
      } else {
        throw new Error("Product not found.");
      }

      if (binid.current && fetchedBinId !== binIdValue) {
        playErrorSound();
        setMessage(
          "Bin ID does not match the product's bin ID. Status change not allowed."
        );
        setShowMessageErr(true);
        setTimeout(() => setShowMessageErr(false), 3000);
        return;
      }

      if (selectedOrderId) {
        obj.order_id = selectedOrderId;
      }

      const response = await axios.post(buildLink("updateProductStatus"), obj);

      if (response && response.data) {
        if (response.data.success) {
          playSuccessSound();
          setMessage(
            response.data.message || "Product status updated successfully."
          );
          setShowMessageSucc(true);
          setTimeout(() => setShowMessageSucc(false), 3000);

          const updatedProduct = {
            product_id: productId,
            status: response.data.status || "Updated",
            success: true,
            bin_id: binIdValue || "null",
          };

          setProductInf((prevProducts) => [...prevProducts, updatedProduct]);
          handleTrashClickID();
        } else if (response.data.message) {
          if (
            Array.isArray(response.data.order_ids) &&
            response.data.order_ids.length > 1
          ) {
            setOrderIds(response.data.order_ids);
            setShowPopup(true);
          } else {
            setMessage(response.data.message);
            playErrorSound();
          }
          return;
        } else {
          throw new Error(
            response.data.message || "Error updating product status"
          );
        }
      } else {
        throw new Error("Unexpected response structure");
      }

      if (codePr.current) {
        codePr.current.value = "";
      }
      if (binid.current) {
        binid.current.value = "";
      }
    } catch (e) {
      console.error("Error updating product status:", e);

      if (axios.isAxiosError(e)) {
        if (e.response) {
          if (
            e.response.status === 400 &&
            e.response.data.success === false &&
            e.response.data.message.includes("Found IN More Than Order")
          ) {
            setOrderIds(e.response.data.order_ids);
            if (
              Array.isArray(e.response.data.order_ids) &&
              e.response.data.order_ids.length > 1
            ) {
              setShowPopup(true);
            }
            return;
          }

          if (e.response.status === 401 && e.response.data.message) {
            setOrderIds(e.response.data.message);
            setShowPopup(true);
          } else {
            playErrorSound();
            setMessage(
              e.response.data.message || "Error updating product status"
            );
            setShowMessageErr(true);
            setTimeout(() => setShowMessageErr(false), 3000);
          }
        } else {
          playErrorSound();
          setMessage("Network Error: Please check your connection.");
          setShowMessageErr(true);
          setTimeout(() => setShowMessageErr(false), 3000);
        }
      } else {
        playErrorSound();
        setMessage(e.message || "Error updating product status");
        setShowMessageErr(true);
        setTimeout(() => setShowMessageErr(false), 3000);
      }
    } finally {
      setLoading(false);
    }
  }

  const handleOrderSelection = (orderId) => {
    setSelectedOrderId(orderId);
    setShowPopup(false);
    updateProductStatus(null, orderId);
  };

  useEffect(() => {
    codePr.current.focus();
  }, []);

  const handleTrashClickID = () => {
    codePr.current.value = "";
    codePr.current.focus();
    setShowBinInput(false);
  };

  const handleTrashClickBin = () => {
    binid.current.value = "";
    binid.current.focus();
  };

  const handleOrderIdClick = (orderId) => {
    setShowPopup(false);
    updateProductStatus({ preventDefault: () => {} }, orderId);
  };

  return (
    <div className="h-full bg-white">
      {/* Header */}
      <div className="mb-5 sticky top-0 z-30 flex flex-col bg-white">
        <div className="w-full bg-dbase h-16">
          <div className="container flex relative gap-7 flex-row justify-start w-full text-white h-full my-auto">
            <button onClick={() => history(-1)}>
              <FaArrowLeft />
            </button>
            <h2 className="text-xl my-auto">{decodedName}</h2>
          </div>
        </div>
      </div>

      {/* Form */}
      <div className="container">
        <form
          onSubmit={(e) =>
            location.pathname.includes("Restock")
              ? getproductinfo(e)
              : updateProductStatus(e)
          }
        >
          <div className="mt-5 flex flex-row justify-center gap-3 mb-4">
            <div className="relative flex-1">
              <input
                required
                ref={codePr}
                type="text"
                className="border border-dlabelColor outline-dbase w-full rounded-md py-3 pr-8 pl-2"
                placeholder="Product Id or SKU"
              />
              <FaTrash
                onClick={handleTrashClickID}
                className="absolute top-1/2 transform -translate-y-1/2 right-3 cursor-pointer opacity-20"
              />
            </div>

            <button className="bg-dbase rounded-md text-white px-5">
              {loading ? (
                <Loader />
              ) : location.pathname.includes("Restock") ? (
                <FaSearch />
              ) : (
                <FaSync />
              )}
            </button>
          </div>
        </form>
      </div>

      {/* Form 2 */}
      {location.pathname.includes("Restock") && showBinInput && (
        <div className="container">
          <form onSubmit={(e) => updateProductStatus(e)}>
            <div className="mt-5 flex flex-row justify-center gap-3 mb-4">
              <div className="relative flex-1">
                <input
                  ref={binid}
                  type="text"
                  className="border border-dlabelColor outline-dbase w-full rounded-md py-3 pr-10 pl-2"
                  placeholder="Bin ID"
                />
                <FaTrash
                  onClick={handleTrashClickBin}
                  className="absolute top-1/2 transform -translate-y-1/2 right-3 cursor-pointer opacity-20"
                />
              </div>
              <button className="bg-dbase rounded-md text-white px-5">
                {loading ? <Loader /> : <FaSync />}
              </button>
            </div>
          </form>
        </div>
      )}

      <div className="container w-full rounded-md text-white flex justify-between mt-4">
        <div className="border w-full flex-1  px-8  text-dgreyBlack border-dblack  py-2 flex items-center justify-center">
          {successfulUpdates}
        </div>
        <div
          onClick={() => handleReset([])}
          className="border cursor-pointer w-full bg-dbase   flex-2  border-dblack  py-2 flex items-center justify-center"
        >
          Reset
        </div>
      </div>

      <div className="flex justify-center items-center h-full bg-white">
        <div className="w-full bg-white p-3 rounded">
          <table className="w-full border-dlabelColor border">
            <thead>
              <tr className="border-b border-dlabelColor">
                <th className="py-2 border-dlabelColor border-r w-1/3">
                  Product ID
                </th>
                <th className="py-2 border-r w-1/3">Status</th>
                {location.pathname.includes("Restock") && (
                  <th className="py-2 border-r w-1/3">Address</th>
                )}
                <th className="py-2 border-r w-1/4">Actions</th>
              </tr>
            </thead>
            <tbody>
              {productInf.map((prod) => (
                <tr
                  key={prod.product_id}
                  className="border-b border-dlabelColor"
                >
                  <td className="py-2 border-dlabelColor border-r text-center">
                    {prod.product_id}
                  </td>
                  <td className="py-2 border-dlabelColor border-r text-center">
                    {prod.status}
                  </td>
                  {location.pathname.includes("Restock") && (
                    <td className="py-2 border-dlabelColor border-r text-center">
                      {prod.bin_id}
                    </td>
                  )}
                  <td className="py-2 border-dlabelColor border-r text-center">
                    <button
                      onClick={() => handleDeleteProduct(prod.product_id)}
                      className="text-red-500 hover:text-red-700 transition duration-200"
                    >
                      <FaTrash />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* <MessageAlert
        title="Success!"
        show={showMessgSucc}
        setShow={setShowMessageSucc}
        message={message}
        setMessage={setMessage}
      /> */}
      <MessageAlert
        message={message}
        type={showMessgErr ? "err" : "succ"}
        showMessg={showMessgErr || (showMessgSucc && true)}
      />
      {showPopup && (
        <>
          <div
            className="h-full w-full fixed z-10 left-0 top-0 bg-dblackOverlay"
            onClick={() => setShowPopup(false)}
          ></div>

          <div className="fixed left-0 top-0 bg-black bg-opacity-50 flex items-center justify-center z-50 transition-opacity duration-300 h-1/2 mt-52">
            <div className="relative bg-white rounded-lg shadow-lg max-w-md w-full p-8 transform transition-transform duration-500 scale-100">
              <button
                onClick={() => setShowPopup(false)}
                className="absolute top-3 right-3 text-gray-400 hover:text-gray-600 transition-colors"
              >
                ✕
              </button>
              <h3 className="text-2xl font-bold text-gray-700 mb-4 text-center">
                Select an Order ID
              </h3>
              <ul className="my-6 space-y-3">
                {orderIds.map((order) => (
                  <li key={order.order_id}>
                    <button
                      onClick={() => handleOrderSelection(order.order_id)}
                      className="block w-full py-3 px-6 rounded-lg border border-blue-500 text-blue-500 font-medium bg-blue-50 hover:bg-blue-100 hover:text-blue-600 transition-all duration-200"
                    >
                      Order ID: {order.order_id}
                    </button>
                  </li>
                ))}
              </ul>
              <div className="flex justify-center mt-6">
                <button
                  onClick={() => setShowPopup(false)}
                  className="px-6 py-3 bg-red-500 text-dblackk font-semibold rounded-lg hover:bg-red-600 transition duration-200 shadow-md"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ChangeSingleProductStatusv2;
