import React, { useContext, useEffect, useRef, useState } from "react";
import { FaArrowLeft, FaCheckCircle, FaSearch, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import buildLink from "../../../urls";
import { CompanyContext } from "../../../contexts/companyCotext";
import { IoIosWarning } from "react-icons/io";
import Loader from "../../../components/loader";
import MessageAlert from "../../../components/messageAlert";
import { PlaySounds } from "../../../functions/playSounds";
import hocStarredPage from "../../../components/hocStarredPage";
import Cookies from "js-cookie";

const ChangeUpcv2 = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [loading, setLoading] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [productInf, setProductInf] = useState(null);
  const [stateCom, dispatchCom] = useContext(CompanyContext);
  const oldUPC = useRef(null);
  const newUPC = useRef(null);
  const [showMessgErr, setShowMessageErr] = useState(false);
  const [message, setMessage] = useState("");
  const [showMessgSucc, setShowMessageSucc] = useState(false);

  const productID = useRef(null);
  const { playErrorSound, playSuccessSound } = PlaySounds();

  useEffect(() => {
    if (activeTab == 2) {
      oldUPC.current.focus();
    } else {
      productID.current.focus();
    }
  }, [activeTab]);

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


  // searchProduct
  const searchProduct = async (e) => {
    e.preventDefault();
    setLoadingSearch(true);
    const item = productID.current.value;
    try {
      const url = buildLink("productInfo") + `&item=${item}&v2=true`+ `&user_id=${stateCom.userID}`;
      await axios.get(url).then((response) => {
        if (response.data.success) {
          oldUPC.current.value = response.data.data.upc;
          setProductInf(response.data.data);
          newUPC.current.focus();
        }
      });
      setLoadingSearch(false);
    } catch (e) {
      playErrorSound();
      setLoadingSearch(false);
      setMessage(e.response.data.message);
      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
    }
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  
  async function changeShelfUpc(e) {
    e.preventDefault();
    setLoading(true);
    const new_upc = newUPC.current.value;
    const old_upc = oldUPC.current.value;

    const obj = JSON.stringify({
      old_upc,
      new_upc,
    });

    try {
      const url = buildLink("updateUPC")+ `&v2=true` ;
      await axios
        .put(url, JSON.parse(obj))
        .then((response) => {
          if (response.data.success) {
            playSuccessSound();
            setMessage(response.data.message);
            setShowMessageSucc(true);
            setTimeout(() => {
              setShowMessageSucc(false);
            }, 3000);
            oldUPC.current.value = '';
            newUPC.current.value = '';
          }
        });
    } catch (e) {
      playErrorSound();
      setMessage(e.response.data.message);
      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
    }
    setLoading(false);
  }
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  
  const getUserIdFromLocalStorage = () => {
    return localStorage.getItem("userID");
  };

  async function changeUpc(e) {
  e.preventDefault();
  const userID = getUserIdFromLocalStorage();
  setLoading(true);
  const product_id = productID.current.value;
  const upc = newUPC.current.value;
  const user_id = userID;

  const obj = JSON.stringify({
    product_id,
    upc,
    user_id,
  });

  try {
    const url = buildLink("updateUPC") + `&v2=true`;
    await axios.put(url, JSON.parse(obj)).then((response) => {
      if (response.data && response.data.success) {
        playSuccessSound();
        setMessage(response.data.message);
        setShowMessageSucc(true);
        setTimeout(() => {
          setShowMessageSucc(false);
        }, 3000);
        oldUPC.current.value = "";
        newUPC.current.value = "";
        productID.current.value = "";
      }
    });
  } catch (e) {
    playErrorSound();
    setMessage(e.response ? e.response.data.message : "Unknown error occurred");
    setShowMessageErr(true);
    setTimeout(() => {
      setShowMessageErr(false);
    }, 3000);
  }
  setLoading(false);
}


  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const history = useNavigate();

  const handleTrashClick = () => {
    productID.current.value = "";
    productID.current.focus();
  };

  return (
    <>
      <div className=" h-full bg-white">
        {/* header */}
        <div className=" mb-5 sticky top-0 flex flex-col bg-white">
          <div className=" w-full bg-dbase h-16">
            <div className=" container flex relative gap-7 flex-row justify-start w-full text-white  h-full my-auto">
              <button onClick={() => history(-1)}>
                <FaArrowLeft />
              </button>
              <h2 className=" text-xl my-auto">Change Upc</h2>
            </div>
          </div>
        </div>

        <div className=" w-screen  bg-white my-4 container ">
          <div className=" flex w-full justify-center gap-2">
            <button
              onClick={() => setActiveTab(1)}
              className={`${
                activeTab === 1 ? "bg-dbase text-white" : "bg-white text-dblack"
              }  transition-all w-full py-2  rounded-full`}
            >
              {" "}
              Single Product
            </button>
            <button
              onClick={() => setActiveTab(2)}
              className={`${
                activeTab === 2 ? "bg-dbase text-white" : "bg-white text-dblack"
              }  transition-all w-full py-2 rounded-full`}
            >
              {" "}
              Shelf Products
            </button>
          </div>
        </div>
        {activeTab === 1 && (
          <>
            <div className="container">
              <form onSubmit={(e) => searchProduct(e)}>
                <div className=" flex flex-row justify-center gap-3 ">
                  <div className=" relative w-full overflow-hidden">
                    <input
                      ref={productID}
                      className=" w-full border-dlabelColor  border pl-3 pr-8 py-3 rounded-md"
                      placeholder="Product ID or Sku"
                      required
                    />
                    <div className=" absolute right-0 top-1 bottom-0  ">
                      {" "}
                      <FaTrash
                        onClick={() => handleTrashClick()}
                        className="absolute top-1/2 transform -translate-y-1/2 right-3 cursor-pointer opacity-20 "
                      />
                    </div>
                  </div>
                  <button className=" bg-dbase rounded-md text-white px-5 ">
                    {" "}
                    {loadingSearch ? <Loader /> : <FaSearch />}{" "}
                  </button>
                </div>
              </form>
            </div>
            <div className="gap-2 flex py-3 ml-3 mr-3"></div>
            <form
              onSubmit={(e) => changeUpc(e)}
              className="container relative flex-1"
            >
              <span>
                Old Upc
                <input
                  type="text"
                  className="border border-dlabelColor outline-dbase w-full rounded-md  py-2 pr-8 pl-2 mb-4"
                  placeholder="Old UPC"
                  ref={oldUPC}
                  readOnly
                />
              </span>
              New Upc
              <span>
                <input
                  ref={newUPC}
                  type="text"
                  className="border border-dlabelColor outline-dbase w-full rounded-md  py-2 pr-8  pl-2 "
                  placeholder="New UPC"
                  required
                />
              </span>
              <div className="w-full ">
                <button
                  type="submit"
                  className="w-full bg-dbase text-white p-2 rounded-md mt-4 shadow"
                >
                  {loading ? "Loading..." : "Change UPC"}
                </button>
              </div>
            </form>

            <div className="container rounded-md shadow w-full m-3">
              {productInf && (
                <div className="flex gap-10 p-4 mt-8">
                  <img
                    className="object-cover w-40 h-40"
                    src={productInf.image}
                    alt="Product Image"
                  />
                  <div className="flex flex-col mt-8">
                    <div className="my-2">
                      <span className="font-bold">SKU:</span> {productInf.sku}
                    </div>
                    <div className="my-2">
                      <span className="font-bold">Quantity:</span>{" "}
                      {`${productInf ? productInf.upc_quantity : ""}`}
                    </div>
                    <div>
                      <span className=" font-bold">
                      Address: {productInf.address}
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
        {activeTab === 2 && (
          <>
            <form
              onSubmit={(e) => changeShelfUpc(e)}
              className="container relative flex-1"
            >
              <span>
                Old Upc
                <input
                  required
                  type="text"
                  className="border border-dlabelColor outline-dbase w-full rounded-md  py-2 pr-8 pl-2 mb-4"
                  placeholder="Old UPC"
                  ref={oldUPC}
                />
              </span>
              New Upc
              <span>
                <input
                  required
                  ref={newUPC}
                  type="text"
                  className="border border-dlabelColor outline-dbase w-full rounded-md  py-2 pr-8  pl-2 "
                  placeholder="New UPC"
                />
              </span>
              <div className="w-full container">
                <button
                  type="submit"
                  className="w-full bg-dbase text-white p-2 rounded-md mt-4 shadow"
                >
                  {loading ? "Loading..." : "Change UPC"}
                </button>
              </div>
            </form>
          </>
        )}

        <MessageAlert
          message={message}
          type={showMessgErr ? "err" : "succ"}
          showMessg={showMessgErr || (showMessgSucc && true)}
        />
      </div>
    </>
  );
};
export default hocStarredPage(ChangeUpcv2);
