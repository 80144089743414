import React, { useContext, useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import { CompanyContext } from "../../contexts/companyCotext";
import axios from "axios";
import buildLink from "../../urls";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const GraphPage = () => {
  const history = useNavigate()
  const [stateCom, dispatchCom] = useContext(CompanyContext);
  const [userData, setUserData] = useState(null);
  const [todayPickups, setTodayPickups] = useState(null);
  const [todayAssigns, setTodayAssigns] = useState(null);
  const user_id = localStorage.getItem("userID");
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  //the number of days in the current month
  const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
  //generate an array of day numbers for the current month
  const daysArray = Array.from({ length: daysInMonth }, (_, i) => i + 1);
  
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const url = buildLink("graphPage") + `&user_id=${user_id}`;
        const response = await axios.get(url);
        // console.log("Fetched data:", response.data);
        if (response.data.success) {
          const data = response.data;
          setUserData(data);
          setTodayPickups(data.todays_stats.picked_products);
          setTodayAssigns(data.todays_stats.assigned_products);
          console.log(data.picked_products)
        } else {
          console.error("Data fetch unsuccessful:", response.data);
        }
      } catch (e) {
        console.log("Error fetching data:", e);
      }
    };

    fetchUserData();
  }, []);


  const data = {
    labels: daysArray,
    datasets: [
      {
        label: "Assigned",
        data: userData ? userData.assigned_products.map(item => parseInt(item.count)) : new Array(daysInMonth).fill(0),
        backgroundColor: "rgba(255, 156, 154, 1)",
      },
      {
        label: "Picked Up",
        data: userData ? userData.picked_products.map(item => parseInt(item.count)) : new Array(daysInMonth).fill(0),
        backgroundColor: "rgba(0, 69, 255, 0.7)",
      },
    ],
  };

  const options = {
    scales: {
      x: {
        ticks: {
          autoSkip: false,
          maxTicksLimit: daysInMonth,
        },
      },
      y: {
        beginAtZero: true,
        min: 0,
        ticks: {
          stepSize: 1,
          callback: function (value) {
            if (Number.isInteger(value)) {
              return value;
            }
            return null;
          },
        },
      },
    },
  };
  

  return (
   <>  <div className=" mb-5 sticky top-0  z-30 flex flex-col bg-white shadow-md">
   <div className=" w-16 max-md:w-14 max-md:h-14 bg-dgreen h-16 cursor-pointer ">
     <div onClick={() => history(-1)} className=" container flex relative gap-7 flex-row justify-start w-full text-white ml-2 max-md:ml-1.5  h-full my-auto  ">
       <button >
         <FaArrowLeft />
       </button>
     </div>
   </div>
 </div>

    <div className="p-6 bg-gray-100 min-h-screen max-md:p-0">
      <h1 className="text-2xl font-bold mb-4 max-md:p-2">Hey, {stateCom.username}!</h1>
      <div className="bg-white p-4 shadow rounded mb-6 chart-container">
        <h2 className="text-xl font-semibold mb-2">Monthly Assigns and Pickups</h2>
        <div className="chart-wrapper">
          <Bar data={data} options={options} />
        </div>
      </div>
      <div className="bg-white p-4 shadow rounded">
        <h2 className="text-xl font-semibold mb-2">Today's Summary</h2>
        <table className="min-w-full bg-white">
          <thead>
            <tr>
              <th className="py-2 px-4 text-start border-b">Type</th>
              <th className="py-2 px-4 text-start border-b">Count</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="py-2 px-4 border-b">Assigns</td>
              <td className="py-2 px-4 border-b">{todayAssigns}</td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b">Pickups</td>
              <td className="py-2 px-4 border-b">{todayPickups}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    </>
  );
};

export default GraphPage;
