import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { FaArrowLeft, FaSearch, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import buildLink from "../../../urls";
import { CompanyContext } from "../../../contexts/companyCotext";
import Loader from "../../../components/loader";
import MessageAlert from "../../../components/messageAlert";
import { PlaySounds } from "../../../functions/playSounds";

const CheckProduct = () => {
  const history = useNavigate();
  const [showMessageSucc, setShowMessageSucc] = useState(false);
  const [message, setMessage] = useState("");
  const [showMessageErr, setShowMessageErr] = useState(false);
  const [loading, setLoading] = useState(false);
  const serialNumber = useRef("");
  const [keyboardType, setKeyboardType] = useState("text");
  const [stateCom] = useContext(CompanyContext);
  const { playErrorSound, playSuccessSound } = PlaySounds();

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const checkProduct = async (e) => {
    e.preventDefault();
    setLoading(true);
    const serial_number = serialNumber.current.value;
    try {
      const url = `${buildLink("checkProduct")}`;
      const response = await axios.post(url, {
        serial_number,
        user_id: stateCom.userID,
      });
  
      console.log("Response:", response.data.message);
      setMessage(response.data.message);
      if (response.data.success === true) {
        playSuccessSound();
        
        setShowMessageSucc(true);
        setTimeout(() => {
          setShowMessageSucc(false);
          setMessage("");
        }, 3000);
      }
    } catch (error) {
      console.error("Error:", error);
      playErrorSound();
      if (error.response && error.response.data && error.response.data.message) {
        setMessage(error.response.data.message);
      }
      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
        setMessage("");
      }, 3000);
    } finally {
      setLoading(false);
      serialNumber.current.value = "";
    }
  };

  useEffect(() => {
    serialNumber.current.focus();
  }, []);

  const handleTrashClick = () => {
    serialNumber.current.value = "";
    serialNumber.current.focus();
  };

  return (
    <>
      <div className="h-full bg-white">
        {/* header */}
        <div className="mb-5 sticky top-0 z-30 flex flex-col bg-white">
          <div className="w-full bg-dbase h-16">
            <div className="container flex relative gap-7 flex-row justify-start w-full text-white h-full my-auto">
              <button onClick={() => history(-1)}>
                <FaArrowLeft />
              </button>
              <h2 className="text-xl my-auto">Check Product</h2>
            </div>
          </div>
        </div>

        <div className="container">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              checkProduct(e);
            }}
          >
            <div className="flex flex-row justify-center gap-3">
              <div className="relative flex-1">
                <input
                  onFocus={() => {
                    setKeyboardType("text");
                  }}
                  inputMode=""
                  ref={serialNumber}
                  type={keyboardType}
                  className="border border-dlabelColor outline-dbase w-full rounded-md py-3 pr-8 pl-2"
                  placeholder="Enter Serial Number"
                />
                <div>
                  <FaTrash
                    onClick={() => handleTrashClick()}
                    className="absolute top-1/2 transform -translate-y-1/2 right-3 cursor-pointer opacity-20"
                  />
                </div>
              </div>
              <button className="bg-dbase rounded-md text-white px-5">
                {loading ? <Loader /> : <FaSearch />}
              </button>
            </div>
          </form>
        </div>

        <MessageAlert
          message={message}
          type={showMessageErr ? "err" : "succ"}
          showMessg={showMessageErr || showMessageSucc}
        />
      </div>
    </>
  );
};

export default CheckProduct;
