import React, { useContext, useEffect, useRef, useState } from "react";
import { FaArrowLeft, FaSearch, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Loader from "../../../components/loader";
import buildLink from "../../../urls";
import MessageAlert from "../../../components/messageAlert";
import hocStarredPage from "../../../components/hocStarredPage";
import { CompanyContext } from "../../../contexts/companyCotext";
import { PlaySounds } from "../../../functions/playSounds";
import Cookies from "js-cookie";

const Restock = () => {
  const {playErrorSound,playSuccessSound} = PlaySounds();
  const history = useNavigate();
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [returnInf, setReturnInf] = useState(null);
  const codePr = useRef(null);
  const [showMessgErr, setShowMessageErr] = useState(false);
  const [message, setMessage] = useState("");
  const [showMessgSucc, setShowMessageSucc] = useState(false);
  const commentRef = useRef(null);
  const [stateCom, dispatchCom] = useContext(CompanyContext);

  const searchReturn = async (e) => {
    setLoadingSearch(true);
    e.preventDefault();

    const item = codePr.current.value;

    try {
      const url =
        buildLink("returnOrderProduct") + `&return_order_product_id=${item}`;
      await axios.get(url).then((response) => {
        if (response.data.success) {
              
            setReturnInf(response.data.data);
            setMessage(response.data.message);
            setShowMessageSucc(true);
            setTimeout(() => {
              setShowMessageSucc(false);
            }, 3000);
          
        }
      });
      setLoadingSearch(false);
    } catch (e) {
      console.log(e);
      setLoadingSearch(false);
      setMessage(e.response.data.message);
      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
    }
    setLoadingSearch(false);
  };


  const getUserIdFromLocalStorage = () => {
    return localStorage.getItem("userID");
  };

  async function reset() {
    const url = buildLink("updateReturnOrderProduct");
    const userID = getUserIdFromLocalStorage()
    const return_order_product_id = codePr.current.value;
    const return_status_id = 30;
    const user_id = userID;
    const comment = commentRef.current.value;
    const type = "";

    const obj = {
      return_order_product_id,
      return_status_id,
      comment,
      type,
      user_id,
    };

    try {
      await axios.put(url, obj).then((res) => {
        if (res.data.success) {
          playSuccessSound();
          setMessage(res.data.message);
          setShowMessageSucc(true);
          setTimeout(() => {
            setShowMessageSucc(false);
          }, 3000);
        }
      });
    } catch (e) {
      playErrorSound();
      setMessage(e.res.data.message);
      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
    }
  }

  async function handleSave() {
    const url = buildLink("updateReturnOrderProduct");
    const userID = getUserIdFromLocalStorage()
    const return_order_product_id = codePr.current.value;
    const return_status_id = 26;
    const user_id = userID;
    const comment = commentRef.current.value;
    const type = "";

    const obj = {
      return_order_product_id,
      return_status_id,
      comment,
      type,
      user_id,
    };

    try {
      await axios.put(url, obj).then((res) => {
        if (res.data.success) {
          playSuccessSound();
          setMessage(res.data.message);
          setShowMessageSucc(true);
          setTimeout(() => {
            setShowMessageSucc(false);
          }, 3000);
        }
      });
    } catch (e) {
      setMessage(e.res.data.message);
      playErrorSound()
      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
    }
  }

  useEffect(() => {
    codePr.current.focus();
  }, []);

  const handleTrashClick = () => {
    codePr.current.value = "";
    codePr.current.focus();
  };

  return (
    <>
      <div className=" h-full  bg-white ">
        {/* header */}
        <div className=" mb-5 sticky top-0 flex flex-col bg-white">
          <div className=" w-full bg-dbase h-16">
            <div className=" container flex relative gap-7 flex-row justify-start w-full text-white  h-full my-auto">
              <button onClick={() => history(-1)}>
                <FaArrowLeft />
              </button>
              <h2 className=" text-xl    my-auto">Restock</h2>
            </div>
          </div>
        </div>

        <div className="container flex flex-col gap-5">
          <form onSubmit={(e) => searchReturn(e)}>
            <div className=" flex flex-row justify-center gap-3 ">
              <div className="relative flex-1">
                <input
                  ref={codePr}
                  type="text"
                  className="border border-dlabelColor outline-dbase w-full rounded-md  py-3 pr-8  pl-2"
                  placeholder="Rerturn Order ID"
                />
                <div>
                  <FaTrash
                    onClick={() => handleTrashClick()}
                    className="absolute top-1/2 transform -translate-y-1/2 right-3 cursor-pointer opacity-20 "
                  />
                </div>
              </div>
              <button className=" bg-dbase rounded-md text-white px-5 ">
                {" "}
                {loadingSearch ? <Loader /> : <FaSearch />}{" "}
              </button>
            </div>
          </form>

          {/* <div className="relative flex-1">
                <input
                  ref={prodcutPlace}
                  type="text"
                  className="border border-dlabelColor outline-dbase w-full rounded-md  py-3 pr-8  pl-2"
                  placeholder="Product Place"
                />
                <div onClick={() => (prodcutPlace.current.value = "")}>
                  <FaTrash className="absolute top-1/2 transform -translate-y-1/2 right-3 cursor-pointer opacity-20 " />
                </div>
              </div> */}

          <div className="relative flex-1">
            <input
              ref={commentRef}
              type="text"
              className="border border-dlabelColor outline-dbase w-full rounded-md  py-3 pr-8  pl-2"
              placeholder="Comment"
            />
            <div onClick={() => (commentRef.current.value = "")}>
              <FaTrash className="absolute top-1/2 transform -translate-y-1/2 right-3 cursor-pointer opacity-20 " />
            </div>
          </div>

          <div className=" w-full  flex flex-row gap-3">
            {" "}
            <button
              onClick={() => {
                handleSave();
              }}
              className=" w-full py-2 bg-dbase text-white "
            >
              Save
            </button>{" "}
            <button
              onClick={() => reset()}
              className=" w-44 py-2 border border-dbase text-dbase bg-white "
            >
              Reset
            </button>
          </div>
        </div>

        <MessageAlert
          message={message}
          type={showMessgErr ? "err" : "succ"}
          showMessg={showMessgErr || (showMessgSucc && true)}
        />
      </div>
    </>
  );
};

export default hocStarredPage(Restock);
