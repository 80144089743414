export const TabBarReducer = (state, action) => {
  switch (action.type) {
    case "setActiveTab":
      return {
        ...state,
        activeTab: action.payload,
      };

    default:
      return state;
  }
};

export const initialState = {
  activeTab: "home",
};
