import React, { useState, useEffect } from "react";
import useDeviceSize from "../components/useDeviceSize";
import { MdKeyboardDoubleArrowUp } from "react-icons/md";

function Test() {
  const [width] = useDeviceSize();
  const [showComponent, setShowComponent] = useState(false);
  const [showScrollTop, setShowScrollTop] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setShowComponent(window.scrollY > 2000);
      setShowScrollTop(window.scrollY > 500);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  const selectElement = (
    <select
      className="block w-full pl-3 pr-10 py-2 text-base border-dplaceHolder border focus:outline-none focus:ring-dlabelColor focus:border-dlabelColor rounded-md"
      id="qty"
      name="qty"
    >
      {[...Array(12)].map((_, index) => (
        <option key={index + 1} value={index + 1}>
          {`Quantity: ${index + 1}`}
        </option>
      ))}
    </select>
  );

  const buttonElement = (
    <button
      type="button"
      className="w-full justify-center block items-center py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white hover:bg-dbluedark bg-dblue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dplaceHolder"
      id="add-to-cart-btn"
    >
      ADD TO BASKET
    </button>
  );

  return (
    <div className="w-full">
      {width > 650 && showComponent && (
        <div className="fixed bottom-0 right-0 m-10 mb-28 w-96 bg-white shadow-xl p-2 rounded-md">
          <div className="flex items-center justify-between">
            <div className="w-1/2 px-1">{selectElement}</div>
            <div className="w-1/2 px-1">{buttonElement}</div>
          </div>
        </div>
      )}
      {width < 650 && (
        <div className="fixed bottom-0 w-full bg-white shadow-xl p-2 rounded-md">
          <div className="flex items-center justify-between">
            <div className="w-1/2 px-1">{selectElement}</div>
            <div className="w-1/2 px-1">{buttonElement}</div>
          </div>
        </div>
      )}
      {showScrollTop && (
        <div
          className={`fixed bottom-0 right-0 ${
            width > 650 ? "m-10 mb-14" : "m-2 mb-40"
          } w-auto p-2 rounded-md`}
        >
          <button
            type="button"
            className="rounded-full p-2 text-white shadow-md bg-dbluedark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dplaceHolder"
            onClick={scrollToTop}
          >
            <MdKeyboardDoubleArrowUp className="text-2xl" />
          </button>
        </div>
      )}
    </div>
  );
}

export default Test;
