import React, { useEffect, useRef, useState } from "react";
import {
  FaArrowLeft,
  FaCheck,
  FaCheckCircle,
  FaSearch,
  FaTrash,
  FaUpload,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import buildLink from "../../../../../urls";
import axios from "axios";
import MessageAlert from "../../../../../components/messageAlert";
import Loader from "../../../../../components/loader";
import { axiosServer } from "../../../../../axiosServer";
import hocStarredPage from "../../../../../components/hocStarredPage";
import Select from "react-select";
const UpdateBinv2 = () => {
  const orderId = useRef(null);
  const binRef = useRef(null);

  const itemref = useRef(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [showMessgErr, setShowMessageErr] = useState(false);
  const [showMessgSucc, setShowMessageSucc] = useState(false);
  const history = useNavigate();
  const [orderStatus, setOrderStatus] = useState("normal");
  const options = [
    { value: "normal", label: "Normal" },
    { value: "return", label: "Return" },
  ];

  // useEffect(()=>{
  //   console.log("Bacharrrr Karhaniiii");
  // })

  const handleTrashClick = () => {
    orderId.current.value = "";
    orderId.current.focus();
  };
  const handleitemTrashClick = () => {
    itemref.current.value = "";
    itemref.current.focus();
  };
  const updateBin = async (e) => {
    e.preventDefault();
    setLoading(true);

    var url =
      orderStatus == "normal"
        ? buildLink("updateBinORder")
        : buildLink("updateReturnBin");
    var obj = {};
    if (orderStatus == "normal") {
      obj.order_id = orderId.current.value;
      obj.bin_id = binRef.current.value;
      obj.item = itemref.current.value;
    } else {
      obj.return_order_id = orderId.current.value;
      obj.bin_id = binRef.current.value;
      obj.item = itemref.current.value;
    }

    try {
      await axiosServer.post(url, obj).then((response) => {
        if (response.data.success) {
          setMessage(response.data.message);
          setShowMessageSucc(true);
          setTimeout(() => {
            setShowMessageSucc(false);
          }, 3000);
        } else {
          setMessage(response.data.message);
          setShowMessageErr(true);
          setTimeout(() => {
            setShowMessageErr(false);
          }, 3000);
        }
      });
    } catch (e) {
      setMessage(e.response.data.message);
      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
    }
    setLoading(false);
  };

  useEffect(() => {
    orderId.current.focus();
  }, []);

  return (
    <>
      <div className="h-full bg-white">
        {/* header */}
        <div className=" mb-5 sticky top-0 flex flex-col bg-white">
          <div className=" w-full bg-dbase h-16">
            <div className=" container flex relative gap-7 flex-row justify-start w-full text-white  h-full my-auto">
              <button onClick={() => history(-1)}>
                <FaArrowLeft />
              </button>
              <h2 className=" text-xl my-auto">Update Bin</h2>
            </div>
          </div>
        </div>
        <div className=" container">
          <form
            onSubmit={(e) => updateBin(e)}
            className=" flex flex-col gap-5 justify-center"
          >
            <Select
              placeholder="Order Type"
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderRadius: "7px",
                  borderColor: state.isFocused ? "grey" : "grey",
                  outline: "red",
                  padding: "8px 5px",
                }),
              }}
              options={options}
              onChange={(e) => {
                setOrderStatus(e.value);
              }}
            />
            <div className="  flex  justify-center gap-3">
              <div className=" my-auto relative flex-1">
                <input
                  required
                  ref={orderId}
                  type="text"
                  className="border border-dlabelColor outline-dbase w-full rounded-md  py-3 pr-8  pl-2"
                  placeholder="Order Id"
                />
                <FaTrash
                  className="absolute top-1/2 transform -translate-y-1/2 right-2 cursor-pointer opacity-20 "
                  onClick={handleTrashClick}
                />
              </div>
            </div>
            <div className="  flex  justify-center gap-3">
              <div className=" my-auto relative flex-1">
                <input
                  required
                  ref={itemref}
                  type="text"
                  className="border border-dlabelColor outline-dbase w-full rounded-md  py-3 pr-8  pl-2"
                  placeholder="Item"
                />
                <FaTrash
                  className="absolute top-1/2 transform -translate-y-1/2 right-2 cursor-pointer opacity-20 "
                  onClick={handleitemTrashClick}
                />
              </div>
            </div>

            <div className="  flex  justify-center gap-3">
              <div className=" my-auto relative flex-1">
                <input
                  required
                  ref={binRef}
                  type="text"
                  className="border border-dlabelColor outline-dbase w-full rounded-md  py-3 pr-8  pl-2"
                  placeholder="BIN"
                />
                <FaTrash
                  className="absolute top-1/2 transform -translate-y-1/2 right-2 cursor-pointer opacity-20 "
                  onClick={() => {
                    binRef.current.value = "";
                    binRef.current.focus();
                  }}
                />
              </div>
              <button
                type="submit"
                className=" bg-dbase rounded-md text-white px-5 "
              >
                {" "}
                {loading ? <Loader /> : <FaCheckCircle />}
              </button>
            </div>
          </form>
          {/* <form>
          <div className=" my-5  flex  justify-center gap-3">
            <div className= " my-auto relative flex-1">
              <input
                ref={upcRef}
                type="text"
                className="border border-dlabelColor outline-dbase w-full rounded-md  py-3 pr-8  pl-2"
                placeholder="Order Id"
              />
              <FaTrash
                className="absolute top-1/2 transform -translate-y-1/2 right-2 cursor-pointer opacity-20 "
                onClick={()=>{
                  upcRef.current.value = '';
                  upcRef.current.focus();
                }}
              />
            </div>
            <button type="submit" className=" bg-dbase rounded-md text-white px-5 ">
                    {" "}
                  <FaUpload />
                  </button>
            </div>
            </form> */}
        </div>
        <MessageAlert
          message={message}
          type={showMessgErr ? "err" : "succ"}
          showMessg={showMessgErr || (showMessgSucc && true)}
        />
      </div>
    </>
  );
};
export default hocStarredPage(UpdateBinv2);
