import React, { useContext, useState, useEffect } from "react";
import { CompanyContext } from "../contexts/companyCotext";
import { HiUserCircle } from "react-icons/hi";
import { useLocation, useNavigate } from "react-router-dom";
import { CiLogout } from "react-icons/ci";
import { RxUpdate } from "react-icons/rx";
import { IoIosSwitch } from "react-icons/io";
import { HiArrowPath } from "react-icons/hi2";
import * as serviceWorkerRegistration from "../serviceWorkerRegistration";
import Select from "react-select";
import useDeviceSize from "./useDeviceSize";
import { IoLanguage } from "react-icons/io5";

const languageOptions = [
  { value: "en", label: "English" },
  { value: "ar", label: "العربية" },
];

const BurgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [stateCom, dispatchCom] = useContext(CompanyContext);
  const [version, setVersion] = useState("");
  const history = useNavigate();
  const [width] = useDeviceSize();
  const location = useLocation();
  const [selectedLanguage, setSelectedLanguage] = useState(languageOptions[0]);
  const [host, setHost] = useState(window.location.host);

  useEffect(() => {
    setHost(window.location.host);
}, [host]);


  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  function logout() {
    localStorage.clear();
    history("/", { replace: true });
  }

  const handleClearSiteData = () => {
    serviceWorkerRegistration.unregister();
    if ("caches" in window) {
      caches.keys().then((cacheNames) => {
        cacheNames.forEach((cacheName) => {
          caches.delete(cacheName);
          history("/");
          window.location.reload();
        });
      });
    }
  };

  // useEffect(() => {
  //   const fetchAppVersion = async () => {
  //     try {
  //       const response = await fetch("/manifest.json");
  //       const manifest = await response.json();

  //       // Extract the app name from the manifest
  //       const { version } = manifest;

  //       setVersion(version);
  //     } catch (error) {
  //       console.error("Error fetching or parsing manifest.json", error);
  //     }
  //   };

  //   fetchAppVersion();
  // }, []);

  const handleLanguageChange = (selectedOption) => {
    setSelectedLanguage(selectedOption);
  };

  const navigateToVersion = () => {
    if (location.pathname === "/home/v2home") {
      history("/");
    } else {
      history("/home/v2home");
    }
  };

  return (
    <div className="relative">
      <button className="focus:outline-none" onClick={toggleMenu}>
        <svg
          className="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 6h16M4 12h16M4 18h16"
          ></path>
        </svg>
      </button>
      <div
        className={`fixed inset-0 bg-dblackOverlay bg-opacity-50 z-40 transition-opacity ${
          isOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
        onClick={closeMenu}
      ></div>
      <div
        className={`fixed top-0 right-0 bottom-0  bg-white  w-3/4 sm:w-1/4 z-50 shadow-lg transform transition-transform duration-300 ease-in-out ${
          isOpen ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <div className="flex flex-col justify-between h-full ">
          <div className="">
            <div
              className={`flex items-center justify-between p-2.5 border-b bg-dbase ${
                location.pathname === "/home/v2home" ? "bg-dgreen " : ""
              }`}
            >
              <button
                onClick={() => {
                  if (location.pathname !== "/home" &&  host === "pda.ishtari.com"|| host === "pda.ishtari.com.gh" || host.startsWith("localhost")) {
                    history("/home/v2home/graphpage");
                  }
                }}
                className="flex items-center cursor-pointer"
                disabled={location.pathname === "/home"}
              >
                <HiUserCircle className="text-white h-10 w-10" />
                <div className="ml-3">
                  <p className="text-sm font-medium text-white">
                    {selectedLanguage.value === "en"
                      ? "Welcome"
                      : "أهلاً وسهلاً"}{" "}
                    <span className="text-md text-white">
                      {stateCom.username}
                    </span>
                  </p>
                  <p className="text-xs text-white border-b">
                    {selectedLanguage.value === "en"
                      ? "Tap to see your results"
                      : "اضغط لرؤية نتائجك"}
                  </p>
                </div>
              </button>

              <button
                onClick={closeMenu}
                className="text-white focus:outline-none"
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
            </div>
            {width > 625 ? (
              <div className="flex justify-end">
                <div className="flex items-center ml-2 py-1 text-sm text-dblack ">
                  <div
                    className={`w-8 h-8  flex items-center  justify-center bg-white border border-dbase bg-opacity-80 rounded-xl mr-1 ${
                      location.pathname === "/home/v2home"
                        ? "border-dgreen text-dgreen"
                        : ""
                    }`}
                  >
                    <IoLanguage
                      className={`text-dbase ${
                        location.pathname === "/home/v2home"
                          ? " text-dgreen"
                          : ""
                      }`}
                    />
                  </div>
                  <Select
                    value={selectedLanguage}
                    onChange={handleLanguageChange}
                    options={languageOptions}
                    className="w-40 justify-center text-center"
                  />
                </div>
              </div>
            ) : (
              <div className="flex justify-end mt-1">
                <div className="flex items-center text-dblack text-xs w-36  ">
                  <div
                    className={`w-8 h-8  flex items-center  justify-center bg-white border border-dbase  rounded-xl ml-2 ${
                      location.pathname === "/home/v2home"
                        ? "text-dgreen border-dgreen"
                        : ""
                    }`}
                  >
                    <IoLanguage
                      className={`text-dbase ${
                        location.pathname === "/home/v2home"
                          ? " text-dgreen"
                          : ""
                      }`}
                    />
                  </div>
                  <Select
                    value={selectedLanguage}
                    onChange={handleLanguageChange}
                    options={languageOptions}
                    className="justify-center text-center ml-1"
                  />
                </div>
              </div>
            )}
            <div className="border-t mt-10">

            { (host.startsWith("localhost") || host === "pda.ishtari.com" || host === "pda.ishtari.com.gh") &&
  <button
  onClick={navigateToVersion}
  className={`flex w-full items-center my-4 px-6 py-3 text-xs ${
    location.pathname === "/home/v2home"
      ? "text-dgreen bg-dgreyRate hover:bg-dinputBorder hover:text-dblack"
      : "text-dbase bg-dinputBorder hover:bg-dgreyRate hover:text-dblack"
  }`}
>
  <div
    className={`w-8 h-8 flex items-center justify-center rounded-full mr-2 ${
      location.pathname === "/home/v2home"
        ? "bg-dgreen"
        : "bg-dbase"
    }`}
  >
    <IoIosSwitch
      className={`text-white ${
        location.pathname === "/home/v2home" ? " " : ""
      }`}
    />
  </div>
  <span className="">
    {location.pathname === "/home/v2home"
      ? selectedLanguage.value === "en"
        ? "Switch to version 1"
        : "انتقل للنسخة رقم ١"
      : selectedLanguage.value === "en"
      ? "Switch to version 2"
      : "انتقل للنسخة رقم ٢"}
  </span>
</button>
}



              

              <button
                onClick={() => {
                  handleClearSiteData();
              }}              
                className={`flex w-full items-center my-4 px-6 py-3 text-sm text-dbase bg-dinputBorder hover:bg-dgreyRate hover:text-dblack  ${
                  location.pathname === "/home/v2home" ? " text-dgreen" : ""
                }`}
              >
                <div
                  className={`w-8 h-8 flex items-center justify-center bg-dbase rounded-full mr-2 ${
                    selectedLanguage.value === "ar" ? "mr-5" : ""
                  } ${
                    location.pathname === "/home/v2home"
                      ? "bg-dgreen text-dgreen"
                      : ""
                  }`}
                >
                  <RxUpdate className="text-white" />
                </div>
                <span className="">
                  {" "}
                  {selectedLanguage.value === "en" ? "Update" : "تحديث"}
                </span>
                <span
                  className={`relative ${
                    stateCom.availableUpdate ? "opacity-100" : "opacity-0"
                  } ${
                    location.pathname === "/home/v2home" ? " text-dgreen" : ""
                  }  right-24 bottom-4 text-dbase transition-all  flex h-3 w-3`}
                >
                  <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-dbase opacity-75"></span>
                  <span className="relative inline-flex rounded-full h-3 w-3  bg-dbase"></span>
                </span>
              </button>

              <button
                onClick={() => logout()}
                className={`flex w-full items-center my-4 px-6 py-3 text-sm text-dbase bg-dinputBorder hover:bg-dgreyRate hover:text-dblack ${
                  location.pathname === "/home/v2home" ? " text-dgreen" : ""
                }`}
              >
                <div
                  className={`w-8 h-8 flex items-center justify-center bg-dbase rounded-full mr-2 ${
                    location.pathname === "/home/v2home" ? "bg-dgreen" : ""
                  } `}
                >
                  <CiLogout className="text-white" />
                </div>
                <span className="">
                  {" "}
                  {selectedLanguage.value === "en" ? "Logout" : "تسجيل خروج "}
                </span>
              </button>
            </div>
          </div>

          <div
            className={` border-t px-4 py-2 text-right text-sm text-dbase ${
              location.pathname === "/home/v2home" ? " text-dgreen" : ""
            }`}
          >
            v{stateCom.version}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BurgerMenu;

