import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { FaArrowLeft, FaCheckCircle, FaSearch, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import buildLink from "../../../urls";
import { CompanyContext } from "../../../contexts/companyCotext";
import Loader from "../../../components/loader";
import { IoIosWarning } from "react-icons/io";
import MessageAlert from "../../../components/messageAlert";
import { PlaySounds } from "../../../functions/playSounds";
import hocStarredPage from "../../../components/hocStarredPage";

const GetOrderPage = () => {
  const {playErrorSound,playSuccessSound} = PlaySounds();
  const history = useNavigate();
  const [products, setProducts] = useState(null);
  const [showMessgSucc, setShowMessageSucc] = useState(false);
  const [message, setMessage] = useState("");
  const [showMessgErr, setShowMessageErr] = useState(false);
  const [loading, setLoading] = useState(false);
  const codePr = useRef("");
  const [stateCom, dispatchCom] = useContext(CompanyContext);
  const searchProduct = async (e) => {
    // console.log(stateCom.company);
    e.preventDefault();
    setLoading(true);
    const item = codePr.current.value;
    try {
      const url =
        buildLink("getDataEntryOrder") + `&data_entry_order_id=${item}`+ `&user_id=${stateCom.userID}`;
      await axios.get(url).then((response) => {
        
        if (response.data.success) {
          setProducts(response.data.data);
        }
      });
      setLoading(false);
    } catch (e) {
      playErrorSound()
      setLoading(false);
      setMessage(e.response.data.message);
      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
    }
  };

  useEffect(() => {
    codePr.current.focus();
  }, []);

  const handleTrashClick = () => {
    codePr.current.value = "";
    codePr.current.focus();
  };

  return (
    <>
      <div className=" h-full bg-white">
        {/* header */}
        <div className=" mb-5 sticky top-0 z-10 flex flex-col bg-white">
          <div className=" w-full  bg-dbase h-16">
            <div className=" container flex relative gap-7 flex-row justify-start w-full text-white  h-full my-auto">
              <button onClick={() => history(-1)}>
                <FaArrowLeft />
              </button>
              <h2 className=" text-xl    my-auto">Get Data Entry Product</h2>
            </div>
          </div>
        </div>
        <div className="container">
          <form onSubmit={(e) => searchProduct(e)}>
            <div className=" flex flex-row justify-center gap-3 ">
              <div className="relative flex-1">
                <input
                  ref={codePr}
                  type="text"
                  className="border border-dlabelColor outline-dbase w-full rounded-md  py-3 pr-8  pl-2"
                  placeholder="Enter Order ID"
                />
                <div>
                  <FaTrash
                    onClick={() => handleTrashClick()}
                    className="absolute top-1/2 transform -translate-y-1/2 right-3 cursor-pointer opacity-20 "
                  />
                </div>
              </div>
              <button className=" bg-dbase rounded-md text-white px-5 ">
                {" "}
                {loading ? <Loader /> : <FaSearch />}{" "}
              </button>
            </div>
          </form>
        </div>
        <div className="container mt-4 px-3 gap-1 grid grid-cols-2">
          {products &&
            products.map((product) => {
              return (
                <div className=" flex shadow-md p-3 flex-col text-start gap-1">
                  <img src={product.image} />
                  <p>{product.product_option_value_id}</p>
                  <p>
                    <strong>Sku: </strong>
                    {product.sku}
                  </p>
                  <p>
                    <strong>Upc: </strong>
                    {product.upc}
                  </p>
                  <p>
                    <strong>Option: </strong>
                    {product.product_option_value_name}
                  </p>
                  <p>{product.status_name}</p>
                </div>
              );
            })}
        </div>
        <MessageAlert
          message={message}
          type={showMessgErr ? "err" : "succ"}
          showMessg={showMessgErr || (showMessgSucc && true)}
        />
      </div>
    </>
  );
};
export default hocStarredPage(GetOrderPage);
