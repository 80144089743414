import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  FaArrowLeft,
  FaSync,
  FaTrash,
} from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import buildLink from "../../../urls";
import { CompanyContext } from "../../../contexts/companyCotext";
import Loader from "../../../components/loader";
import MessageAlert from "../../../components/messageAlert";
import { PlaySounds } from "../../../functions/playSounds";


const ChangeSingleProductStatus = () => {

  const {playErrorSound,playSuccessSound} = PlaySounds();
  const history = useNavigate();
  const [productInf, setProductInf] = useState(null);
  const [showMessgSucc, setShowMessageSucc] = useState(false);
  const [message, setMessage] = useState("");
  const [showMessgErr, setShowMessageErr] = useState(false);
  const [loading, setLoading] = useState(false);
  const codePr = useRef(null);
  const orderId = useRef(null);
  const { name, id } = useParams();
  const decodedName = decodeURIComponent(name);


  async function updateProductStatus(e) {
    e.preventDefault();
    setLoading(true);
    const obj = {
      product_id: codePr.current.value,
      status_id: id
    };
    try {
      await axios
        .post(buildLink("updateProductStatus"), obj)
        .then((response) => {
          if (response.data.success) {
            playSuccessSound()
            setMessage(response.data.message);
            setShowMessageSucc(true);
            setTimeout(() => {
              setShowMessageSucc(false);
            }, 3000);
            codePr.current.value = ''
          }
        });
    } catch (e) {
      playErrorSound();
      setMessage(e.response.data.message);
      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
    }
    setLoading(false);
  }

//   async function updateProductStatusSku() {
//     try {
//       await axios
//         .get(
//           buildLink("updateProductStatus") +
//             `&item=${codePr.current.value}&order_id=${orderId.current.value}`
//         )
//         .then((response) => {
//           if (response.data.success) {
//             playSuccessSound()
//             setProductInf(response.data.data);
//             orderId.current.value = ''
//             codePr.current.value = ''
//           }
//         });
//     } catch (e) {
//       playErrorSound();
//       setMessage(e.response.data.message);
//       setShowMessageErr(true);
//       setTimeout(() => {
//         setShowMessageErr(false);
//       }, 3000);
//     }
//   }

  useEffect(() => {
    codePr.current.focus();
  }, []);

  const handleTrashClick = () => {
    codePr.current.value = "";
    codePr.current.focus();
  };



  return (
    <>
      <div className=" h-full bg-white">
        {/* header */}
        <div className=" mb-5 sticky top-0 z-30 flex flex-col bg-white">
          <div className=" w-full  bg-dbase h-16">
            <div className=" container flex relative gap-7 flex-row justify-start w-full text-white  h-full my-auto">
              <button onClick={() => history(-1)}>
                <FaArrowLeft />
              </button>
              <h2 className=" text-xl    my-auto">{decodedName}</h2>
            </div>
          </div>
        </div>
        <div className="container">
           <form onSubmit={(e) => updateProductStatus(e)}>
          <div className=" flex flex-row justify-center gap-3 ">
          </div>
            <div className=" mt-5 flex flex-row justify-center gap-3 ">
              <div className="relative flex-1">
                <input
                required
                  ref={codePr}
                  type="text"
                  className="border border-dlabelColor outline-dbase w-full rounded-md  py-3 pr-8  pl-2"
                  placeholder="Product Id or SKU"
                />
                <div>
                  <FaTrash onClick={()=>{handleTrashClick()}} className="absolute top-1/2 transform -translate-y-1/2 right-3 cursor-pointer opacity-20 " />
                </div>
              </div>
              <button className=" bg-dbase rounded-md text-white px-5 ">
                {" "}
                {loading ? <Loader /> : <FaSync />}{" "}
              </button>
            </div>
          </form>
        </div>
         <div className="container ">
          {productInf &&
            productInf.map((product) => (
              <div className=" text-sm max-h-fit rounded-md shadow-md overflow-y-hidden container">
                <div
                  className={`
                        bg-dgreen2
                     px-5  w-full  py-2 `}
                >
                  <div className=" flex flex-row gap-5">
                    <div className=" my-auto h-fit  w-16 aspect-square">
                      <img
                        src={product.image}
                        className="  object-cover"
                        alt=""
                      />
                    </div>
                    <div className=" my-auto">
                      <div className=" flex flex-col justify-center">
                        <h2>
                          <strong>SKU:</strong> {product.sku}
                        </h2>
                        <h2> {product.product_id}</h2>
                        <h2>
                          <strong>Option:</strong>{" "}
                          {product.product_option_value_name}
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div> 
        <MessageAlert
          message={message}
          type={showMessgErr ? "err" : "succ"}
          showMessg={showMessgErr || (showMessgSucc && true)}
        />
      </div>
    </>
  )
}

export default ChangeSingleProductStatus