import React, { useContext, useEffect, useRef, useState } from "react";
import { FaArrowLeft, FaCheckCircle, FaSearch, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import buildLink from "../../urls";
import axios from "axios";
import { CompanyContext } from "../../contexts/companyCotext";
import { IoIosWarning } from "react-icons/io";
import Loader from "../../components/loader";
import MessageAlert from "../../components/messageAlert";
import { keyboard } from "@testing-library/user-event/dist/keyboard";
import { PlaySounds } from "../../functions/playSounds";
import hocStarredPage from "../../components/hocStarredPage";
import Cookies from "js-cookie";

const ReceiveProductQuantity = () => {

  const {playErrorSound,playSuccessSound} = PlaySounds();
  const history = useNavigate();
  const codePr = useRef(null);
  const qtyRef = useRef(null);
  const LinkRef = useRef(null)
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [showMessgErr, setShowMessageErr] = useState(false);
  const [message, setMessage] = useState("");
  const [showMessgSucc, setShowMessageSucc] = useState(false);
  const [stateCom, dispatchCom] = useContext(CompanyContext);
  const [loading, setLoading] = useState(false);
  const [productInf, setProductInf] = useState(null);

  const searchProduct = async (e) => {
    setLoadingSearch(true);
    e.preventDefault();

    const item = codePr.current.value;

    try {
      const url = buildLink("productInfo") + `&item=${item}`+ `&user_id=${stateCom.userID}`;
      await axios.get(url).then((response) => {
        if (response.data.success) {
          qtyRef.current.focus()
          setProductInf(response.data.data);
        }
      });
      setLoadingSearch(false);
    } catch (e) {
      playErrorSound()
      setLoadingSearch(false);
      setMessage(e.response.data.message);
      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
    }
    setLoadingSearch(false);
  };

  const getUserIdFromLocalStorage = () => {
    return localStorage.getItem("userID");
  };

  const receivedProductQty = async (e) => {
    e.preventDefault()
    const userID = getUserIdFromLocalStorage()
    const label = codePr.current.value;
    const user_id = userID;
    const quantity = qtyRef.current.value;
    const link = LinkRef.current.value;

    const obj = {
        label,
      user_id,
      quantity,
      link

  
    };
    setLoading(true);
    try {
      await axios
        .post(buildLink("receivedProductQty"), obj)
        .then((response) => {
          if (response.data.success) {
            history(-1)
            playSuccessSound()
            setMessage(response.data.message);
            setShowMessageSucc(true);
            setTimeout(() => {
              setShowMessageSucc(false);
            }, 3000);
          }
        });
    } catch (e) {
      playErrorSound()
      setMessage(e.response.data.message);
      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
    }
    setLoading(false);
  };

  useEffect(() => {
    codePr.current.focus();
  }, []);

  const handleTrashClick = () => {
    codePr.current.value = "";
    codePr.current.focus();
  };

  return (
    <>
      <div className="  h-full bg-white">
        {/* header */}
        <div className=" z-10 mb-5 sticky top-0 flex flex-col bg-white">
          <div className=" w-full bg-dbase h-16">
            <div className=" container flex relative gap-7 flex-row justify-start w-full text-white  h-full my-auto">
              <button onClick={() => history(-1)}>
                <FaArrowLeft />
              </button>
              <h2 className=" text-xl    my-auto">Received Product Qty</h2>
            </div>
          </div>
        </div>
        <div className="container flex flex-col gap-5">
          <form onSubmit={(e) => searchProduct(e)}>
            <div className=" flex flex-row justify-center gap-3 ">
              <div className="relative flex-1">
                <input
                  ref={codePr}
                  type="text"
                  className="border border-dlabelColor outline-dbase w-full rounded-md  py-3 pr-8  pl-2"
                  placeholder="Product Label"
                />
                <div>
                  <FaTrash
                    onClick={() => handleTrashClick()}
                    className="absolute top-1/2 transform -translate-y-1/2 right-3 cursor-pointer opacity-20 "
                  />
                </div>
              </div>
              <button className=" bg-dbase rounded-md text-white px-5 ">
                {" "}
                {loadingSearch ? <Loader /> : <FaSearch />}{" "}
              </button>
            </div>
          </form>
<form  onSubmit={(e) => receivedProductQty(e)}  className=" flex flex-col gap-5">
        

          <div className="relative flex-1">
            <input
            required
              ref={qtyRef}
              type="text"
              className="border border-dlabelColor outline-dbase w-full rounded-md  py-3 pr-8  pl-2"
              placeholder=" Quantity"
            />
          </div>
          <div className="relative flex-1">
            <input
            required
              ref={LinkRef}
              type="text"
              className="border border-dlabelColor outline-dbase w-full rounded-md  py-3 pr-8  pl-2"
              placeholder=" Link"
            />
          </div>

        

          <button
           
            type="submit"
            className=" bg-dbase  px-3 py-3 text-white rounded-sm mt-3"
          >
            {" "}
            {loading ? "Lading..." : "Add Received"}
          </button>
          </form>
        </div>

        {productInf && (
          <div className="container">
            <div className="bg-white rounded-md shadow-md w-full px3 py-2 mt-10 ">
              <div className=" flex flex-row gap-5">
                <img src={productInf.image} alt="" />
                <div className=" my-auto">
                  <div className=" flex flex-col justify-center">
                    <h2>SKU: {productInf.sku}</h2>
                    <h2>Qty: {productInf.quantity}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <MessageAlert
          message={message}
          type={showMessgErr ? "err" : "succ"}
          showMessg={showMessgErr || (showMessgSucc && true)}
        />
      </div>
    </>
  );
};

export default hocStarredPage(ReceiveProductQuantity);
