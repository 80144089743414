import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { CompanyContext } from "../contexts/companyCotext";
import { FaStar } from "react-icons/fa6";
import { FaWifi } from "react-icons/fa";
import { MdOutlineWifiOff } from "react-icons/md";

const hocStarredPage = (WrappedComponent) => {
  const HocStarredPage = (props) => {
    const [state] = useContext(CompanyContext);
    const [isFavorite, setIsFavorite] = useState(false);
    const navigate = useNavigate();
    const [wifiOn, setWifiOn] = useState(false);
    const store_name = localStorage.getItem("store_name");
    const [host, setHost] = useState(window.location.host);

    useEffect(() => {
      setHost(window.location.host);
    }, [host]);

    useEffect(() => {
      const favoritePage = Cookies.get("favoritePage");
      setIsFavorite(window.location.pathname === favoritePage);
    }, []);

    const handleFavoriteClick = () => {
      const newPath = window.location.pathname;
      const newIsFavorite = !isFavorite;
      setIsFavorite(newIsFavorite);

      if (newIsFavorite) {
        Cookies.set("favoritePage", newPath, { expires: 365 });
      } else {
        Cookies.remove("favoritePage");
      }
    };

    useEffect(() => {
      const handleOnlineStatusChange = () => {
        setWifiOn(navigator.onLine);
      };
      window.addEventListener("online", handleOnlineStatusChange);
      window.addEventListener("offline", handleOnlineStatusChange);
      setWifiOn(navigator.onLine);
      return () => {
        window.removeEventListener("online", handleOnlineStatusChange);
        window.removeEventListener("offline", handleOnlineStatusChange);
      };
    }, []);

    return (
      <div className="z-50 sticky top-0 flex flex-col bg-white">
        <div className="absolute w-full  h-16 flex items-center justify-end">
          <div className="relative w-40 h-20 text-white text-center">
            <FaStar
              className="absolute top-1/2 left-0 transform translate-x-10 -translate-y-1/2 w-7 h-7 z-30"
              style={{ color: isFavorite ? 'rgb(255,255,0)' : 'white' }}
              onClick={handleFavoriteClick}
            />
            {(
              host === "pda.flo-lebanon.com" ||
              host === "pda.englishhome.com.lb") && (
                <div className="absolute top-1/2 left-1/2 transform -translate-x-24 -translate-y-1/2 w-40 z-20">
                  {store_name}
                </div>
              )}
          </div>

        </div>
        <WrappedComponent {...props} />
      </div>

    );
  };

  return HocStarredPage;
};

export default hocStarredPage;
