import React, { useContext, useEffect, useRef, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import hocStarredPage from "../../../components/hocStarredPage";
import { axiosServer } from "../../../axiosServer";
import buildLink from "../../../urls";
import DOMPurify from "dompurify";
import Select from "react-select";
import axios from "axios";

const RefilBin = () => {
  const history = useNavigate();
  const [productList, setProductList] = useState(null);
  const [selectedWarehouseId, setSelectedWarehouseId] = useState(null);
  const [warehouses, setWarehouses] = useState([]);
  const [loadingWarehouses, setLoadingWarehouses] = useState(true);
  const [error, setError] = useState(null);
  const userId = localStorage.getItem("userID");
  const fetchfromwarehouse = async () => {
    try {
      const url = buildLink(`fetchfromwarehouse`);
      const response = await axios.get(url);

      if (response.data.success) {
        setWarehouses(response.data.data || []);
        setLoadingWarehouses(false);
      } else {
        setError("Failed to fetch warehouses.");
      }
    } catch (err) {
      setError("Failed to fetch warehouses.");
    } finally {
      setLoadingWarehouses(false);
    }
  };

  useEffect(() => {
    fetchfromwarehouse();
  }, []);

  const handleWarehouseChange = async (selectedOption) => {
    setSelectedWarehouseId(selectedOption ? selectedOption.value : null);

    if (selectedOption) {
      await getProductList(selectedOption.value);
    }
  };

  async function getProductList(warehouseId) {
    try {
      const response = await axiosServer.get(
        `${buildLink("refillProduct")}&user_id=${userId}&warehouse_id=${warehouseId}`
      );
      if (response.data.products) {
        setProductList(response.data.products);
      } else {
        setProductList([]);
      }
    } catch (error) {
      setError("Failed to fetch products.");
    }
  }

  function sanitizeHTML(html) {
    if (typeof window !== "undefined") {
      return DOMPurify.sanitize(html);
    } else {
      return html;
    }
  }

  function unescapeHTML(str) {
    if (!str) {
      return;
    }
    return str.replace(/\&([^;]+);/g, function (entity, entityCode) {
      var match;

      if (entityCode in htmlEntities) {
        return htmlEntities[entityCode];
        /*eslint no-cond-assign: 0*/
      } else if ((match = entityCode.match(/^#x([\da-fA-F]+)$/))) {
        return String.fromCharCode(parseInt(match[1], 16));
        /*eslint no-cond-assign: 0*/
      } else if ((match = entityCode.match(/^#(\d+)$/))) {
        return String.fromCharCode(~~match[1]);
      } else {
        return entity;
      }
    });
  }
  var htmlEntities = {
    nbsp: " ",
    cent: "¢",
    pound: "£",
    yen: "¥",
    euro: "€",
    copy: "©",
    reg: "®",
    lt: "<",
    gt: ">",
    quot: '"',
    amp: "&",
    apos: "'",
  };
  // useEffect(() => {
  //   getProductList();
  // }, []);

  // async function getProductList() {
  //   const response = await axiosServer.get(
  //     buildLink("refillProduct") + "&user_id=1272"
  //   );
  //   setProductList(response.data.products);
  // }

  return (
    <>
      <div className="  h-full bg-white">
        <div className=" mb-5 sticky top-0 flex flex-col bg-white z-40">
          <div className=" w-full bg-dbase h-16">
            <div className=" container px-3  relative flex justify-between w-full text-white  h-full my-auto">
              <div className=" flex  gap-7 flex-row justify-start">
                <button onClick={() => history(-1)}>
                  <FaArrowLeft />
                </button>
                <h2 className=" text-xl my-auto">Refil Bin</h2>
              </div>
            </div>
          </div>
        </div>

        <div className="px-10 mb-10">
          <Select
            placeholder="Select Warehouse"
            onChange={handleWarehouseChange}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderRadius: "7px",
                borderColor: state.isFocused ? "grey" : "grey",
                padding: "8px 5px",
              }),
            }}
            options={
              warehouses.length > 0
                ? warehouses.map((warehouse) => ({
                    value: warehouse.warehouse_id,
                    label: warehouse.warehouse_name,
                  }))
                : []
            }
            className="w-full" // Make select take full width
          />
        </div>
        <div className=" flex flex-col gap-2 container ">
          {productList &&
            productList.map((item, index) => (
              <Link
                to={item.sku}
                className=" w-full  gap-2 flex border border-dplaceHolder rounded-md px-2 py-1 flex-row justify-start"
              >
                <div className=" relative overflow-hidden  w-[20%]">
                  <img className="  w-full" src={`${item.image}`} />
                </div>
                <div className=" flex flex-col gap-2 w-[80%]">
                  <h2
                    dangerouslySetInnerHTML={{
                      __html: unescapeHTML(sanitizeHTML(item.name)),
                    }}
                    className="  line-clamp-2 overflow-hidden text-ellipsis  my-auto"
                  />
                  <div className=" w-full flex flex-col gap-2">
                    <span className=" text-sm bg-dplaceHolder px-1 rounded-sm">
                      SKU: {item.sku}
                    </span>
                    <span className=" text-sm text-dbase font-bold">
                      quantity to restock: {item.quantity_to_restock}
                    </span>
                  </div>
                </div>
              </Link>
            ))}
        </div>
      </div>
    </>
  );
};

export default hocStarredPage(RefilBin);
