import React, { useContext, useEffect, useRef, useState } from "react";
import { FaArrowLeft, FaFill, FaSearch, FaTrash } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import hocStarredPage from "../../../components/hocStarredPage";
import { axiosServer } from "../../../axiosServer";
import buildLink from "../../../urls";
import DOMPurify from "dompurify";
import { BsSendArrowUpFill } from "react-icons/bs";
import Loader from "../../../components/loader";
import { PlaySounds } from "../../../functions/playSounds";
import MessageAlert from "../../../components/messageAlert";
import axios from "axios";
import Select from "react-select";
import Cookies from "js-cookie";

const ProductRoRefillWarehouse = () => {
  const { playErrorSound, playSuccessSound } = PlaySounds();
  const [showMessgErr, setShowMessageErr] = useState(false);
  const [message, setMessage] = useState("");
  const [showMessgSucc, setShowMessageSucc] = useState(false);
  const history = useNavigate();
  const [prodcutInfo, setProductInfo] = useState(null);
  const skuRef = useRef(null);
  const binRef = useRef(null);
  const qtyref = useRef(null);

  const [loadingSubmit, setLoadingSubmit] = useState(null);
  const quantityRef = useRef(null);
  const [warehouses, setWarehouses] = useState([]);
  const [loadingWarehouses, setLoadingWarehouses] = useState(true);
  const [error, setError] = useState(null);
  const [selectedWarehousefrom, setSelectedWarehousefrom] = useState(null);
  const [selectedWarehouseto, setSelectedWarehouseto] = useState(null);

  const [FromWarehouse, setFromWarehouse] = useState([]);
  const userId = localStorage.getItem("userID");

  const handleFromWarehouseChange = (selectedOption) => {
    setSelectedWarehousefrom(selectedOption);

    setSelectedWarehouseto(null);

    fetchtowarehouse(selectedOption);

    Cookies.set("from_warehouse", JSON.stringify(selectedOption), {
      expires: 1,
    });
  };

  const handleToWarehouseChange = (option) => {
    setSelectedWarehouseto(option);
    Cookies.set("to_warehouse", JSON.stringify(option), {
      expires: 1,
    });
  };

  useEffect(() => {
    const fromWarehouseCookie = Cookies.get("from_warehouse");
    const toWarehouseCookie = Cookies.get("to_warehouse");

    if (fromWarehouseCookie) {
      setSelectedWarehousefrom(JSON.parse(fromWarehouseCookie));
      fetchtowarehouse(JSON.parse(fromWarehouseCookie));
    }
    if (toWarehouseCookie) {
      setSelectedWarehouseto(JSON.parse(toWarehouseCookie));
    }
  }, []);

  const fetchtowarehouse = async (e) => {
    const warehouseId = e.value;
    // setSelectedWarehousefrom(warehouseId);
    setSelectedWarehouseto(null);

    try {
      const url = buildLink(`towarehouse`) + `&warehouse_id=${warehouseId}`;
      const response = await axios.get(url);

      if (response.data.success) {
        setFromWarehouse(response.data.data || []);
      } else {
        setError("Failed to fetch from warehouses.");
      }
    } catch (err) {
      setError("Failed to fetch from warehouses.");
    } finally {
      setLoadingWarehouses(false);
    }
  };

  const { sku } = useParams();
  function sanitizeHTML(html) {
    if (typeof window !== "undefined") {
      return DOMPurify.sanitize(html);
    } else {
      return html;
    }
  }

  const clearinputs = () => {
    qtyref.current.value = "";
    binRef.current.value = "";
    skuRef.current.value = "";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingSubmit(true);
    // if( prodcutInfo.quantity_to_restock >=30  && quantityRef.current.value > prodcutInfo.quantity_to_restock){
    if (
      parseInt(qtyref.current.value) > parseInt(prodcutInfo.quantity_to_restock)
    ) {
      console.log(123);
      setMessage("Entered Quantity Should Be Less Or Equal To Refill Quantity");
      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
      setLoadingSubmit(false);
      clearinputs();

      return;
    }
    const response = await axios.post(buildLink("refillProduct"), {
      sku: skuRef.current.value,
      address: binRef.current.value,
      user_id: userId,
      quantity: qtyref.current.value,
      from_warehouse_id: selectedWarehousefrom?.value || null,
      to_warehouse_id: selectedWarehouseto?.value || null,
    });
    if (response.data.success) {
      playSuccessSound();
      setMessage(response.data.message);
      setShowMessageSucc(true);
      setTimeout(() => {
        setShowMessageSucc(false);
      }, 3000);
      clearinputs();
    } else {
      playErrorSound();
      setMessage(response.data.message);
      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
      clearinputs();
    }
    setLoadingSubmit(false);
    getProductInfo();
    clearinputs();
  };

  useEffect(() => {
    if (prodcutInfo && prodcutInfo.quantity_to_restock == 0) {
      history(-1);
    }
  }, [prodcutInfo]);

  function unescapeHTML(str) {
    if (!str) {
      return;
    }
    return str.replace(/\&([^;]+);/g, function (entity, entityCode) {
      var match;

      if (entityCode in htmlEntities) {
        return htmlEntities[entityCode];
        /*eslint no-cond-assign: 0*/
      } else if ((match = entityCode.match(/^#x([\da-fA-F]+)$/))) {
        return String.fromCharCode(parseInt(match[1], 16));
        /*eslint no-cond-assign: 0*/
      } else if ((match = entityCode.match(/^#(\d+)$/))) {
        return String.fromCharCode(~~match[1]);
      } else {
        return entity;
      }
    });
  }
  var htmlEntities = {
    nbsp: " ",
    cent: "¢",
    pound: "£",
    yen: "¥",
    euro: "€",
    copy: "©",
    reg: "®",
    lt: "<",
    gt: ">",
    quot: '"',
    amp: "&",
    apos: "'",
  };
  useEffect(() => {
    if (sku) {
      getProductInfo();
    }
  }, [sku]);

  async function getProductInfo() {
    const response = await axiosServer.get(
      buildLink("refillProduct") + "&user_id="+userId + "&sku=" + sku
    );
    setProductInfo(response.data.data);
    if (response.data.data.quantity_to_restock == 0) {
      history(-1);
    }
  }

  const fetchfromwarehouse = async () => {
    try {
      const url = buildLink(`fetchfromwarehouse`);
      const response = await axios.get(url);

      if (response.data.success) {
        setWarehouses(response.data.data || []);
        setLoadingWarehouses(false);
      } else {
        setError("Failed to fetch warehouses.");
      }
    } catch (err) {
      setError("Failed to fetch warehouses.");
    } finally {
      setLoadingWarehouses(false);
    }
  };

  useEffect(() => {
    fetchfromwarehouse();
  }, []);

  return (
    <>
      <div className="  h-full bg-white">
        <div className=" mb-5 sticky top-0 flex flex-col bg-white z-40">
          <div className=" w-full bg-dbase h-16">
            <div className=" container px-3  relative flex justify-between w-full text-white  h-full my-auto">
              <div className=" flex  gap-7 flex-row justify-start">
                <button onClick={() => history(-1)}>
                  <FaArrowLeft />
                </button>
                <h2 className=" text-xl my-auto">Product To Refill</h2>
              </div>
            </div>
          </div>
        </div>
        {prodcutInfo && (
          <div className=" flex flex-col gap-2 container ">
            <form onSubmit={handleSubmit} className=" flex flex-col gap-2">
              {loadingWarehouses ? (
                <div className="text-center text-dbase">
                  <Loader />
                </div>
              ) : error ? (
                <p className="text-dbase">{error}</p>
              ) : (
                <Select
                  placeholder="Source Warehouse"
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderRadius: "7px",
                      borderColor: state.isFocused ? "grey" : "grey",
                      padding: "8px 5px",
                    }),
                  }}
                  options={
                    warehouses.length > 0
                      ? warehouses.map((warehouse) => ({
                          value: warehouse.warehouse_id,
                          label: warehouse.warehouse_name,
                        }))
                      : []
                  }
                  onChange={handleFromWarehouseChange}
                  defaultValue={selectedWarehousefrom}
                />
              )}

              {loadingWarehouses ? (
                <div className="text-center text-dbase">
                  <Loader />
                </div>
              ) : error ? (
                <p className="text-dbase">{error}</p>
              ) : (
                <Select
                  placeholder="Destination Warehouse"
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderRadius: "7px",
                      borderColor: state.isFocused ? "grey" : "grey",
                      padding: "8px 5px",
                    }),
                  }}
                  options={
                    FromWarehouse.length > 0
                      ? FromWarehouse.map((warehouse) => ({
                          value: warehouse.warehouse_id,
                          label: warehouse.warehouse_name,
                        }))
                      : []
                  }
                  value={selectedWarehouseto}
                  onChange={handleToWarehouseChange}
                />
              )}
              <div className="relative flex gap-2 flex-row flex-1">
                <input
                  ref={skuRef}
                  required
                  type="text"
                  className="border border-dlabelColor outline-dbase w-full rounded-md  py-3 pr-8  pl-2"
                  placeholder="Product Sku"
                />
              </div>
              <div className="relative flex-1">
                <input
                  ref={binRef}
                  required
                  type="text"
                  className="border border-dlabelColor outline-dbase w-full rounded-md  py-3 pr-8  pl-2"
                  placeholder="Bin"
                />
              </div>

              <div className="flex flex-row gap-5">
                <input
                  ref={qtyref}
                  required
                  type="number"
                  className="border border-dlabelColor outline-dbase w-full rounded-md  py-3 pr-8  pl-2"
                  placeholder="Quantity"
                />
                <button
                  disabled={loadingSubmit}
                  className=" bg-dbase rounded-md text-white px-5 "
                >
                  {" "}
                  {loadingSubmit ? <Loader /> : <BsSendArrowUpFill />}
                </button>
              </div>

              {prodcutInfo.quantity_to_restock >= 30 && (
                <input
                  ref={quantityRef}
                  max={prodcutInfo.quantity_to_restock}
                  required
                  type="text"
                  className="border border-dlabelColor outline-dbase w-full rounded-md  py-3 pr-8  pl-2"
                  placeholder="Qty"
                />
              )}
            </form>

            <div className=" flex flex-col  gap-5 justify-center text-center">
              <div className=" flex flex-row justify-between  w-full ">
                <div className=" flex flex-col justify-center text-center">
                  <h2>
                    {" "}
                    <span className="font-bold">Refill Qty: </span>{" "}
                    <span className="text-dbase">
                      {prodcutInfo.quantity_to_restock}
                    </span>
                  </h2>
                </div>
                <div className=" flex flex-col justify-center text-center">
                  <h2>
                    {" "}
                    <span className="font-bold">Bin Qty: </span>{" "}
                    <span className="text-dgreen2">
                      {prodcutInfo.bin_quantity}
                    </span>
                  </h2>
                </div>
              </div>
              <div className=" relative overflow-hidden  w-[40%] lg:w-[20%] mx-auto">
                <img className="  w-full" src={`${prodcutInfo.image}`} />
              </div>
              <div className=" w-full flex flex-row  justify-center  gap-2">
                <span className=" text-xl bg-dplaceHolder font-bold px-1  rounded-sm">
                  {" "}
                  SKU: {prodcutInfo.sku}
                </span>
              </div>
              <div className=" flex flex-col gap-2 mx-auto w-full">
                <h2
                  dangerouslySetInnerHTML={{
                    __html: unescapeHTML(sanitizeHTML(prodcutInfo.name)),
                  }}
                  className="my-auto"
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <MessageAlert
        message={message}
        type={showMessgErr ? "err" : "succ"}
        showMessg={showMessgErr || (showMessgSucc && true)}
      />
    </>
  );
};

export default hocStarredPage(ProductRoRefillWarehouse);
