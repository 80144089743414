import React, { useContext, useEffect, useRef, useState } from "react";
import {
  FaArrowLeft,
  FaCheckCircle,
  FaSearch,
  FaTrash,
  FaWatchmanMonitoring,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import buildLink from "../../../urls";
import axios from "axios";
import { CompanyContext } from "../../../contexts/companyCotext";
import { IoIosWarning } from "react-icons/io";
import Loader from "../../../components/loader";
import MessageAlert from "../../../components/messageAlert";
import { PlaySounds } from "../../../functions/playSounds";
import hocStarredPage from "../../../components/hocStarredPage";
import Cookies from "js-cookie";

const ChangeBigUpcv2 = () => {
  const {playErrorSound,playSuccessSound} = PlaySounds();
  const history = useNavigate();
  const codePr = useRef(null);
  const oldUpc = useRef(null);
  const newUpc = useRef(null);
  const oldUpcSh = useState(null);
  const newUpcSh = useState(null);

  const [stateCom, dispatchCom] = useContext(CompanyContext);
  const [loading, setLoading] = useState(false);
  const [productInf, setProductInf] = useState(null);
  const [showMessgSucc, setShowMessageSucc] = useState(false);
  const [message, setMessage] = useState("");
  const [showMessgErr, setShowMessageErr] = useState(false);
  const [activeTab, setActiveTab] = useState(1);

  const [loadingSearch, setLoadingSearch] = useState(false);

  useEffect(()=>{
   if(activeTab ==2){
    oldUpcSh.current.focus();
   }else{
    codePr.current.focus();
   }
  },[activeTab])

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const searchProduct = async (e) => {
    setLoadingSearch(true);
    e.preventDefault();

    const item = codePr.current.value;

    try {
      const url = buildLink("productInfo") + `&item=${item}&v2=true`+ `&user_id=${stateCom.userID}`;
      await axios.get(url).then((response) => {
        if (response.data.success) {
          setProductInf(response.data.data);
          oldUpc.current.value = response.data.data.big_upc;
          newUpc.current.focus();
        }
      });
      setLoadingSearch(false);
    } catch (e) {
      playErrorSound()
      setLoadingSearch(false);
      setMessage(e.response.data.message);
      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
    }
    setLoadingSearch(false);
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


  const getUserIdFromLocalStorage = () => {
    return localStorage.getItem("userID");
  };


  async function updateUpcQty(e) {
    e.preventDefault()
    const userID = getUserIdFromLocalStorage()
    setLoading(true);
    const product_id = codePr.current.value;
    const big_upc = newUpc.current.value;
    const user_id = userID;

    const obj = {
      product_id,
      big_upc,
      user_id,
    };
    try {
      const url = buildLink("updateBigUPC") + `&v2=true` ;
      await axios.put(url, obj).then((response) => {
        if (response.data.success) {
          playSuccessSound()
          setMessage(response.data.message);
          setShowMessageSucc(true);
          setTimeout(() => {
            setShowMessageSucc(false);
          }, 3000);
          oldUpc.current.value = ''
          newUpc.current.value = ''
          codePr.current.value = ''
        }
      });
    } catch (e) {
      playErrorSound()
      setMessage(e.response.data.message);
      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
    }
    setLoading(false);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  async function shelfProduct(e) {
    e.preventDefault()
    const userID = getUserIdFromLocalStorage()
    setLoading(true);

    const old_big_upc = oldUpcSh.current.value;
    const new_big_upc = newUpcSh.current.value;
    const user_id = userID;

    const obj = JSON.stringify({
      old_big_upc,
      new_big_upc,
    });
    try {
      const url = buildLink("updateBigUPC") + `&v2=true` ;
      await axios
        .put(url, JSON.parse(obj))
        .then((response) => {
          if (response.data.success) {
            playSuccessSound()
            setShowMessageSucc(true);
            setTimeout(() => {
              setShowMessageSucc(false);
            }, 3000);
            oldUpcSh.current.value = ''
            newUpcSh.current.value = ''
          }
        });
    } catch (e) {
      playErrorSound()
      setMessage(e.response.data.message);
      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
    }
    setLoading(false);
  }
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
 

  const handleTrashClick = () => {
    codePr.current.value = "";
    codePr.current.focus();
  };

  return (
    <>
      <div className=" h-full bg-white ">
        {/* header */}
        <div className=" mb-5 sticky top-0 flex flex-col bg-white">
          <div className=" w-full bg-dbase h-16">
            <div className=" container flex relative gap-7 flex-row justify-start w-full text-white  h-full my-auto">
              <button onClick={() => history(-1)}>
                <FaArrowLeft />
              </button>
              <h2 className=" text-xl    my-auto">Change Big Upc</h2>
            </div>
          </div>
        </div>
        <div className="container flex flex-col gap-5">
          <div className=" flex w-full justify-center gap-2">
            <button
              onClick={() => setActiveTab(1)}
              className={`${
                activeTab === 1 ? "bg-dbase text-white" : "bg-white text-dblack"
              }  transition-all w-full py-2  rounded-full`}
            >
              {" "}
              Single Product
            </button>
            <button
              onClick={() => setActiveTab(2)}
              className={`${
                activeTab === 2 ? "bg-dbase text-white" : "bg-white text-dblack"
              }  transition-all w-full py-2 rounded-full`}
            >
              {" "}
              Shelf Products
            </button>
          </div>
          {activeTab === 1 && (
            <>
              <form onSubmit={(e) => searchProduct(e)}>
                <div className=" flex flex-row justify-center gap-3 ">
                  <div className="relative flex-1">
                    <input
                      ref={codePr}
                      type="text"
                      className="border border-dlabelColor outline-dbase w-full rounded-md  py-3 pr-8  pl-2"
                      placeholder="Product ID Or Sku"
                    />
                    <div>
                      <FaTrash
                        onClick={() => handleTrashClick()}
                        className="absolute top-1/2 transform -translate-y-1/2 right-3 cursor-pointer opacity-20 "
                      />
                    </div>
                  </div>
                  <button className=" bg-dbase rounded-md text-white px-5 ">
                    {" "}
                    {loadingSearch ? <Loader /> : <FaSearch />}{" "}
                  </button>
                </div>
              </form>
              <form onSubmit={(e)=>updateUpcQty(e)} className="flex flex-col justify-center w-full gap-2">
              <div className="relative flex-1">
                <input
                  ref={oldUpc}
                  type="text"
                  className="border border-dlabelColor outline-dbase w-full rounded-md  py-3 pr-8  pl-2"
                  placeholder="Old Big UPC"
                />
              </div>

              <div className="relative flex-1">
                <input
                  ref={newUpc}
                  type="text"
                  className="border border-dlabelColor outline-dbase w-full rounded-md  py-3 pr-8  pl-2"
                  placeholder="New Big UPC"
                />
              </div>
              <button
               
                type="submit"
                className=" bg-dbase  px-3 py-3 text-white rounded-sm mt-3"
              >
                {loading ? "Loading..." : "Change Big UPC"}{" "}
              </button>
              </form>
            

              {productInf && (
                <div className="container">
                  <div className="bg-white rounded-md shadow-md w-full px3 py-2 mt-10 ">
                    <div className=" flex flex-row gap-5">
                      <img src={productInf.image} alt="" />
                      <div className=" my-auto">
                        <div className=" flex flex-col justify-center">
                          <h2>SKU: {productInf.sku}</h2>
                          <h2>Qty: {productInf.quantity}</h2>
                          <h2>Barcode: {productInf.barcode}</h2>
                          <h2>Address: {productInf.address}</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}

          {activeTab === 2 && (
            <form   onSubmit={(e) => shelfProduct(e)} className=" flex flex-col gap-4 ">
              <div className="relative flex-1">
                <input required
                  ref={oldUpcSh}
                  type="text"
                  className="border border-dlabelColor outline-dbase w-full rounded-md  py-3 pr-8  pl-2"
                  placeholder="Old Big UPC"
                />
              </div>

              <div className="relative flex-1">
                <input required
                  ref={newUpcSh}
                  type="text"
                  className="border border-dlabelColor outline-dbase w-full rounded-md  py-3 pr-8  pl-2"
                  placeholder="New Big UPC"
                />
              </div>

              <button
              
                type="submit"
                className=" bg-dbase  px-3 py-3 text-white rounded-sm mt-3"
              >
                {loading ? "Loading..." : "Change Big UPC"}{" "}
              </button>
            </form>
          )}
        </div>

        <MessageAlert
          message={message}
          type={showMessgErr ? "err" : "succ"}
          showMessg={showMessgErr || (showMessgSucc && true)}
        />
      </div>
    </>
  );
};

export default hocStarredPage(ChangeBigUpcv2);
