import React, { useEffect, useRef, useState } from "react";
import { FaArrowLeft, FaCheckCircle, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import buildLink from "../../urls";
import axios from "axios";
import MessageAlert from "../../components/messageAlert";
import { PlaySounds } from "../../functions/playSounds";
import hocStarredPage from "../../components/hocStarredPage";

const Order = () => {

  const {playErrorSound,playSuccessSound} = PlaySounds();
  const orderId = useRef(null);
  const [loading, setLoading] = useState(false);
  const [orderStatus, setOrderStatus] = useState("normal");
  const [showMessgErr, setShowMessageErr] = useState(false);
  const [message, setMessage] = useState("");
  const [showMessgSucc, setShowMessageSucc] = useState(false);

  const searchOrder = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      var url = "";
      if (orderStatus == "normal") {
        url =
          buildLink("productsFullVersion") +
          `&order_id=${orderId.current.value}`;
      } else if (orderStatus == "return") {
        url =
          buildLink("returnProductsFullVersion") +
          `&return_order_id=${orderId.current.value}`;
      }

      await axios.get(url).then((response) => {
        if (response.data.success) {
          history("orderproductlist", {
            state: {
              products: response.data.data.products,
              logistic: response.data.data.logistic_id,
              orederStatus: orderStatus,
              order_id: response.data.data.order_id,
            },
          });
        }
      });
      setLoading(false);
    } catch (e) {
      playErrorSound()
      setLoading(false);
      setMessage(e.response.data.message);
      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
    }
  };

  const history = useNavigate();
  const options = [
    { value: "normal", label: "Normal" },
    { value: "return", label: "Return" },
  ];

  useEffect(() => {
    orderId.current.focus();
  }, []);

  const handleTrashClick = () => {
    orderId.current.value = "";
    orderId.current.focus();
  };

  return (
    <>
      <div className=" h-full bg-white ">
        {/* header */}
        <div className=" mb-5 sticky top-0 flex flex-col ">
          <div className=" w-full bg-dbase h-16">
            <div className=" container flex relative gap-7 flex-row justify-start w-full text-white  h-full my-auto">
              <button onClick={() => history(-1)}>
                <FaArrowLeft />
              </button>
              <h2 className=" text-xl    my-auto">Order Product</h2>
            </div>
          </div>
        </div>

        <div className=" container">
          <form
            onSubmit={(e) => searchOrder(e)}
            className=" flex flex-col gap-5 justify-center"
          >
            <div className="relative flex-1">
              <input
                ref={orderId}
                type="text"
                className="border border-dlabelColor outline-dbase w-full rounded-md  py-3 pr-8  pl-2"
                placeholder="Order Id"
              />
              <div>
                <FaTrash
                  onClick={() => handleTrashClick()}
                  className="absolute top-1/2 transform -translate-y-1/2 right-3 cursor-pointer opacity-20 "
                />
              </div>
            </div>
            <div className=" py-3">
              <Select
                placeholder="Order Status"
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderRadius: "7px",
                    borderColor: state.isFocused ? "grey" : "grey",
                    outline: "red",
                    padding: "8px 5px",
                  }),
                }}
                onChange={(e) => setOrderStatus(e.value)}
                options={options}
              />
            </div>

            <button
              type="submit"
              onClick={(e) => {}}
              className=" bg-dbase  px-3 py-3 text-white rounded-sm mt-3"
            >
              {" "}
              {loading ? "Loading..." : "Search Order"}
            </button>
          </form>
        </div>

        <MessageAlert
          message={message}
          type={showMessgErr ? "err" : "succ"}
          showMessg={showMessgErr || (showMessgSucc && true)}
        />
      </div>
    </>
  );
};

export default hocStarredPage(Order);
