import React, { useContext, useEffect, useRef, useState } from "react";
import { FaArrowLeft, FaCheckCircle, FaSearch, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { AiFillExclamationCircle } from "react-icons/ai";

import axios from "axios";
import { IoIosWarning } from "react-icons/io";

import { axiosServer } from "../../../../axiosServer";
import buildLink from "../../../../urls";
import MessageAlert from "../../../../components/messageAlert";
import { CompanyContext } from "../../../../contexts/companyCotext";
import Loader from "../../../../components/loader";
import hocStarredPage from "../../../../components/hocStarredPage";

const Assign = () => {
  const productId = useRef(null);
  const boxId = useRef(null);
  const floorId = useRef(null);
  const [products, setProducts] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showMessgErr, setShowMessageErr] = useState(false);
  const [message, setMessage] = useState("");
  const [showMessgSucc, setShowMessageSucc] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [productInf, setProductInf] = useState(null);
  const [stateCom, dispatchCom] = useContext(CompanyContext);
  const barcode = useRef(null);
  const oldUPC = useRef(null);
  const newUPC = useRef(null);
  const [loadingSearch, setLoadingSearch] = useState(false);

  async function getPalletProducts(e) {
    e.preventDefault();
    await globalGetPalletProducts();
  }

  async function globalGetPalletProducts() {
    const url = buildLink("getPalletProducts");
    try {
      await axiosServer
        .get(url + `&pallet_id=${boxId.current.value}`)
        .then((res) => {
          setLoading(false);
          if (res.data.success) {
            setProducts(res.data.products);
          }
        });
    } catch (e) {
      setLoading(false);
      setMessage(e.response.data.message);
      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
    }
  }

  async function assignFloor(e) {
    e.preventDefault();
    const url = buildLink("assignFloor");
    const obj = {
      pallet_id: boxId.current.value,
      floor_id: floorId.current.value,
    };
    try {
      await axiosServer.post(url, obj).then((res) => {
        setLoading(false);
        if (res.data.success) {
          setMessage(res.data.message);
          setShowMessageSucc(true);
          setTimeout(() => {
            setShowMessageSucc(false);
          }, 3000);
          floorId.current.value = ''
          boxId.current.value = ''
        }
      });
    } catch (e) {
      setLoading(false);
      setMessage(e.response.data.message);
      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
    }
  }

  async function assignToPallet(e) {
    e.preventDefault();
    const url = buildLink("assignToPallet");
    const obj = {
      pallet_id: boxId.current.value,
      product_identifier: productId.current.value,
    };

    try {
      axiosServer.post(url, obj).then((res) => {
        console.log(res);
        if (res.data.success) {
          globalGetPalletProducts();
          setMessage(res.data.message);
          setShowMessageSucc(true);
          setTimeout(() => {
            setShowMessageSucc(false);
          }, 3000);
          boxId.current.value = ''
          productId.current.value = ''
        }
      });
    } catch (e) {
      console.log(e);
      setLoading(false);
      setMessage(e.response.data.message);
      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
    }
  }

  const history = useNavigate();

  useEffect(() => {
    boxId.current.focus();
  }, []);

  const handleTrashClick = () => {
    boxId.current.value = "";
    boxId.current.focus();
  };

  return (
    <>
      <div className=" h-full bg-white">
        {/* header */}
        <div className=" mb-5 sticky top-0  z-30 flex flex-col bg-white">
          <div className=" w-full bg-dbase h-16">
            <div className=" container flex relative gap-7 flex-row justify-start w-full text-white  h-full my-auto">
              <button onClick={() => history(-1)}>
                <FaArrowLeft />
              </button>
              <h2 className=" text-xl my-auto">Assign</h2>
            </div>
          </div>
        </div>

        <div className=" w-screen  bg-white my-4 container ">
          <div className=" flex w-full justify-center gap-2">
            <button
              onClick={() => setActiveTab(1)}
              className={`${
                activeTab === 1 ? "bg-dbase text-white" : "bg-white text-dblack"
              }  transition-all w-full py-2  rounded-full`}
            >
              {" "}
              Product To Pallet
            </button>
            <button
              onClick={() => setActiveTab(2)}
              className={`${
                activeTab === 2 ? "bg-dbase text-white" : "bg-white text-dblack"
              }  transition-all w-full py-2 rounded-full`}
            >
              {" "}
              Pallet To Floor
            </button>
          </div>
        </div>
        {activeTab === 1 && (
          <>
            <div className="container">
              <form onSubmit={(e) => getPalletProducts(e)}>
                <div className=" flex flex-row justify-center gap-3 ">
                  <div className="relative flex-1">
                    <input
                      ref={boxId}
                      type="text"
                      className="border border-dlabelColor outline-dbase w-full rounded-md  py-3 pr-8  pl-2"
                      placeholder="Pallet ID"
                      required
                    />
                    <div>
                      <FaTrash
                        onClick={() => handleTrashClick()}
                        className="absolute top-1/2 transform -translate-y-1/2 right-3 cursor-pointer opacity-20 "
                      />
                    </div>
                  </div>
                  <button
                    type="submit"
                    className=" bg-dbase rounded-md text-white px-5 "
                  >
                    {" "}
                    {loadingSearch ? <Loader /> : <FaSearch />}{" "}
                  </button>
                </div>
              </form>
              <form onSubmit={(e) => assignToPallet(e)}>
                <div className=" flex flex-row justify-center gap-3  mt-5">
                  <div className="relative flex-1">
                    <input
                      ref={productId}
                      type="text"
                      className="border border-dlabelColor outline-dbase w-full rounded-md  py-3 pr-8  pl-2"
                      placeholder="Product ID or SKU"
                      required
                    />
                    <div>
                      <FaTrash className="absolute top-1/2 transform -translate-y-1/2 right-3 cursor-pointer opacity-20 " />
                    </div>
                  </div>
                  <button
                    type="submit"
                    className=" bg-dbase rounded-md text-white px-5 "
                  >
                 
                    {<FaCheckCircle/>}
                  </button>
                </div>
              </form>
            </div>

            <div className="container mt-4  px-3 gap-1 flex-col  flex rounded-md ">
              {products &&
                products.map((product) => {
                  return (
                    <div className="container  border rounded-md border-dlabelColor flex flex-row">
                      <div>
                        <img className="  w-32" src={product.image}></img>
                      </div>
                      <div className=" flex p-3 flex-col text-start gap-1">
                        <p>
                          <strong>Sku: </strong>
                          {product.sku}
                        </p>
                        <p>
                          <strong>Barcode: </strong>
                          {product.product_id}
                        </p>
                        <p>
                          <strong>Quantity: </strong>
                          {product.quantity}
                        </p>
                      </div>
                    </div>
                  );
                })}
            </div>
          </>
        )}
        {activeTab === 2 && (
          <>
            <form
              onSubmit={(e) => assignFloor(e)}
              className="container relative flex-1"
            >
              <span>
                <input
                  type="text"
                  className="border border-dlabelColor outline-dbase w-full rounded-md  py-2 pr-8 pl-2 mb-4"
                  placeholder="Pallet ID"
                  ref={boxId}
                  required
                />
              </span>

              <span>
                <input
                  ref={floorId}
                  type="text"
                  className="border border-dlabelColor outline-dbase w-full rounded-md  py-2 pr-8  pl-2 "
                  placeholder="Floor Id"
                  required
                />
              </span>
              <button
                type="submit"
                className=" w-full py-1 bg-dbase text-white mt-4 rounded-md"
              >
                Assign to floor
              </button>
            </form>
            <div className="w-full container"></div>
          </>
        )}

        <MessageAlert
          message={message}
          type={showMessgErr ? "err" : "succ"}
          showMessg={showMessgErr || (showMessgSucc && true)}
        />
      </div>
    </>
  );
};

export default hocStarredPage(Assign);
