import React, { useEffect, useRef, useState } from "react";
import { FaArrowLeft, FaCheck, FaCheckCircle, FaSearch, FaTrash, FaUpload } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import axios from "axios";
import MessageAlert from "../../../../components/messageAlert";
import Loader from "../../../../components/loader";
import NavigateButton from "../../../../components/buttonsNavigate";
import hocStarredPage from "../../../../components/hocStarredPage";

const RecivedFromSellerv2 = () => {
const history = useNavigate();

  return (
    <>
      <div className="h-full bg-white">
        {/* header */}
        <div className=" mb-5 sticky top-0 flex flex-col bg-white">
          <div className=" w-full bg-dbase h-16">
            <div className=" container flex relative gap-7 flex-row justify-start w-full text-white  h-full my-auto">
              <button onClick={() => history(-1)}>
                <FaArrowLeft />
              </button>
              <h2 className=" text-xl my-auto">Received From Seller</h2>
            </div>
          </div>
        </div>
        <div className="  grid grid-cols-2 container gap-x-6 gap-y-4">


          <NavigateButton 
          onclick={()=>history("receivedfromsellerpage")} 
          name='Order Check' 
          color={'bg-dbase'}/>
          
          <NavigateButton 
          onclick={()=>history("updatebin")} 
          name='Update Bin' 
          color={'bg-Orangeflo'}/>
                 </div>
     
      </div>
    </>
  );
};
export default hocStarredPage(RecivedFromSellerv2);
