import { useReducer, createContext } from "react"
import { TabBarReducer, initialState } from "../reducers/tabBarReducer"

export const TabBarContext = createContext({
    state: initialState,
    dispatch: () => null
})

export const TabBarProvider = ({ children }) => {
    const [state, dispatch] = useReducer(TabBarReducer, initialState)

    return (
        <TabBarContext.Provider value={[state, dispatch]}>
            {children}
        </TabBarContext.Provider>
    )
}