import React, { useContext, useEffect, useRef, useState } from "react";
import { FaArrowLeft, FaCheckCircle, FaSearch, FaTrash } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";

import buildLink from "../../urls";
import axios from "axios";
import { IoIosWarning } from "react-icons/io";
import { CompanyContext } from "../../contexts/companyCotext";
import MessageAlert from "../../components/messageAlert";
import Cookies from "js-cookie";

const OrderProductList = () => {
  const orderId = useRef(null);
  const [loading, setLoading] = useState(false);
  const [showMessgErr, setShowMessageErr] = useState(false);
  const [canComplete, setCanComplet] = useState(false);
  const [message, setMessage] = useState("");
  const [showMessgSucc, setShowMessageSucc] = useState(false);
  const location = useLocation();
  const [productsList, setProductsList] = useState([]);
  const [checkedProducts, setCheckedProducts] = useState(0);
  const [stateCom, dispatchCom] = useContext(CompanyContext);

  useEffect(() => {
    setProductsList([]);
    setCheckedProducts(0);
    location.state.products.forEach((product) => {
      // Creating a new product object with the desired properties
      const newProduct = {
        product_id_with_option_value_id:
        product.product_id_with_option_value_id,
        sku: product.sku,
        barcode: product.barcode,
        isChecked: false,
        image: product.image,
      };

      // Updating the state by spreading the existing products and adding the new product
      setProductsList((prevProducts) => [...prevProducts, newProduct]);
    });
  }, [location.pathname]);

  const history = useNavigate();

  const checkCode = (e) => {
    e.preventDefault();

    const updatedProductsList = productsList.map((product) => {
      // Check if the code is 34
      if (product.product_id_with_option_value_id === orderId.current.value) {
        setCheckedProducts(checkedProducts + 1);

        // Update the isChecked property to true
        return { ...product, isChecked: true };
      }
      // No change needed for other products
      return product;
    });
    orderId.current.value = "";
    setProductsList(updatedProductsList);
  };
  useEffect(() => {
    if (checkedProducts === productsList.length && productsList.length > 0) {
      setCanComplet(true);
    } else {
      setCanComplet(false);
    }
  }, [checkedProducts]);
  
  const getUserIdFromLocalStorage = () => {
    return localStorage.getItem("userID");
  };

  async function completeOrder() {
    const userID = getUserIdFromLocalStorage()
    const tempOrderStatus = location.state.orederStatus;
    console.log(checkedProducts);
    if (checkedProducts === productsList.length) {
      setLoading(true);
      var url = "";

      if (tempOrderStatus == "normal") {
        url = buildLink("updateOrder");
      } else {
        url = buildLink("updateReturnOrder");
      }

      const obj = {
        logistic_id: location.state.logistic,
        user_id: userID,
        type: "ready",
      };

      if (tempOrderStatus === "return") {
        obj.return_order_id = location.state.order_id; // replace with the actual return_order_id value
      } else if (tempOrderStatus === "normal") {
        obj.order_id = location.state.order_id; // replace with the actual order_id value
      }
      try {
        await axios.put(url, obj).then((response) => {
          if (response.data.success) {
            setMessage(response.data.message);
            setShowMessageSucc(true);
            setTimeout(() => {
              setShowMessageSucc(false);
            }, 3000);
          }
        });
      } catch (e) {
        setLoading(false);
        setMessage(e.response.data.message);
        setShowMessageErr(true);
        setTimeout(() => {
          setShowMessageErr(false);
        }, 3000);
      }
    }
  }

  return (
    <>
      <div className=" h-full ">
        {/* header */}
        <div className=" z-30 mb-5 sticky top-0 flex flex-col bg-white">
          <div className=" w-full bg-dbase h-16">
            <div className=" container flex relative gap-7 flex-row justify-start w-full text-white  h-full my-auto">
              <button onClick={() => history(-1)}>
                <FaArrowLeft />
              </button>
              <h2 className=" text-xl    my-auto">Order Product List</h2>
            </div>
          </div>
        </div>

        <div className=" container">
          <form
            onSubmit={(e) => {
              checkCode(e);
            }}
          >
            <div className=" flex flex-row justify-center gap-3 ">
              <div className="relative flex-1">
                <input
                  ref={orderId}
                  type="text"
                  className="border border-dlabelColor outline-dbase w-full rounded-md  py-3 pr-8  pl-2"
                  placeholder="Product ID Or Sku"
                />
                <div>
                  <FaTrash className="absolute top-1/2 transform -translate-y-1/2 right-3 cursor-pointer opacity-20 " />
                </div>
              </div>
              <button className=" bg-dbase rounded-md text-white px-5 ">
                {" "}
                <FaSearch />{" "}
              </button>
            </div>
          </form>

          <div className=" flex flex-col gap-2 mt-10">
            {productsList &&
              productsList.map((product) => {
                return (
                  <div className=" text-sm max-h-fit rounded-md shadow-md overflow-y-hidden container">
                    <div
                      className={`${
                        product.isChecked ? " bg-dgreen2" : "bg-white"
                      } px-5  w-full  py-2 `}
                    >
                      <div className=" flex flex-row gap-5">
                        <div className=" my-auto h-fit  w-16 aspect-square">
                          <img
                            src={product.image}
                            className="  object-cover"
                            alt=""
                          />
                        </div>
                        <div className=" my-auto">
                          <div className=" flex flex-col justify-center">
                            <h2> {product.product_id_with_option_value_id}</h2>
                            <h2> {product.sku}</h2>
                            <h2>Barcode:{product.barcode}</h2>
                            <h2 className=" text-Orangeflo">1</h2>
                            <h2 className=" text-dbase">0</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className=" container w-full  ">
            {canComplete && (
              <button
                onClick={() => completeOrder()}
                className=" w-full bg-dbase  px-3 py-3 text-white rounded-sm mt-3"
              >
                {" "}
                {loading ? "Loading..." : "Complete Order "}
              </button>
            )}
          </div>
        </div>
        <MessageAlert
          message={message}
          type={showMessgErr ? "err" : "succ"}
          showMessg={showMessgErr || (showMessgSucc && true)}
        />
      </div>
    </>
  );
};

export default OrderProductList;
