import React, { useContext, useEffect, useRef, useState } from "react";
import { FaArrowLeft, FaCheck, FaCheckCircle, FaSearch, FaTrash, FaUpload } from "react-icons/fa";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import buildLink from "../../../../urls";
import axios from "axios";
import MessageAlert from "../../../../components/messageAlert";
import { CompanyContext } from "../../../../contexts/companyCotext";
import hocStarredPage from "../../../../components/hocStarredPage";
import Cookies from "js-cookie";

const RecivedFromSellerPart2 = () => {
  const upcref = useRef(null);
  const itemRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [orderStatus, setOrderStatus] = useState("normal");
  const [message, setMessage] = useState("");
  const [showMessgErr, setShowMessageErr] = useState(false);
  const [stateCom,dispatchCom]= useContext(CompanyContext)
  const [showMessgSucc, setShowMessageSucc] = useState(false);
  const history = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const orderId = queryParams.get('orderId');



  useEffect(()=>{
console.log(orderId)
  },[orderId])
  const options = [
    { value: "normal", label: "Normal" },
    { value: "return", label: "Return" },
  ];

  const handleTrashClick = () => {
    upcref.current.value = "";
    upcref.current.focus();
  };

  const getUserIdFromLocalStorage = () => {
    return localStorage.getItem("userID");
  };

  const checkOrder = async (e) => {
    e.preventDefault();
    const userID = getUserIdFromLocalStorage()
    setLoading(true);
    const url = buildLink("updateOrder");
    const obj = {
        user_id: userID,
        upc_id:upcref.current.value,
        item:itemRef.current.value,
        
        type:"received_from_seller"


    }
    if(orderStatus == "normal"){
        obj.order_id= orderId
    }else{
        obj.return_order_id = orderId
    }
    try {
      await axios.put(url,obj).then((response) => {
        if(response.data.success){
         setMessage(response.data.message);
         setShowMessageSucc(true);
         setTimeout(() => {
            setShowMessageSucc(false);
         }, 3000);
        }else{
          setMessage(response.data.message);
          setShowMessageErr(true);
          setTimeout(() => {
            setShowMessageErr(false);
          }, 3000);
        }
    
      });
    } catch (e) {
      setMessage(e.response.data.message);
      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
    }
    setLoading(false);
  };

  useEffect(() => {
    upcref.current.focus();
  }, []);

  return (
    <>
      <div className="h-full bg-white">
        {/* header */}
        <div className=" mb-5 sticky top-0 flex flex-col bg-white">
          <div className=" w-full bg-dbase h-16">
            <div className=" container flex relative gap-7 flex-row justify-start w-full text-white  h-full my-auto">
              <button onClick={() => history(-1)}>
                <FaArrowLeft />
              </button>
              <h2 className=" text-xl my-auto">Received From Seller</h2>
            </div>
          </div>
        </div>
        <div className=" container">
          <form
            onSubmit={(e) => checkOrder(e)}
            className=" flex flex-col gap-3 justify-center"
          >
                <div className=" py-1">
              <Select
                placeholder="Order Status"
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderRadius: "7px",
                    borderColor: state.isFocused ? "grey" : "grey",
                    outline: "red",
                    padding: "8px 5px",
                  }),
                }}
                onChange={(e) => setOrderStatus(e.value)}
                options={options}
              />
            </div>
           
            <div className= " my-auto relative flex-1">
              <input
              required
                ref={upcref}
                type="text"
                className="border border-dlabelColor outline-dbase w-full rounded-md  py-3 pr-8  pl-2"
                placeholder="UPC"
              />
              <FaTrash
                className="absolute top-1/2 transform -translate-y-1/2 right-2 cursor-pointer opacity-20 "
                onClick={handleTrashClick}
              />
            </div>
            <div className= " my-auto relative flex-1">
              <input
              required
                ref={itemRef}
                type="text"
                className="border border-dlabelColor outline-dbase w-full rounded-md  py-3 pr-8  pl-2"
                placeholder="item"
              />
              <FaTrash
                className="absolute top-1/2 transform -translate-y-1/2 right-2 cursor-pointer opacity-20 "
                onClick={()=>{itemRef.current.value =''
            itemRef.current.focus()
            }}
              />
            </div>


            <button className=" w-full py-2 bg-dbase text-white rounded-md">{loading?"Loading...":"Update"}</button>
          
          </form>
        
        </div>
        <MessageAlert
          message={message}
          type={showMessgErr ? "err" : "succ"}
          showMessg={showMessgErr || (showMessgSucc && true)}
        />
      </div>
    </>
  );
};
export default hocStarredPage(RecivedFromSellerPart2);
