import React, { useContext, useEffect, useRef, useState } from "react";
import {
  FaArrowLeft,
  FaCheckCircle,
  FaSearch,
  FaTrash,
  FaTrashRestore,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import { axiosServer } from "../../../axiosServer";
import buildLink from "../../../urls";
import MessageAlert from "../../../components/messageAlert";
import { BsBookshelf } from "react-icons/bs";
import { CompanyContext } from "../../../contexts/companyCotext";
import Loader from "../../../components/loader";
import { IoMdRemoveCircleOutline } from "react-icons/io";
import Cookies from "js-cookie";
import { PlaySounds } from "../../../functions/playSounds";
import hocStarredPage from "../../../components/hocStarredPage";

const BinPage = () => {
  const { playErrorSound, playSuccessSound } = PlaySounds();
  const productId = useRef(null);
  const binId = useRef(null);
  const qty = useRef(null);
  const [products, setProducts] = useState(null);
  const [info, setInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showMessgErr, setShowMessageErr] = useState(false);
  const [message, setMessage] = useState("");
  const [showMessgSucc, setShowMessageSucc] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [action, setAction] = useState();
  const [Action, setaction] = useState();
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [start, setStart] = useState(false);
  const [assign, setAssign] = useState(false);
  const scanRef = useRef(null);
  const [stateCom, dispatchCom] = useContext(CompanyContext);
  const [counterOnScan, setcounterOnScan] = useState();
  const [requestSuccess, setRequestSuccess] = useState(false);
  const [successBackground, setSuccessBackground] =useState()
  async function getBinInfo(e) {
    e.preventDefault();
    const bin_id = binId.current.value;
    const product = productId.current.value;
    const quantity = qty.current.value;
    const obj = JSON.stringify({
      bin_id,
      product,
      quantity,
    });
    try {
      await axiosServer
        .post(buildLink("getBinInfo"), JSON.parse(obj))
        .then((response) => {
          // console.log(response);
          if (response.data.success) {
            const productInfo = response.data.data.product;
            setAction(response.data.data.action);
            setaction(response.data.data.action);
            // console.log(action);
            setInfo(productInfo);
          }
        });
      Cookies.set("productInfo", JSON.stringify(info), { expires: 7 });
      Cookies.set("action", action, { expires: 7 });
    } catch (e) {
      console.log(e);
      playErrorSound();
      setMessage(e.response.data.message);
      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
    }
  }


  const getUserIdFromLocalStorage = () => {
    return localStorage.getItem("userID");
  };

  async function assignProductToBin(e) {
    // if (response.data.data.counter === productId.current.value) {
    //   console.log("Counter is equal to productId. Do not make a new request.");
    // }
    e.preventDefault();
    const userID = getUserIdFromLocalStorage()

    if (Action === "count" && counterOnScan >= qty.current.value) {
      playSuccessSound();
      setMessage("Successfully Assigned To Bin");
      setSuccessBackground(true)
      setShowMessageSucc(true);
      setTimeout(() => {
        setShowMessageSucc(false);
      }, 3000);
    } else {
      const getcookies = Cookies.get("productInfo");
      const savedData = JSON.parse(getcookies);
      // console.log(savedData);

      const user_id = userID;
      const new_bin_id = binId.current.value;
      const upc = savedData.upc;
      const big_upc = savedData.big_upc;
      const bin_id = savedData.bin_id;
      const quantity = savedData.quantity;
      const action = Action;
      const counter = counterOnScan || 0;

      const obj = {
        user_id,
        new_bin_id,
        upc,
        big_upc,
        bin_id,
        quantity,
        action,
        counter,
      };

      if (typeof info === "object") {
        if (info.product_option_value_id === "") {
          obj.id = info.product_id;
          obj.type = "product";
        } else {
          obj.id = info.product_option_value_id;
          obj.type = "option";
        }
      }
      if (Action === "count") {
        obj.quantity = "1";
      } else {
        obj.quantity = qty.current.value;
      }
      // if (Action === "assign") {
      //   obj.quantity = quantity.current.value;
      // }

      try {
        await axiosServer
          .post(buildLink("assignProductToBin"), obj)
          .then((response) => {
            // console.log(response);
            if (response.data.success) {
              setcounterOnScan(response.data.data.counter);
              setRequestSuccess(true);

              // console.log("This is the success response", response);
            }
          });
      } catch (e) {
        setMessage(e.response.message);
        setShowMessageErr(true);
        setTimeout(() => {
          setShowMessageErr(false);
        }, 3000);
        console.log(e);
        setRequestSuccess(false);
      }
    }
  }

  function DisplayProductInfo({ productInfo }) {
    return (
      <div>        
            {Object.entries(productInfo).map(([key, value]) => {
              if (value && ["popup", "thumb", "mobile_image"].includes(key)) {
                return null; // don't show the value of them
              } else if (value) {
                return (
                  <div key={key}>
                    <li className=" px-2 py-1">
                      <strong>{key}: </strong>{value}
                    </li>
                    {/* <li className="border border-dlabelColor px-2 py-1">
                      {value}
                    </li> */}
                  </div>
                );
              } else {
                return null;
              }
            })}
      </div>
    );
  }

  // async function getBinProducts(e) {
  //   e.preventDefault();
  //   await globalGetBinProducts();
  // }

  // async function globalGetBinProducts() {
  //   const url = buildLink("getBinProducts");
  //   try {
  //     await axiosServer
  //       .get(url + `&bin_id=${binId.current.value}`)
  //       .then((res) => {
  //         setLoading(false);
  //         if (res.data.success) {
  //           setProducts(res.data.products);
  //         }
  //       });
  //   } catch (e) {
  //     setLoading(false);
  //     setMessage(e.response.data.message);
  //     setShowMessageErr(true);
  //     setTimeout(() => {
  //       setShowMessageErr(false);
  //     }, 3000);
  //   }
  // }

  // async function assignBin(e) {
  //   e.preventDefault();
  //   const url = buildLink("assignBin");
  //   const obj = {
  //     bin_id: binId.current.value,
  //     product: productId.current.value,
  //   };
  //   try {
  //     await axiosServer.post(url, obj).then((res) => {
  //       setLoading(false);
  //       if (res.data.success) {
  //         setMessage(res.data.message);
  //         setShowMessageSucc(true);
  //         setTimeout(() => {
  //           setShowMessageSucc(false);
  //         }, 3000);
  //       }
  //     });
  //   } catch (e) {
  //     setLoading(false);
  //     setMessage(e.response.data.message);
  //     setShowMessageErr(true);
  //     setTimeout(() => {
  //       setShowMessageErr(false);
  //     }, 3000);
  //   }
  // }

  async function unassignfromBin(e) {
    e.preventDefault();
    const url = buildLink("unassignProductFromBin");
    const obj = {
      bin_id: binId.current.value,
      product: productId.current.value,
    };

    try {
      axiosServer.post(url, obj).then((res) => {
        // console.log(res);
        if (res.data.success) {
          playSuccessSound();
          // globalGetBinProducts();
          setMessage(res.data.message);
          setShowMessageSucc(true);
          setTimeout(() => {
            setShowMessageSucc(false);
          }, 3000);
        }
      });
    } catch (e) {
      playErrorSound();
      console.log(e);
      setLoading(false);
      setMessage(e.response.data.message);
      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
    }
  }

  const history = useNavigate();

  useEffect(() => {
    binId.current.focus();
  }, []);

  useEffect(() => {
    Cookies.set("productInfo", JSON.stringify(info), { expires: 7 });
    Cookies.set("action", action, { expires: 7 });
    // console.log(Cookies);
  }, [info, action]);

  const handleTrashClick = () => {
    binId.current.value = "";
    binId.current.focus();
  };

  // useEffect(() => {
  //   if (start) {
  //     scanRef.current.focus();
  //     scanRef.current.setSelectionRange(
  //       scanRef.current.value.length,
  //       scanRef.current.value.length
  //     );
  //   }
  // }, [start]);

  return (
    <>
      <div className=" h-full bg-white">
        {/* header */}
        <div className=" mb-5 sticky top-0  z-30 flex flex-col bg-white">
          <div className=" w-full bg-dbase h-16">
            <div className=" container flex relative gap-7 flex-row justify-start w-full text-white  h-full my-auto">
              <button onClick={() => history(-1)}>
                <FaArrowLeft />
              </button>
              <h2 className=" text-xl my-auto">Bin</h2>
            </div>
          </div>
        </div>

        <div className=" w-screen  bg-white my-4 container ">
          <div className=" flex w-full justify-center gap-2">
            <button
              onClick={() => setActiveTab(1)}
              className={`${
                activeTab === 1 ? "bg-dbase text-white" : "bg-white text-dblack"
              }  transition-all w-full py-2  rounded-full`}
            >
              {" "}
              Assign to bin
            </button>
            <button
              onClick={() => setActiveTab(2)}
              className={`${
                activeTab === 2 ? "bg-dbase text-white" : "bg-white text-dblack"
              }  transition-all w-full py-2 rounded-full`}
            >
              {" "}
              Unassaign from bin
            </button>
          </div>
        </div>
        {activeTab === 1 && (
          <>
            <div className="container">
              <form onSubmit={(e) => getBinInfo(e)}>
                <div className=" flex flex-row justify-center gap-3 ">
                  <div className="relative flex-1">
                    <input
                      required
                      ref={binId}
                      type="text"
                      className="border border-dlabelColor outline-dbase w-full rounded-md  py-3 pr-8  pl-2"
                      placeholder="Bin ID"
                    />
                    <div>
                      <FaTrash
                        onClick={() => handleTrashClick()}
                        className="absolute top-1/2 transform -translate-y-1/2 right-3 cursor-pointer opacity-20 "
                      />
                    </div>
                  </div>
                  {/* <button
                    type="submit"
                    className=" bg-dbase rounded-md text-white px-5 "
                  >
                    {" "}
                    {loadingSearch ? <Loader /> : <FaSearch />}{" "}
                  </button> */}
                </div>
                <div className=" flex flex-row justify-center gap-3  mt-5">
                  <div className="relative flex-1">
                    <input
                      required
                      ref={productId}
                      type="text"
                      className="border border-dlabelColor outline-dbase w-full rounded-md  py-3 pr-8  pl-2"
                      placeholder="Product ID or SKU"
                    />
                    <div onClick={() => (productId.current.value = "")}>
                      <FaTrash className="absolute top-1/2 transform -translate-y-1/2 right-3 cursor-pointer opacity-20 " />
                    </div>
                  </div>
                  {/* <button
                    type="submit"
                    className=" bg-dbase rounded-md text-white px-5 "
                  >
                    {" "}
                    <BsBookshelf />
                  </button> */}
                </div>
                <div className=" flex flex-row justify-center gap-3  mt-5">
                  <div className="relative flex-1">
                    <input
                      required
                      ref={qty}
                      type="text"
                      className="border border-dlabelColor outline-dbase w-full rounded-md  py-3 pr-8  pl-2"
                      placeholder="Quantity"
                    />
                    <div onClick={() => (productId.current.value = "")}>
                      <FaTrash className="absolute top-1/2 transform -translate-y-1/2 right-3 cursor-pointer opacity-20 " />
                    </div>
                  </div>
                  <button
                    type="submit"
                    className=" bg-dbase rounded-md text-white px-5 "
                  >
                    {" "}
                    {loadingSearch ? <Loader /> : <FaSearch />}{" "}
                  </button>
                </div>
              </form>
              {info && (
             <div className={`flex container flex-row  mt-4 border rounded-md ${start ? 'bg-Energyplus bg-opacity-60' : ''} ${successBackground ? 'bg-dgreen2' : ''}`}>
                {info && <DisplayProductInfo productInfo={info} />}

                {info && (
                  <div className="object-cover h-fit w-fit rounded-md m-auto container">
                    {info.mobile_image && <img src={info.mobile_image} />}
                    {!info.mobile_image && info.popup && (
                      <img src={info.popup} />
                    )}
                    {!info.mobile_image && !info.popup && info.thumb && (
                      <img src={info.thumb} />
                    )}
                  </div>
                )}
              </div>)}
            </div>
            <div>
              {!start && action && action === "count" && (
                <button
                  onClick={() => setStart(true) && scanRef.current.focus()}
                  className="bg-dbase border border-dblack container rounded-md p-3 text-white mt-3"
                >
                  Start
                </button>
              )}
              <form
                onSubmit={(e) => assignProductToBin(e)}
                className="container"
              >
                {start && action && action === "count" && (
                  <div className="flex flex-row border-dblack rounded-lg text-center max-w-sm mx-auto container ">
                    <input
                      ref={scanRef}
                      type="text"
                      // value={yourValue}
                      // onChange={(e) => handleChange(e)}
                      autoFocus
                      className="border border-dblack rounded-lg block p-2.5 max-w-sm mx-auto mt-4 text-center w-full"
                      placeholder="Scan Item"
                      required
                    />
                    {/* <button className="border border-dblack bg-dbase rounded-lg block max-w-sm mx-auto text-white px-5 ">
                      {" "}
                      {loading ? <Loader /> : <FaSearch />}{" "}
                    </button> */}
                  </div>
                )}
              </form>

              {action && action === "assign" && (
                <button
                  onClick={(e) => assignProductToBin(e)}
                  className={`bg-dbase border border-dblack container rounded-md p-3 text-white mt-3 ${
                    requestSuccess ? "disabled" : ""
                  }`}
                  disabled={requestSuccess}
                >
                  Assign
                </button>
              )}
              {start && (
                <div class="  flex flex-col max-w-sm mx-auto mt-5">
                  <label className=" mb-1 container text-center h-fit  bg-white font-bold text-dbase border rounded-md w-fit ">
                    Counter:
                  </label>
                  <input
                    className="border-2 border-dblack rounded-lg block w-full p-2.5 text-center text-2xl"
                    placeholder="0"
                    readOnly={true}
                    value={counterOnScan}
                  />
                </div>
              )}
            </div>
          </>
        )}
        {activeTab === 2 && (
          <>
            <div className="container">
              <form>
                <div className=" flex flex-row justify-center gap-3 ">
                  <div className="relative flex-1">
                    <input
                      ref={binId}
                      type="text"
                      className="border border-dlabelColor outline-dbase w-full rounded-md  py-3 pr-8  pl-2"
                      placeholder="Bin ID"
                    />
                    <div>
                      <FaTrash
                        onClick={() => handleTrashClick()}
                        className="absolute top-1/2 transform -translate-y-1/2 right-3 cursor-pointer opacity-20 "
                      />
                    </div>
                  </div>
                  <button
                    type="submit"
                    className=" bg-dbase rounded-md text-white px-5 "
                  >
                    {" "}
                    {loadingSearch ? <Loader /> : <FaSearch />}{" "}
                  </button>
                </div>
              </form>
              <form onSubmit={(e) => unassignfromBin(e)}>
                <div className=" flex flex-row justify-center gap-3  mt-5">
                  <div className="relative flex-1">
                    <input
                      ref={productId}
                      type="text"
                      className="border border-dlabelColor outline-dbase w-full rounded-md  py-3 pr-8  pl-2"
                      placeholder="Product ID or SKU"
                    />
                    <div onClick={() => (productId.current.value = "")}>
                      <FaTrash className="absolute top-1/2 transform -translate-y-1/2 right-3 cursor-pointer opacity-20 " />
                    </div>
                  </div>
                  <button
                    type="submit"
                    className=" bg-dbase rounded-md text-white px-5 "
                  >
                    {" "}
                    <IoMdRemoveCircleOutline />
                  </button>
                </div>
              </form>
            </div>
          </>
        )}
        <div className="container mt-4  px-3 gap-1 flex-col  flex rounded-md ">
          {products &&
            products.map((product) => {
              return (
                <div className="container  border rounded-md border-dlabelColor flex flex-row">
                  <div>
                    <img className="  w-32" src={product.image}></img>
                  </div>
                  <div className=" flex p-3 flex-col text-start gap-1">
                    <p>
                      <strong>Sku: </strong>
                      {product.sku}
                    </p>
                    <p>
                      <strong>Barcode: </strong>
                      {product.product_id}
                    </p>
                    <p>
                      <strong>Quantity: </strong>
                      {product.quantity}
                    </p>
                  </div>
                </div>
              );
            })}
        </div>

        <MessageAlert
          message={message}
          type={showMessgErr ? "err" : "succ"}
          showMessg={showMessgErr || (showMessgSucc && true)}
        />
      </div>
    </>
  );
};

export default hocStarredPage(BinPage);
