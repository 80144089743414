import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  FaArrowLeft,
  FaCheck,
  FaSpinner,
  FaTrash,
} from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import buildLink from "../../../urls";
import { format } from "date-fns";
import { CompanyContext } from "../../../contexts/companyCotext";
import MessageAlert from "../../../components/messageAlert";
import { PlaySounds } from "../../../functions/playSounds";

const ReceiveTransferScan = () => {
  const { playErrorSound, playSuccessSound } = PlaySounds();
  const history = useNavigate();
  const [products, setProducts] = useState([]);
  const scannedItemRef = useRef(null);
  const [showMessgSucc, setShowMessageSucc] = useState(false);
  const [message, setMessage] = useState("");
  const [errorProcuts,setErrorProcuts] = useState(false)
  const [loading,setLoading] = useState(false)
  const [showMessgErr, setShowMessageErr] = useState(false);
  const [stateCom, dispatchCom] = useContext(CompanyContext);
  const { id } = useParams();
  const getUserIdFromLocalStorage = () => {
    return localStorage.getItem("userID");
  };

  const handleAddProducts = (e) => {
    if(errorProcuts){
      setProducts([])
      setErrorProcuts(false)
    }
    e.preventDefault();
    const currentDate = new Date();
    const formattedDate = format(currentDate, "yyyy-MM-dd HH:mm:ss");
    const currDate = formattedDate;
    const item = scannedItemRef.current.value;

    // Check if the item already exists in products state
    const existingProductIndex = products.findIndex((p) => p.item === item);
    if (existingProductIndex !== -1) {
      // If the item exists, update its count
      const updatedProducts = [...products];
      updatedProducts[existingProductIndex].count++;
      setProducts(updatedProducts);
    } else {
      // If the item doesn't exist, add it to the state with count 1
      setProducts(prevProducts => [...prevProducts, { item, count: 1 }]);
    }

    scannedItemRef.current.value = "";
  };



  // useEffect(()=>{
  //   const productsList = products.map((pr) => ({ item: pr.item, quantity: pr.count }));
  //   console.log(productsList)
  // },[products])

  async function pickTransferProducts() {

    const userID = getUserIdFromLocalStorage();
    const currentDate = new Date();
    const formattedDate = format(currentDate, "yyyy-MM-dd HH:mm:ss");
    const productsList = products.map((pr) => ({ item: pr.item, quantity: pr.count }));
    const url = buildLink("pickTransferProducts");
    const obj = {
      transfer_id: id,
      user_id: userID,
      date: formattedDate,
      products: productsList,
    };

    try {
      setLoading(true)
      await axios.put(url, obj).then((res) => {
        if (res.data.success) {
          setLoading(false)
          setErrorProcuts(false)
          playSuccessSound();
        setMessage(res.data.message);
        setShowMessageSucc(true);
        setTimeout(() => {
          setShowMessageSucc(false);
          history(-1)
        }, 2000);
         
        }
        
      });
    } catch (e) {
      setLoading(false)
      if(e.response.data.not_exist_product){
        console.log(e.response.data.not_exist_product)
        setProducts(e.response.data.not_exist_product)
        setErrorProcuts(true)
      }
      
      playErrorSound();
      console.log(e);
      setMessage(e.response.data.message);
      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
    }
  }

  useEffect(() => {
    scannedItemRef.current.focus();
  }, []);

  useEffect(()=>{
    console.log(products)
  },[products])

  const handleTrashClick = () => {
    scannedItemRef.current.value = "";
    scannedItemRef.current.focus();
  };

  return (
    <>
      <div className=" h-full ">
        {/* header */}
        <div className=" mb-5 sticky top-0 flex flex-col bg-white">
          <div className=" w-full bg-dbase h-16">
            <div className=" container px-3  relative flex justify-between w-full text-white  h-full my-auto">
              <div className=" flex  gap-7 flex-row justify-start">
                <button onClick={() => history(-1)}>
                  <FaArrowLeft />
                </button>
                <h2 className=" text-xl    my-auto">Receive Transfer Scan</h2>
              </div>
              <div className=" flex flex-row justify-end text-end gap-10 "></div>
            </div>
          </div>
        </div>
        <div className="container ">
          <div className=" flex flex-row justify-center gap-3 ">
            <div className="relative flex-1">
              <form onSubmit={(e) => handleAddProducts(e)}>
                <input
                disabled={errorProcuts}
                  ref={scannedItemRef}
                  type="text"
                  className="border border-dlabelColor outline-dbase w-full rounded-md  py-3 pr-8  pl-2"
                  placeholder="Scan"
                  required
                />
              </form>
              <div onClick={()=>{handleTrashClick()}}>
                <FaTrash className="absolute top-1/2 transform -translate-y-1/2 right-3 cursor-pointer opacity-20 " />
              </div>
            </div>

            <button onClick={()=>{pickTransferProducts()}} className=" bg-dbase rounded-md text-white px-5 ">
              {" "}
              {
                loading?
                <FaSpinner className="animate-spin text-white" />:
                <FaCheck />
              }
            
            </button>
          </div>

          <div className=" mt-5">
            <table className="min-w-full divide-y border border-dplaceHolder divide-dgrey  ">
              <thead className="bg-dgrey">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3  text-xs font-medium text-dblack uppercase tracking-wider "
                  >
                    Scanned Item
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3  text-xs font-medium text-dblack uppercase tracking-wider"
                  >
                    Count
                  </th>
                </tr>
              </thead>
              <tbody>
                {products &&
                  products.map((product, i) => {
                    return (
                      <tr className="  border-b  border-dplaceHolder" key={i}>
                        <td className=" py-2 border-r border-dplaceHolder  text-center w-fit mx-auto">
                          {product.item}
                        </td>
                        <td className={`${errorProcuts?" text-dbase":"text-dblack"} py-2 border-r border-dplaceHolder text-center w-fit mx-auto`}>
                          {product.count}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <MessageAlert
        message={message}
        type={showMessgErr ? "err" : "succ"}
        showMessg={showMessgErr || (showMessgSucc && true)}
      />
    </>
  );
};

export default ReceiveTransferScan;
