import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import NavigateButton from "../../../components/buttonsNavigate";
import { TbSettingsExclamation, TbSettingsStar } from "react-icons/tb";
import { MdRestorePage } from "react-icons/md";
import hocStarredPage from "../../../components/hocStarredPage";

const Control = () => {
  const history = useNavigate();
  return (
    <>
      <div className=" h-full bg-white">
        <div className=" mb-5 sticky top-0 flex flex-col bg-white">
          {/* header */}

          <div className=" w-full bg-dbase h-16">
            <div className=" container flex relative gap-7 flex-row justify-start w-full text-white  h-full my-auto">
              <button onClick={() => history(-1)}>
                <FaArrowLeft />
              </button>
              <h2 className=" text-xl    my-auto">Control</h2>
            </div>
          </div>
        </div>

        <div className="  grid grid-cols-2 container gap-x-6 gap-y-4">
          <NavigateButton
            name={"DEFECT"}
            color={"bg-dbase"}
            icon={<TbSettingsExclamation className=" text-3xl" />}
            onclick={() => history("defect")}
          />
          <NavigateButton
            name={"Maintenance"}
            icon={<TbSettingsStar className=" text-3xl" />}
            color={"bg-[rgb(96,165,250)]"}
            onclick={() => history("maintenance")}
          />
          <NavigateButton
            name={"Restock"}
            icon={<MdRestorePage className=" text-3xl" />}
            color={"bg-[rgb(5,150,105)]"}
            onclick={() => history("restock")}
          />
        </div>
      </div>
    </>
  );
};

export default hocStarredPage(Control);
