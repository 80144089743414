import React from "react";
import {
  FaArrowLeft,
  FaBox,
  FaResolving,
  FaSearch,
  FaSyncAlt,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import NavigateButton from "../../components/buttonsNavigate";
import { IoTrashBin } from "react-icons/io5";
import hocStarredPage from "../../components/hocStarredPage";

const StockToEntry = () => {
  const history = useNavigate();
  return (
    <>
      <div className=" h-full bg-white">
        {/* header */}
        <div className=" mb-5 sticky top-0 flex flex-col bg-white">
          <div className=" w-full bg-dbase h-16">
            <div className=" container flex relative gap-7 flex-row justify-start w-full text-white  h-full my-auto">
              <button onClick={() => history(-1)}>
                <FaArrowLeft />
              </button>
              <h2 className=" text-xl    my-auto">Stock To Entry</h2>
            </div>
          </div>
        </div>

        <div className="  grid grid-cols-2 container gap-x-6 gap-y-4">
          <NavigateButton
            name={"GET ORDER"}
            icon={<FaSearch />}
            color={"bg-dbase"}
            onclick={() => history("getorder")}
          />
          <NavigateButton
            name={"CHANGE PRODUCT STATUS"}
            icon={<FaSyncAlt />}
            color={"bg-[rgb(14,165,233)]"}
            onclick={() => history("changeproductstatus")}
          />
          <NavigateButton
            icon={<FaBox />}
            name={"PALLET"}
            arname={" "}
            color={"bg-[rgb(204,204,0)]"}
            onclick={() => history("pallet")}
          />
           <NavigateButton
            icon={<IoTrashBin />}
            name={"Bin"}
            arname={" "}
            color={"bg-dblack"}
            onclick={() => history("bin")}
          />
        </div>
      </div>
    </>
  );
};

export default hocStarredPage(StockToEntry);
