import React, { useContext, useEffect, useRef, useState } from "react";
import { FaArrowLeft, FaCheckCircle, FaSearch, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import buildLink from "../../../urls";
import { CompanyContext } from "../../../contexts/companyCotext";
import { IoIosWarning } from "react-icons/io";
import Loader from "../../../components/loader";
import MessageAlert from "../../../components/messageAlert";
import { PlaySounds } from "../../../functions/playSounds";
import hocStarredPage from "../../../components/hocStarredPage";
import Cookies from "js-cookie";
import Select from "react-select";

const ChangeUpcWarehouse = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [loading, setLoading] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [productInf, setProductInf] = useState(null);
  const [stateCom, dispatchCom] = useContext(CompanyContext);
  const oldUPC = useRef(null);
  const newUPC = useRef(null);
  const [showMessgErr, setShowMessageErr] = useState(false);
  const [message, setMessage] = useState("");
  const [showMessgSucc, setShowMessageSucc] = useState(false);

  const productID = useRef(null);
  const { playErrorSound, playSuccessSound } = PlaySounds();

  useEffect(() => {
    if (activeTab == 2) {
      oldUPC.current.focus();
    } else {
      productID.current.focus();
    }
  }, [activeTab]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  // searchProduct
  const searchProduct = async (e) => {
    e.preventDefault();
    setLoadingSearch(true);
    const item = productID.current.value;
    const warehouseID = selectedWarehouseId; // Get the warehouse ID from the state

    try {
      const url =
        buildLink("productInfo") +
        `&item=${item}&v2=true` +
        `&user_id=${stateCom.userID}` +
        `&warehouse_id=${warehouseID}`;

      await axios.get(url).then((response) => {
        if (response.data.success) {
          oldUPC.current.value = response.data.data.upc;
          setProductInf(response.data.data);
          newUPC.current.focus();
          setMessage(e.response.data.message);
          setShowMessageSucc(true);
          setTimeout(() => {
            setShowMessageSucc(false);
          }, 3000);
        }
      });
      setLoadingSearch(false);
    } catch (e) {
      playErrorSound();
      setLoadingSearch(false);
      setMessage(e.response.data.message);
      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
    }
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  async function changeShelfUpc(e) {
    e.preventDefault();
    setLoading(true);
    const new_upc = newUPC.current.value;
    const old_upc = oldUPC.current.value;

    const obj = JSON.stringify({
      old_upc,
      new_upc,
    });

    try {
      const url = buildLink("updateUPC") + `&v2=true`;
      await axios.put(url, JSON.parse(obj)).then((response) => {
        if (response.data.success) {
          playSuccessSound();
          setMessage(response.data.message);
          setShowMessageSucc(true);
          setTimeout(() => {
            setShowMessageSucc(false);
          }, 3000);
          oldUPC.current.value = "";
          newUPC.current.value = "";
        }
      });
    } catch (e) {
      playErrorSound();
      setMessage(e.response.data.message);
      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
    }
    setLoading(false);
  }
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const getUserIdFromLocalStorage = () => {
    return localStorage.getItem("userID");
  };

  const [warehouses, setWarehouses] = useState([]);

  const [loadingWarehouses, setLoadingWarehouses] = useState(true);
  const [error, setError] = useState(null);
  const [selectedWarehouseId, setSelectedWarehouseId] = useState(null);

  const handleWarehouseChange = (selectedOption) => {
    setSelectedWarehouseId(selectedOption ? selectedOption.value : null);
  };

  const fetchfromwarehouse = async () => {
    try {
      const url = buildLink(`fetchfromwarehouse`);
      const response = await axios.get(url);

      if (response.data.success) {
        setWarehouses(response.data.data || []);
        setLoadingWarehouses(false);
      } else {
        setError("Failed to fetch warehouses.");
      }
    } catch (err) {
      setError("Failed to fetch warehouses.");
    } finally {
      setLoadingWarehouses(false);
    }
  };

  useEffect(() => {
    fetchfromwarehouse();
  }, []);

  async function changeUpc(e) {
    e.preventDefault();
    const userID = getUserIdFromLocalStorage();
    setLoading(true);
    const product_id = productID.current.value;
    const upc = newUPC.current.value;
    const user_id = userID;

    const obj = JSON.stringify({
      product_id,
      upc,
      user_id,
      warehouse_id: selectedWarehouseId,
    });

    try {
      const url = buildLink("updateUPC") + `&v2=true`;
      await axios.put(url, JSON.parse(obj)).then((response) => {
        if (response.data && response.data.success) {
          playSuccessSound();
          setMessage(response.data.message);
          setShowMessageSucc(true);
          setTimeout(() => {
            setShowMessageSucc(false);
          }, 3000);
          oldUPC.current.value = "";
          newUPC.current.value = "";
          productID.current.value = "";
        }
      });
    } catch (e) {
      playErrorSound();
      setMessage(
        e.response ? e.response.data.message : "Unknown error occurred"
      );
      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
    }
    setLoading(false);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const history = useNavigate();

  const handleTrashClick = () => {
    productID.current.value = "";
    productID.current.focus();
  };

  return (
    <>
      <div className=" h-full bg-white">
        {/* header */}
        <div className=" mb-5 sticky top-0 flex flex-col bg-white z-50">
          <div className=" w-full bg-dbase h-16">
            <div className=" container flex relative gap-7 flex-row justify-start w-full text-white  h-full my-auto">
              <button onClick={() => history(-1)}>
                <FaArrowLeft />
              </button>
              <h2 className=" text-xl my-auto">Change Upc</h2>
            </div>
          </div>
        </div>

        <div className=" w-screen  bg-white my-4 container ">
          <div className=" flex w-full justify-center gap-2">
            <button
              onClick={() => setActiveTab(1)}
              className={`${
                activeTab === 1 ? "bg-dbase text-white" : "bg-white text-dblack"
              }  transition-all w-full py-2  rounded-full`}
            >
              {" "}
              Single Product
            </button>
            <button
              onClick={() => setActiveTab(2)}
              className={`${
                activeTab === 2 ? "bg-dbase text-white" : "bg-white text-dblack"
              }  transition-all w-full py-2 rounded-full`}
            >
              {" "}
              Shelf Products
            </button>
          </div>
        </div>
        {activeTab === 1 && (
          <>
            <div className="container">
              <form onSubmit={(e) => searchProduct(e)}>
                <div className="flex flex-col justify-center gap-3">
                  {/* Product ID or SKU field */}
                  <div className="relative">
                    <input
                      ref={productID}
                      type="text"
                      className="border border-dlabelColor outline-dbase w-full rounded-md py-3 pr-8 pl-2"
                      placeholder="Product ID Or Sku"
                    />
                    <div>
                      <FaTrash
                        onClick={() => handleTrashClick()}
                        className="absolute top-1/2 transform -translate-y-1/2 right-3 cursor-pointer opacity-20"
                      />
                    </div>
                  </div>

                  {/* Warehouse Select and Button */}
                  <div className="flex flex-row gap-3 w-full">
                    {loadingWarehouses ? (
                      <div className="text-center text-dbase">
                        <Loader />
                      </div>
                    ) : error ? (
                      <p className="text-dbase">{error}</p>
                    ) : (
                      <Select
                        placeholder="Select Warehouse"
                        onChange={handleWarehouseChange}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderRadius: "7px",
                            borderColor: state.isFocused ? "grey" : "grey",
                            padding: "8px 5px",
                          }),
                        }}
                        options={
                          warehouses.length > 0
                            ? warehouses.map((warehouse) => ({
                                value: warehouse.warehouse_id,
                                label: warehouse.warehouse_name,
                              }))
                            : []
                        }
                        className="w-full" // Make select take full width
                      />
                    )}
                    <button className="bg-dbase rounded-md text-white px-5 py-3  flex items-center justify-center">
                      {loadingSearch ? <Loader /> : <FaSearch />}
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="gap-2 flex py-3 ml-3 mr-3"></div>
            <form
              onSubmit={(e) => changeUpc(e)}
              className="container relative flex-1"
            >
              <span>
                Old Upc
                <input
                  type="text"
                  className="border border-dlabelColor outline-dbase w-full rounded-md  py-2 pr-8 pl-2 mb-4"
                  placeholder="Old UPC"
                  ref={oldUPC}
                  readOnly
                />
              </span>
              <span>
                New Upc
                <input
                  ref={newUPC}
                  type="text"
                  className="border border-dlabelColor outline-dbase w-full rounded-md  py-2 pr-8  pl-2 mb-4"
                  placeholder="New UPC"
                  required
                />
              </span>

              <div className="w-full ">
                <button
                  type="submit"
                  className="w-full bg-dbase text-white p-2 rounded-md mt-4 shadow"
                >
                  {loading ? "Loading..." : "Change UPC"}
                </button>
              </div>
            </form>

            <div className="container rounded-md shadow w-full m-3">
              {productInf && (
                <div className="flex gap-10 p-4 mt-8">
                  <img
                    className="object-cover w-40 h-40"
                    src={productInf.image}
                    alt="Product Image"
                  />
                  <div className="flex flex-col mt-8">
                    <div className="my-2">
                      <span className="font-bold">SKU:</span> {productInf.sku}
                    </div>
                    <div className="my-2">
                      <span className="font-bold">Quantity:</span>{" "}
                      {`${productInf ? productInf.upc_quantity : ""}`}
                    </div>
                    <div>
                      <span className=" font-bold">
                        Address: {productInf.address}
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
        {activeTab === 2 && (
          <>
            <form
              onSubmit={(e) => changeShelfUpc(e)}
              className="container relative flex-1"
            >
              {loadingWarehouses ? (
                <div className="text-center text-dbase">
                  <Loader />
                </div>
              ) : error ? (
                <p className="text-dbase">{error}</p>
              ) : (
                <span>
                  Select Warehouse
                  <Select
                    placeholder="Select Warehouse"
                    onChange={handleWarehouseChange} // Added this line
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderRadius: "7px",
                        borderColor: state.isFocused ? "grey" : "grey",
                        padding: "8px 5px",
                        marginBottom: "15px",
                      }),
                    }}
                    options={
                      warehouses.length > 0
                        ? warehouses.map((warehouse) => ({
                            value: warehouse.warehouse_id,
                            label: warehouse.warehouse_name,
                          }))
                        : []
                    }
                  />
                </span>
              )}
              <span>
                Old Upc
                <input
                  required
                  type="text"
                  className="border border-dlabelColor outline-dbase w-full rounded-md  py-2 pr-8 pl-2 mb-4"
                  placeholder="Old UPC"
                  ref={oldUPC}
                />
              </span>
              New Upc
              <span>
                <input
                  required
                  ref={newUPC}
                  type="text"
                  className="border border-dlabelColor outline-dbase w-full rounded-md  py-2 pr-8  pl-2 mb-4"
                  placeholder="New UPC"
                />
              </span>
              <div className="w-full container">
                <button
                  type="submit"
                  className="w-full bg-dbase text-white p-2 rounded-md mt-4 shadow"
                >
                  {loading ? "Loading..." : "Change UPC"}
                </button>
              </div>
            </form>
          </>
        )}

        <MessageAlert
          message={message}
          type={showMessgErr ? "err" : "succ"}
          showMessg={showMessgErr || (showMessgSucc && true)}
        />
      </div>
    </>
  );
};
export default hocStarredPage(ChangeUpcWarehouse);
