import React, { useEffect, useRef, useState } from "react";
import { FaArrowLeft, FaCheckCircle, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import buildLink from "../../../urls";
import axios from "axios";
import { IoIosWarning } from "react-icons/io";
import MessageAlert from "../../../components/messageAlert";
import { PlaySounds } from "../../../functions/playSounds";
import hocStarredPage from "../../../components/hocStarredPage";

const OrderSearch = () => {

  const {playErrorSound,playSuccessSound} = PlaySounds();
  const orderId = useRef(null);
  const [loading, setLoading] = useState(false);
  const [orderStatus, setOrderStatus] = useState("normal");
  const [orderInf, setOrderInf] = useState(null);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [showMessgErr, setShowMessageErr] = useState(false);
  const [message, setMessage] = useState("");
  const [showMessgSucc, setShowMessageSucc] = useState(false);

  const searchOrder = async (e) => {
    e.preventDefault();
    setLoadingSearch(true);

    try {
      var url = "";
      if (orderStatus == "normal") {
        url = buildLink("orderInfo") + `&order_id=${orderId.current.value}`;
      } else if (orderStatus == "return") {
        url =
          buildLink("returnOrderInfo") +
          `&return_order_id=${orderId.current.value}`;
      }

      await axios.get(url).then((response) => {
        if (response.data.success) {
      playSuccessSound()
          setOrderInf(response.data.data);
        }
      });
      setLoadingSearch(false);
    } catch (e) {
      playErrorSound()
      setMessage(e.response.data.message);
      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
    }
    setLoadingSearch(false);
  };

  const history = useNavigate();
  const options = [
    { value: "normal", label: "Normal" },
    { value: "return", label: "Return" },
  ];

  useEffect(() => {
    orderId.current.focus();
  }, []);

  const handleTrashClick = () => {
    orderId.current.value = "";
    orderId.current.focus();
  };

  return (
    <>
      <div className=" h-full bg-white">
        {/* header */}
        <div className=" z-30 mb-5 sticky top-0 flex flex-col ">
          <div className=" w-full bg-dbase h-16">
            <div className=" container flex relative gap-7 flex-row justify-start w-full text-white  h-full my-auto">
              <button onClick={() => history(-1)}>
                <FaArrowLeft />
              </button>
              <h2 className=" text-xl    my-auto">Order Details</h2>
            </div>
          </div>
        </div>

        <div className=" container">
          <form
            onSubmit={(e) => searchOrder(e)}
            className=" flex flex-col gap-5 justify-center"
          >
            <div className="relative flex-1">
              <input
                ref={orderId}
                type="text"
                className="border border-dlabelColor outline-dbase w-full rounded-md  py-3 pr-8  pl-2"
                placeholder="Order Id"
              />
              <div>
                <FaTrash
                  onClick={() => handleTrashClick()}
                  className="absolute top-1/2 transform -translate-y-1/2 right-3 cursor-pointer opacity-20 "
                />
              </div>
            </div>
            <div className=" py-3">
              <Select
                placeholder="Order Status"
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderRadius: "7px",
                    borderColor: state.isFocused ? "grey" : "grey",
                    outline: "red",
                    padding: "8px 5px",
                  }),
                }}
                onChange={(e) => setOrderStatus(e.value)}
                options={options}
              />
            </div>

            <button
              type="submit"
              onClick={(e) => {}}
              className=" bg-dbase  px-3 py-3 text-white rounded-sm mt-3"
            >
              {" "}
              {loadingSearch ? "Loading..." : "Search Order"}
            </button>
          </form>
          {orderInf && (
            <div className="  overflow-auto flex flex-col gap-5 mt-5">
              <div className=" shadow-md rounded-md py-3 px-4 ">
                <div className="my-2 py-2 border-b border-dlabelColor">
                  <h2 className=" font-bold">Order Product</h2>
                </div>
                <table className="border-collapse border w-full  border-dlabelColor">
                  <thead>
                    <tr>
                      <th className="border  border-gray-400 p-2">Product</th>
                      <th className="border border-gray-400 p-2">SKU</th>
                      <th className="border border-gray-400 p-2">Price</th>
                      <th className="border border-gray-400 p-2">Qty </th>
                      <th className="border border-gray-400 p-2">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderInf.products.map((product)=><tr>
                      <td className="border  justify-center   border-gray-400 p-2">
                        <div className=" w-fit  mx-auto">
                          <img
                            className=" w-20"
                            src={product.image}
                            alt=""
                          />
                        </div>
                      </td>
                      <td className="border border-gray-400 p-2">
                        <div className=" w-fit  mx-auto">
                          {product.sku}
                        </div>
                      </td>
                      <td className="border border-gray-400 p-2">
                        <div className=" w-fit  mx-auto">
                          {product.price}
                        </div>
                      </td>
                      <td className="border border-gray-400 p-2">
                        <div className=" w-fit  mx-auto">
                          {product.quantity}
                        </div>
                      </td>
                      <td className="border border-gray-400 p-2">
                        <div className=" w-fit  mx-auto">
                          {product.total}
                        </div>
                      </td>
                      {/* Add more cells if needed */}
                    </tr>
                    )}
                  
                  </tbody>
                </table>
              </div>

              <div className=" shadow-md rounded-md py-3 px-4 ">
                <div className="my-2 py-2 border-b border-dlabelColor">
                  <h2 className=" font-bold">Order Info</h2>
                </div>
                <h3 className=" text-sm py-1 text-dlabelColor">
                  Order Status:{" "}
                  <span className="   pl-2 font-bold text-dblack">
                    {orderInf.order_status_name}
                  </span>
                </h3>
                <h3 className=" text-sm py-1 text-dlabelColor">
                  Track:{" "}
                  <span className="   pl-2 font-bold text-dblack">
                    {orderInf.track}
                  </span>
                </h3>
                <h3 className=" text-sm py-1 text-dlabelColor">
                  Logistic Id:{" "}
                  <span className="   pl-2 font-bold text-dblack">
                    {orderInf.logistic_id}
                  </span>
                </h3>
                <h3 className=" text-sm py-1 text-dlabelColor">
                  Order Total:{" "}
                  <span className="   pl-2 font-bold text-dblack">
                    {orderInf.total}
                  </span>
                </h3>
              </div>

              <div className=" shadow-md rounded-md py-3 px-4 ">
                <div className="my-2 py-2 border-b border-dlabelColor">
                  <h2 className=" font-bold">Order N#{orderInf.order_id}</h2>
                </div>
                <h3 className=" text-sm py-1 text-dlabelColor">
                  Name:{" "}
                  <span className="   pl-2 font-bold text-dblack">
                    {orderInf.shipping_firstname +
                      " " +
                      orderInf.shipping_lastname}
                  </span>
                </h3>
                <h3 className=" text-sm py-1 text-dlabelColor">
                  Phone number:{" "}
                  <span className="   pl-2 font-bold text-dblack">
                    {orderInf.telephone}
                  </span>
                </h3>
                <h3 className=" text-sm py-1 text-dlabelColor">
                  Email:{" "}
                  <span className="   pl-2 font-bold text-dblack">
                    {orderInf.email}
                  </span>
                </h3>
              </div>

              <div className="my-2 py-2 border-b border-dlabelColor">
                <h2 className=" font-bold">Shipping Adress</h2>
              </div>
              <h3 className=" text-sm py-1 text-dlabelColor">
                Name:{" "}
                <span className="   pl-2 font-bold text-dblack">
                  {orderInf.shipping_firstname +
                    " " +
                    orderInf.shipping_lastname}
                </span>
              </h3>
              <h3 className=" text-sm py-1 text-dlabelColor">
                Adress 1:{" "}
                <span className="   pl-2 font-bold text-dblack">
                  {orderInf.shipping_address_1}
                </span>
              </h3>
              <h3 className=" text-sm py-1 text-dlabelColor">
                Adress 2:{" "}
                <span className="   pl-2 font-bold text-dblack">
                  {orderInf.shipping_address_2}
                </span>
              </h3>
            </div>
          )}
        </div>

        <MessageAlert
          message={message}
          type={showMessgErr ? "err" : "succ"}
          showMessg={showMessgErr || (showMessgSucc && true)}
        />
      </div>
    </>
  );
};

export default hocStarredPage(OrderSearch);
