import React, { useRef, useState, useEffect } from "react";
import { FaArrowLeft, FaSearch, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Loader from "../../../components/loader";
import MessageAlert from "../../../components/messageAlert";
import { PlaySounds } from "../../../functions/playSounds";
import buildLink from "../../../urls";
import { useLocation } from 'react-router-dom';

const PAGE_SIZE = 5;

const RestockHistory = () => {
  const { playErrorSound } = PlaySounds();
  const history = useNavigate();
  const codePr = useRef(null);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [showMessgErr, setShowMessageErr] = useState(false);
  const [message, setMessage] = useState("");
  const [showMessgSucc, setShowMessageSucc] = useState(false);
  const [productHistory, setProductHistory] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const location = useLocation();
  const codePrValue = new URLSearchParams(location.search).get('codePr');

const getProductHistory = async (e) => {
    if (e) {
      e.preventDefault();
    }
  
    setLoadingSearch(true);
  
    const item = codePr.current.value;
  
    try {
      const url = buildLink("getProductHistory") + `&label=${item}`;
      const response = await axios.get(url);
  
      if (response.data.success) {
        codePr.current.focus();
        setProductHistory(response.data.data.histories);
        setTotalPages(response.data.data.total_histories / PAGE_SIZE);
      }
    } catch (error) {
      playErrorSound();
      setLoadingSearch(false);
      setMessage(error.response?.data?.message || "An error occurred");
      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
    }
  
    setLoadingSearch(false);
  };
  

  useEffect(() => {
    codePr.current.focus();
  }, []);

  const handleTrashClick = () => {
    codePr.current.value = "";
    codePr.current.focus();
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const renderProductHistory = () => {
    if (!productHistory) {
      return null;
    }

    const startIndex = (currentPage - 1) * PAGE_SIZE;
    const endIndex = Math.min(startIndex + PAGE_SIZE, productHistory.length);

    const productHistoryElements = [];
    for (let i = startIndex; i < endIndex; i++) {
      const ph = productHistory[i];
      const identifier = ph.upc_id ? "Product UPC" : "Product BIN";
      const value = ph.upc_id || ph.bin_id;

      const formattedDate = new Date(ph.date_added).toLocaleDateString(
        "en-US",
        {
          weekday: "short",
          month: "short",
          day: "numeric",
          year: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        }
      );

      productHistoryElements.push(
        <div className="container" key={ph.id}>
          <div className="bg-white rounded-md shadow-md w-full px-3 py-2 mt-10 border">
            <div className="flex flex-row gap-5">
              <div className="my-auto">
                <div className="flex flex-col justify-center m-1">
                  <h2 className="m-1">{`${identifier} : ${value}`}</h2>
                  <h2 className="m-1">SKU : {ph.sku}</h2>
                  <h2 className="m-1">{formattedDate}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return productHistoryElements;
  };

  useEffect(() => {
    if (codePrValue) {
      codePr.current.value = codePrValue;
      getProductHistory();
    }
  }, [codePrValue]);
  

  return (
    <>
      <div className="  h-full bg-white">
        <div className=" mb-5 sticky top-0 flex flex-col bg-white z-50">
          <div className=" w-full bg-dbase h-16">
            <div className=" container px-3  relative flex justify-between w-full text-white  h-full my-auto">
              <div className=" flex  gap-7 flex-row justify-start">
                <button onClick={() => history(-1)}>
                  <FaArrowLeft />
                </button>
                <h2 className=" text-xl    my-auto">Restock History</h2>
              </div>
            </div>
          </div>
        </div>

        <div className="container flex flex-col gap-5">
          <form onSubmit={(e) => getProductHistory(e)}>
            <div className=" flex flex-row justify-center gap-3 ">
              <div className="relative flex-1">
                <input
                  ref={codePr}
                  type="text"
                  className="border border-dlabelColor outline-dbase w-full rounded-md  py-3 pr-8  pl-2"
                  placeholder="Enter SKU"
                />
                <div>
                  <FaTrash
                    onClick={() => handleTrashClick()}
                    className="absolute top-1/2 transform -translate-y-1/2 right-3 cursor-pointer opacity-20 "
                  />
                </div>
              </div>
              <button className=" bg-dbase rounded-md text-white px-5 ">
                {" "}
                {loadingSearch ? <Loader /> : <FaSearch />}{" "}
              </button>
            </div>
          </form>
        </div>

        {loadingSearch ? (
         ''
        ) : (
          renderProductHistory()
        )}

        {/* Pagination */}
        <div className="flex justify-center mt-4">
          {currentPage > 1 && (
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              className="mx-2 px-3 py-1 border"
            >
              {"<"}
            </button>
          )}
          {Array.from({ length: totalPages }).map((_, index) => (
            <button
              key={index}
              onClick={() => handlePageChange(index + 1)}
              className={`mx-2 px-3 py-1 border ${
                currentPage === index + 1 ? "text-dbase" : ""
              }`}
            >
              {index + 1}
            </button>
          ))}
          {currentPage < totalPages && (
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              className="mx-2 px-3 py-1 border"
            >
              {">"}
            </button>
          )}
        </div>

        <MessageAlert
          message={message}
          type={showMessgErr ? "err" : "succ"}
          showMessg={showMessgErr || (showMessgSucc && true)}
        />
      </div>
    </>
  );
};

export default RestockHistory;
