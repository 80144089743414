import React, { useEffect, useRef, useState } from "react";
import { FaArrowLeft, FaSearch, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/loader";
import axios from "axios";
import buildLink from "../../urls";
import MessageAlert from "../../components/messageAlert";
import { PlaySounds } from "../../functions/playSounds";
import hocStarredPage from "../../components/hocStarredPage";

const Arrived = () => {
  const {playErrorSound,playSuccessSound} = PlaySounds();
  const history = useNavigate();
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [returnInf, setReturnInf] = useState(null);
  const codePr = useRef(null);
  const [showMessgErr, setShowMessageErr] = useState(false);
  const [message, setMessage] = useState("");
  const [showMessgSucc] = useState(false);

  const searchReturn = async (e) => {
    setLoadingSearch(true);
    e.preventDefault();

    const item = codePr.current.value;

    try {
      const url = buildLink("arrived") + `&return_order_id=${item}`;
      await axios.get(url).then((response) => {
        if (response.data.success) {
          setReturnInf(response.data.data);
        }
      });
      setLoadingSearch(false);
    } catch (e) {
      playErrorSound()
      console.log(e);
      setLoadingSearch(false);
      setMessage(e.response.data.message);
      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
    }
    setLoadingSearch(false);
  };

  useEffect(()=>{
    codePr.current.focus();
  },[])

  const handleTrashClick = () => {
    codePr.current.value = "";
    codePr.current.focus();
  };
  return (
    <>
      <div className=" h-full bg-white">
        {/* header */}
        <div className=" mb-5 sticky top-0 flex flex-col bg-white">
          <div className=" w-full bg-dbase h-16">
            <div className=" container flex relative gap-7 flex-row justify-start w-full text-white  h-full my-auto">
              <button onClick={() => history(-1)}>
                <FaArrowLeft />
              </button>
              <h2 className=" text-xl    my-auto">Arrived</h2>
            </div>
          </div>
        </div>

        <div className="container">
          <form onSubmit={(e) => searchReturn(e)}>
            <div className=" flex flex-row justify-center gap-3 ">
              <div className="relative flex-1">
                <input
                  ref={codePr}
                  type="text"
                  className="border border-dlabelColor outline-dbase w-full rounded-md  py-3 pr-8  pl-2"
                  placeholder="Rerturn Order ID"
                />
                <div>
                  <FaTrash onClick={()=>handleTrashClick()} className="absolute top-1/2 transform -translate-y-1/2 right-3 cursor-pointer opacity-20 " />
                </div>
              </div>
              <button className=" bg-dbase rounded-md text-white px-5 ">
                {" "}
                {loadingSearch ? <Loader /> : <FaSearch />}{" "}
              </button>
            </div>
          </form>
          <div className=" flex flex-col gap-3 mt-5">
            {returnInf &&
              returnInf.map((item) => {
                return (
                 <div className=" w-full  shadow-md py-2 px-3  flex flex-row  gap-3 justify-start">
                  <div className=" w-24 aspect-square">
                    <img src={item.image}/>
                    </div>
                    <div className=" flex flex-col ">
                      <p className=" mb-2">{item.name} </p>
                      <p><strong>Sku:</strong>{item.sku} </p>
                      <p><strong>Qty:</strong>{item.quantity} </p>
                     <p><strong>Barcode:</strong>{item.barcode} </p>


                    </div>
                 </div>
                );
              })}
          </div>
        </div>

        <MessageAlert
          message={message}
          type={showMessgErr ? "err" : "succ"}
          showMessg={showMessgErr || (showMessgSucc && true)}
        />
      </div>
    </>
  );
};

export default hocStarredPage(Arrived);
