import React, { useContext, useEffect, useRef, useState } from "react";
import { FaArrowLeft, FaCheckCircle, FaSearch, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import buildLink from "../../urls";
import { CompanyContext } from "../../contexts/companyCotext";
import Loader from "../../components/loader";
import MessageAlert from "../../components/messageAlert";
import { PlaySounds } from "../../functions/playSounds";
import hocStarredPage from "../../components/hocStarredPage";
import Cookies from "js-cookie";

const ChangeMainUpc = () => {

  const {playErrorSound,playSuccessSound} = PlaySounds();
  const [loading, setLoading] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [showMessgErr, setShowMessageErr] = useState(false);
  const [message, setMessage] = useState("");
  const [showMessgSucc, setShowMessageSucc] = useState(false);
  const [productInf, setProductInf] = useState(null);
  const [stateCom, dispatchCom] = useContext(CompanyContext);
  const product_id = useRef(null);
  const oldUPC = useRef(null);

  const codePr = useRef(null);
  const UPC = useRef(null);

  // searchProduct
  const searchProduct = async (e) => {

    setLoadingSearch(true);
    e.preventDefault();

    const item = codePr.current.value;

    try {
      const url = buildLink("productInfo") + `&item=${item}`+ `&user_id=${stateCom.userID}`;
      await axios.get(url).then((response) => {
        if (response.data.success) {
          setProductInf(response.data.data);
          oldUPC.current.value = response.data.data.main_upc;
          UPC.current.focus()
        }
      });
      setLoadingSearch(false);
    } catch (e) {
      playErrorSound();
      setLoadingSearch(false);
      setMessage(e.response.data.message);
      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
    }
    setLoadingSearch(false);
  };

  const getUserIdFromLocalStorage = () => {
    return localStorage.getItem("userID");
  };

  async function changeUpc(e) {
    e.preventDefault()
    const userID = getUserIdFromLocalStorage();
    setLoading(true);
    const product_id = codePr.current.value;
    const main_upc = UPC.current.value;
    const user_id = userID;

    const obj = JSON.stringify({
      product_id,
      main_upc,
      user_id,
    });

    try {
      await axios
        .put(buildLink("updateMainUPC"), JSON.parse(obj))
        .then((response) => {
          if (response.data.success) {
            playSuccessSound()
            setMessage(response.data.message);
            setShowMessageSucc(true);
            setTimeout(() => {
              setShowMessageSucc(false);
            }, 3000);
          }
        });
    } catch (e) {
      playErrorSound();
      setMessage(e.response.data.message);
      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
    }
    setLoading(false);
  }
  ///////////////////////////////////////////////////////////////////////

  const history = useNavigate();

  useEffect(() => {
    codePr.current.focus();
  }, []);

  const handleTrashClick = () => {
    codePr.current.value = "";
    codePr.current.focus();
  };

  return (
    <>
      <div className=" h-full bg-white">
        {/* header */}
        <div className=" mb-5 sticky top-0 flex flex-col bg-white">
          <div className=" w-full bg-dbase h-16">
            <div className=" container flex relative gap-7 flex-row justify-start w-full text-white  h-full my-auto">
              <button onClick={() => history(-1)}>
                <FaArrowLeft />
              </button>
              <h2 className=" text-xl my-auto">Change Main Upc</h2>
            </div>
          </div>
        </div>
        <>
          <div className="container">
            <form onSubmit={(e) => searchProduct(e)}>
              <div className=" flex flex-row justify-center gap-3 ">
                <div className="relative flex-1">
                  <input
                    ref={codePr}
                    type="text"
                    className="border border-dlabelColor outline-dbase w-full rounded-md  py-3 pr-8  pl-2"
                    placeholder="Product ID Or Sku"
                  />
                  <div>
                    <FaTrash
                      onClick={() => handleTrashClick()}
                      className="absolute top-1/2 transform -translate-y-1/2 right-3 cursor-pointer opacity-20 "
                    />
                  </div>
                </div>
                <button className=" bg-dbase rounded-md text-white px-5 ">
                  {" "}
                  {loadingSearch ? <Loader /> : <FaSearch />}{" "}
                </button>
              </div>
            </form>
          </div>
          <div className="gap-2 flex py-3 ml-3 mr-3"></div>
          <form  onSubmit={(e) => changeUpc(e)}  className="container relative flex-1">
            <span>
              Old Main Upc
              <input
                type="text"
                className="border border-dlabelColor outline-dbase w-full rounded-md  py-2 pr-8 pl-2 mb-4"
                placeholder="Old Main UPC"
                ref={oldUPC}
                readOnly
              />
            </span>
            New Main Upc
            <span>
              <input
                ref={UPC}
                type="text"
                className="border border-dlabelColor outline-dbase w-full rounded-md  py-2 pr-8  pl-2 "
                placeholder="New Main UPC"
              />
            </span>
            <div className="w-full ">
            <button
             type="submit"
              className="w-full bg-dbase text-white p-2 rounded-md mt-4 shadow"
            >
              {loading ? "Loading..." : "Change Main UPC"}
            </button>
          </div>
          </form>
        
          <div className="container rounded-md shadow w-full m-3">
            {productInf && (
              <div className="flex gap-10 p-4 mt-8">
                <img
                  className="object-cover w-40 h-40"
                  src={productInf.image}
                  alt="Product Image"
                />
                <div className="flex flex-col mt-8">
                  <div className="my-2">
                    <span className="font-bold">SKU:</span> {productInf.sku}
                  </div>
                  <div className="my-2">
                    <span className="font-bold">Quantity:</span>{" "}
                    {`${productInf ? productInf.upc_quantity : ""}`}
                  </div>
                </div>
              </div>
            )}
          </div>
        </>

        <MessageAlert
          message={message}
          type={showMessgErr ? "err" : "succ"}
          showMessg={showMessgErr || (showMessgSucc && true)}
        />
      </div>
    </>
  );
};
export default hocStarredPage(ChangeMainUpc);
