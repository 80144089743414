import React from "react";

const NavigateButton = ({ icon, name, arname, color, onclick }) => {
  return (
    <>
      <button
        onClick={onclick}
        className={` h-32  rounded-3xl text-white  ${color}`}
      >
        <div className=" flex flex-col justify-center text-center my-auto gap-2 px-2 ">
          <div className=" mx-auto  text-2xl">{icon}</div>
          <h2 className=" text-md">{name}</h2>
          <h2>{arname}</h2>
        </div>
      </button>
    </>
  );
};

export default NavigateButton;
