import React, { useContext, useEffect, useRef, useState } from "react";
import {
  FaArrowLeft,
  FaBox,
  FaResolving,
  FaSearch,
  FaSyncAlt,
  FaTrash,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import NavigateButton from "../../../components/buttonsNavigate";
import { IoTrashBin } from "react-icons/io5";
import buildLink from "../../../urls";
import axios from "axios";
import { CompanyContext } from "../../../contexts/companyCotext";
import Order from "../order";
import hocStarredPage from "../../../components/hocStarredPage";
import Cookies from "js-cookie";
import Loader from "../../../components/loader";
import { BiSolidDownArrow } from "react-icons/bi";
import { BsFillEnvelopeExclamationFill } from "react-icons/bs";

const Pickuporder = () => {
  const [data, setData] = useState();
  const [orders, setOrders] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [stateCom, dispatchCom] = useContext(CompanyContext);
  const [ispicked, setIsPicked] = useState(false);
  const [indexBin, setIndexBin] = useState(0);
  const [list, setList] = useState();
  const [loadingSearch, setLoadingSearch] = useState(false);
  const history = useNavigate();
  const orderID = useRef(null);
  const [isInfoVisible, setInfoVisible] = useState(false);
  const [start, setStart] = useState(false)

  const getUserIdFromLocalStorage = () => {
    return localStorage.getItem("userID");
  };

  const handleIconClick = () => {
    setInfoVisible(!isInfoVisible);
  };

  useEffect(() => {
    const User_Total = async () => {
      const userID = getUserIdFromLocalStorage();
      // const user_id = stateCom.userID;
      try {
        const url = buildLink("pickupOrder") + `&user_id=${userID}`;
        await axios.get(url).then((response) => {
          if (response.data.success) {
            setData(response.data.data);
            // setOrders(response.data.data.orders);
            // setTotalPages(response.data.data.total_pages);
          }
          // setList(response.data.data.orders);
          // if (response.data.data.orders[indexBin].picked) {
          //   setIsPicked(true);
          // }
        });
      } catch (e) {
        console.log(e);
      }
    };
    User_Total();
  }, []);



  async function searchPickupOrders(e) {
    e.preventDefault();
    // const user_id = stateCom.userID;
    try {
      const url =
        buildLink("getPickupOrderProducts") +
        `&order_id=${orderID.current.value}`;
      const response = await axios.get(url);
      if (response.data.success) {
        setOrders(response.data.data.order_products);
        setStart(true)
      }
    } catch (e) {
      console.log("error:", e);
    }
  }



  // useEffect(() => {
  //   if (list && list[indexBin] && list[indexBin].picked) {
  //     setIsPicked(true);
  //   } else {
  //     setIsPicked(false);
  //   }
  // }, [indexBin]);

  // const handlePageChange = (newPage) => {
  //   setCurrentPage(newPage);
  // };

  // const handleProductClick = (orderId) => {
  //   history(`pickuporderproducts/${orderId}`);
  // };

  const handleTrashClick = () => {
    orderID.current.value = "";
    orderID.current.focus();
  };
  return (
    <>
      <div className=" h-full bg-white">
        {/* header */}
        <div className=" mb-5 sticky top-0 flex flex-col bg-white z-10">
          <div className=" w-full bg-dbase h-16">
            <div className=" container flex relative gap-7 flex-row justify-start w-full text-white  h-full my-auto">
              <button onClick={() => history(-1)}>
                <FaArrowLeft />
              </button>
              <h2 className=" text-xl my-auto">Pick Up Order</h2>
            </div>
          </div>
        </div>
        <form onSubmit={(e) => searchPickupOrders(e)}>
          <div className="container flex flex-row justify-center gap-3 ">
            <div className="relative flex-1">
              <input
                ref={orderID}
                type="text"
                className="border border-dlabelColor outline-dbase w-full rounded-md  py-3 pr-8  pl-2"
                placeholder="Order ID"
              />
              <div>
                <FaTrash
                  onClick={() => handleTrashClick()}
                  className="absolute top-1/2 transform -translate-y-1/2 right-3 cursor-pointer opacity-20 "
                />
              </div>
            </div>
            <button className=" bg-dbase rounded-md text-white px-5 ">
              {" "}
              {loadingSearch ? <Loader /> : <FaSearch />}{" "}
            </button>
          </div>
        </form>
        {data && (
          <div className="flex justify-center mt-7">
            <div className="bg-dblack rounded mx-2 my-2 px-1 pt-1 shadow-lg absolute left-0">
              <h1 className="text-white font-semibold mb-2">
                Name: {data.username}
              </h1>
            </div>
            <div className="bg-dblack rounded mx-2 my-2 px-1 pt-1 shadow-lg absolute right-0">
              <h1 className="text-white font-semibold mb-2">
                Total Picked: {data.total_picked_order_products}
              </h1>
            </div>
          </div>
        )}
        {start && (
          <button
          onClick={() => history(`${orderID.current.value}/orderproductlist`)}
          className="bg-dbase border border-dblack container rounded-md p-3 text-white mt-14"
        >
          Start
        </button>
        ) }
        

        <div className="container">
          {orders &&
            orders.map((order) => {
              return (
                <div
                  key={order.order_product_id}
                  className={` border-2 rounded-lg flex gap-10 p-7 mt-8 product-container mb-2 ${
                    order.picked ? " bg-dgreen2 bg-opacity-80 " : ""
                  }`}
                >
                  <div className="container w-auto">
                    <div key={order.order_product_id}>
                      {order.bin_id && (
                        <p className="font-bold text-dbase1">
                          Bin ID: {order.bin_id}
                        </p>
                      )}
                      {order.upc && !order.bin_id && (
                        <p className="font-bold text-dbase1">
                          UPC: {order.upc}
                        </p>
                      )}
                      {order.big_upc && !order.bin_id && !order.upc && (
                        <p className="font-bold text-dbase1">
                          Big UPC: {order.big_upc}
                        </p>
                      )}
                      {!order.big_upc && !order.bin_id && !order.upc && (
                        <>
                          {isInfoVisible && (
                            <p className="font-bold text-dbase1 border-2">
                              <td>
                                <tr className="font-light">Bin_ID: no value</tr>
                                <tr className="font-light">UPC: no value</tr>
                                <tr className="font-light">
                                  Big UPC: no value
                                </tr>
                                <BiSolidDownArrow className="relative translate-y-9" />
                              </td>
                            </p>
                          )}
                          <div
                            onClick={handleIconClick}
                            className="cursor-pointer"
                          >
                            <BsFillEnvelopeExclamationFill className="text-dbase mt-3 ml-1" />
                          </div>
                        </>
                      )}
                    </div>
                    <div className="flex flex-col mt-8">
                      <div className="my-2">
                        <span className="font-bold">SKU:</span> {order.sku}
                      </div>
                      <div className="my-2">
                        <span className="font-bold">Quantity:</span>{" "}
                        {`${order.quantity}`}
                      </div>
                    </div>
                  </div>
                  <img
                    className="object-cover h-36 w-40  rounded-md m-auto container"
                       src={order.mobile_image}
                    // src={
                    //   window.innerWidth > 675 ? order.image : order.mobile_image
                    // }
                    alt="Product Image"
                  />
                </div>
              );
            })}
        </div>
        {/* Pagination */}
        {/* <div className="flex justify-center mt-4">
          {currentPage > 1 && (
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              className="mx-2 px-3 py-1 border"
            >
              {"<"}
            </button>
          )}
          {Array.from({ length: totalPages }).map((_, index) => (
            <button
              key={index}
              onClick={() => handlePageChange(index + 1)}
              className={`mx-2 px-3 py-1 border ${
                currentPage === index + 1 ? "  text-dbase" : ""
              }`}
            >
              {index + 1}
            </button>
          ))}
          {currentPage < totalPages && (
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              className="mx-2 px-3 py-1 border"
            >
              {">"}
            </button>
          )}
        </div> */}
      </div>
    </>
  );
};

export default hocStarredPage(Pickuporder);
