import React, { useRef, useState, useEffect } from "react";
import { FaArrowLeft, FaSearch } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import MessageAlert from "../../../components/messageAlert";
import Loader from "../../../components/loader";
import axios from "axios";
import buildLink from "../../../urls";
import { PlaySounds } from "../../../functions/playSounds";
import Select from "react-select";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";

const StatusComponent = () => {
  const { playErrorSound, playSuccessSound } = PlaySounds();
  const [loadingWarehouses, setLoadingWarehouses] = useState(true);
  const [warehouses, setWarehouses] = useState([]);
  const [selectedWarehousefrom, setSelectedWarehousefrom] = useState(null);
  const [selectedWarehouseto, setSelectedWarehouseto] = useState(null);
  const [inputQuantity, setInputQuantity] = useState("");
  const [logEntries, setLogEntries] = useState([]);

  const history = useNavigate();
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [WahrehouesInfo, setWahrehouesInfo] = useState([]);
  const [error, setError] = useState(null);
  const OrderID = useRef(null);
  const AddressID = useRef(null);
  const ProductID = useRef(null);
  const [message, setMessage] = useState("");
  const [showMessgSucc, setShowMessageSucc] = useState(false);
  const [showMessgErr, setShowMessageErr] = useState(false);
  const [ProductInfo, setProductInfo] = useState([]);
  const [Counter, setCounter] = useState(0);
  const [CounterTemp, setCounterTemp] = useState(0);
  const [userID, setUserID] = useState(null);
  const [isQuantityInputDisabled, setIsQuantityInputDisabled] = useState(false);
  const { name, id } = useParams();
  const decodedName = decodeURIComponent(name);
  const [status, setstatus] = useState("");
  const [status2, setstatus2] = useState("");
  const [fromwarehouse, setfromwarehouse] = useState(null);
  const [towarehouse, settowarehouse] = useState(null);
  const inputQuantityy = useRef(null);
  const [objectFromCookies, setObjFromCookies] = useState(null);

  useEffect(() => {
    const user_id = localStorage.getItem("userID");
    setUserID(user_id);
  }, []);

  const handleSetDataInCookies = (key, value) => {
    const dataFromCookies = Cookies.get(decodedName);
    let data = {};
    if (dataFromCookies) {
      data = JSON.parse(dataFromCookies);
    }
    data[key] = value;
    Cookies.set(decodedName, JSON.stringify(data), { expires: 1 });
  };

  const fetchWarehouses = async () => {
    try {
      const url = buildLink(`fetchfromwarehouse`);
      const response = await axios.get(url);
      if (response.data.success) {
        setWarehouses(response.data.data || []);
        setLoadingWarehouses(false);
      } else {
        setError("Failed to fetch warehouses.");
      }
    } catch (err) {
      setError("Failed to fetch warehouses.");
    } finally {
      setLoadingWarehouses(false);
    }
  };

  useEffect(() => {
    if (objectFromCookies) {
      if (objectFromCookies.selectedWarehousefrom && warehouses) {
        setSelectedWarehousefrom(objectFromCookies.selectedWarehousefrom);
      }
      if (objectFromCookies.selectedWarehouseto && warehouses) {
        setSelectedWarehouseto(objectFromCookies.selectedWarehouseto);
      }
    }
  }, [warehouses, objectFromCookies]);

  const submitchangedqty = (e) => {
    e.preventDefault();

    const enteredQuantity = parseInt(inputQuantity, 10);

    if (!isNaN(enteredQuantity) && enteredQuantity > 0) {
      setCounter(enteredQuantity);
      setCounterTemp(enteredQuantity);
      setIsQuantityInputDisabled(true);
    } else {
      console.error("Invalid quantity entered.");
    }
  };

  useEffect(() => {
    if (
      window.location.pathname ===
      "/home/v2home/warehousepage/ChangeStatusWarehouse/Warehouse%20Restock"
    ) {
      setstatus("restock");
      setstatus2("restock");
    } else if (
      window.location.pathname ===
      "/home/v2home/warehousepage/ChangeStatusWarehouse/Send%20To%20Warehouse"
    ) {
      setstatus("send_to_warehouse");
      setstatus2("send_to_warehouse");
    } else if (
      window.location.pathname ===
      "/home/v2home/warehousepage/ChangeStatusWarehouse/Received%20From%20Warehouse"
    ) {
      setstatus("received_from_warehouse");
      setstatus2("received_from_warehouse");
    }
  }, []);

  const changestatus = async (e) => {
    e.preventDefault();
    let productIdTemp = ProductID.current.value;
    // console.log("!@312312312312#", CounterTemp);
    if (CounterTemp < 30 && CounterTemp - 1 > 0) {
      setCounterTemp(CounterTemp - 1);
      ProductID.current.value = "";
      ProductID.current.focus();
    } else {
      const order_id = OrderID.current.value;
      const from_warehouse = selectedWarehousefrom?.value;
      const to_warehouse = selectedWarehouseto?.value;
      const user_id = userID;
      const product = ProductID.current.value;

      setIsQuantityInputDisabled(true);

      try {
        const obj = {
          order_id,
          status,
          user_id,
          product,
          quantity: inputQuantity,
        };

        if (
          window.location.pathname ===
          "/home/v2home/warehousepage/ChangeStatusWarehouse/Warehouse%20Restock"
        ) {
          obj.address_id = AddressID.current.value;
          obj.to_warehouse = to_warehouse;
          obj.from_warehouse = fromwarehouse;
        }
        if (
          window.location.pathname ===
          "/home/v2home/warehousepage/ChangeStatusWarehouse/Send%20To%20Warehouse"
        ) {
          obj.from_warehouse = from_warehouse;
          obj.to_warehouse = towarehouse;
        }
        if (
          window.location.pathname ===
          "/home/v2home/warehousepage/ChangeStatusWarehouse/Received%20From%20Warehouse"
        ) {
          obj.to_warehouse = to_warehouse;
          obj.from_warehouse = fromwarehouse;
        }

        const url = buildLink("changeProductStatus");
        const response = await axios.post(url, obj);

        if (response.data.success) {
          setMessage("Product updated successfully");
          playSuccessSound();
          setShowMessageSucc(true);
          setTimeout(() => setShowMessageSucc(false), 3000);
          clearInputs();
           console.log(response.data.data.products)
          const newLogEntry = {
            productId: productIdTemp,
            quantity: inputQuantity,
            sku: response.data.data.products[response.data.data.products.findIndex((product)=>product.product_id === productIdTemp)].sku,
          };
          
          
    
          setLogEntries((prevEntries) => [newLogEntry, ...prevEntries]);
        } else {
          setMessage(response.data.message);
          setShowMessageErr(true);
          setTimeout(() => setShowMessageErr(false), 3000);
          clearInputs();
        }
      } 
      catch (e) {
        console.log(e)
        setMessage(e.response.data.message);
        setShowMessageErr(true);
        setTimeout(() => setShowMessageErr(false), 3000);
        clearInputs();
      } finally {
        setIsQuantityInputDisabled(false);
      }
    }
  };

  const clearInputs = () => {
    ProductID.current.value = "";
    setInputQuantity(""); // Reset the state for the controlled input
  };

  useEffect(() => {
    const response = Cookies.get(decodedName);
    if (response) {
      setObjFromCookies(JSON.parse(response));
      // console.log(response);
    }

    fetchWarehouses();
  }, []);

  const getOrders = async (e) => {
    e.preventDefault();
    setLoadingSearch(true);
    setError(null);
    try {
      const url = `${buildLink(`getTransferOrderById`)}&order_id=${
        OrderID.current.value
      }&status=${status}`;

      const response = await axios.get(url);
      if (response.data.success) {
        setMessage("Order with id: " + OrderID.current.value + " Was Found");
        setShowMessageSucc(true);
        playSuccessSound();
        setTimeout(() => setShowMessageSucc(false), 3000);
        setProductInfo(response.data.products);
        setfromwarehouse(response.data.from_warehouse);
        settowarehouse(response.data.to_warehouse);
        ProductID.current.focus();
      } else {
        setMessage(response.data.message);
        setShowMessageErr(true);
        setTimeout(() => setShowMessageErr(false), 3000);
        playErrorSound();
        OrderID.current.value = "";
      }
    } catch (e) {
      setMessage(e.response.data.message);
      setShowMessageErr(true);
      setTimeout(() => setShowMessageErr(false), 3000);
      playErrorSound();
      OrderID.current.value = "";
    } finally {
      setLoadingSearch(false);
    }
  };

  return (
    <>
      {/* Header */}
      <div className="mb-5 sticky top-0 flex flex-col bg-white z-50">
        <div className="w-full bg-dbase h-16">
          <div className="container px-3 relative flex justify-between w-full text-white h-full">
            <div className="flex gap-7 flex-row justify-start">
              <button onClick={() => history(-1)}>
                <FaArrowLeft />
              </button>
              <h2 className="text-xl my-auto">{decodedName}</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto p-5">
        <form onSubmit={getOrders}>
          <div className="flex flex-col">
            <div className="mb-1">Order ID</div>
            <div className="flex flex-row border-dblack rounded-lg text-center w-full gap-3">
              <input
                ref={OrderID}
                type="text"
                className="border border-dblack rounded-lg block p-2.5 flex-grow text-start w-full h-12"
                placeholder="Order ID"
              />
              <button className="border border-dblack bg-dbase rounded-lg text-white px-5 h-12">
                {loadingSearch ? <Loader /> : <FaSearch />}
              </button>
            </div>
          </div>
        </form>
        {window.location.pathname ===
          "/home/v2home/warehousepage/ChangeStatusWarehouse/Send%20To%20Warehouse" && (
          <div className="py-3">
            <div className="mb-1">From Warehouse</div>
            {loadingWarehouses ? (
              <div className="text-center text-dbase">
                <Loader />
              </div>
            ) : error ? (
              <p className="text-dbase">{error}</p>
            ) : (
              <Select
                placeholder="Source Warehouse"
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderRadius: "7px",
                    borderColor: state.isFocused ? "grey" : "grey",
                    padding: "8px 5px",
                    height: "48px",
                  }),
                }}
                options={warehouses.map((warehouse) => ({
                  value: warehouse.warehouse_id,
                  label: warehouse.warehouse_name,
                }))}
                value={selectedWarehousefrom}
                onChange={(option) => {
                  handleSetDataInCookies("selectedWarehousefrom", option);
                  setSelectedWarehousefrom(option);
                }}
              />
            )}
          </div>
        )}

        {(window.location.pathname ===
          "/home/v2home/warehousepage/ChangeStatusWarehouse/Received%20From%20Warehouse" ||
          window.location.pathname ===
            "/home/v2home/warehousepage/ChangeStatusWarehouse/Warehouse%20Restock") && (
          <div className="py-3">
            <div className="mb-1">To Warehouse</div>
            {loadingWarehouses ? (
              <div className="text-center text-dbase">
                <Loader />
              </div>
            ) : error ? (
              <p className="text-dbase">{error}</p>
            ) : (
              <Select
                placeholder="Destination Warehouse"
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderRadius: "7px",
                    borderColor: state.isFocused ? "grey" : "grey",
                    padding: "8px 5px",
                    height: "48px",
                  }),
                }}
                options={warehouses.map((warehouse) => ({
                  value: warehouse.warehouse_id,
                  label: warehouse.warehouse_name,
                }))}
                value={selectedWarehouseto}
                onChange={(option) => {
                  handleSetDataInCookies("selectedWarehouseto", option);
                  setSelectedWarehouseto(option);
                }}
              />
            )}
          </div>
        )}

        {window.location.pathname ==
          "/home/v2home/warehousepage/ChangeStatusWarehouse/Warehouse%20Restock" && (
          <div className="flex flex-col mt-3">
            <div className="mb-1">Address</div>
            <div className="flex flex-row border-dblack rounded-lg text-center w-full gap-3">
              <input
                ref={AddressID}
                type="text"
                className="border border-dblack rounded-lg block p-2.5 flex-grow text-start w-full h-12"
                placeholder="Address ID"
              />
            </div>
          </div>
        )}

        <form onSubmit={changestatus}>
          <div className="flex flex-col mt-3">
            <div className="mb-1">Product ID</div>
            <div className="flex flex-row border-dblack rounded-lg text-center w-full gap-3">
              <input
                required
                ref={ProductID}
                type="text"
                className="border border-dblack rounded-lg block p-2.5 flex-grow text-start w-full h-12"
                placeholder="Product ID"
              />
            </div>
          </div>
          <div className="py-3">
            <div className="mb-1">Quantity</div>
            <div className="flex items-center gap-3">
              <input
                required
                type="number"
                value={inputQuantity}
                ref={inputQuantityy}
                onChange={(e) => {
                  const quantity = e.target.value;
                  setInputQuantity(quantity);
                  setCounterTemp(quantity);
                }}
                // onKeyDown={(e) => {
                //   if (e.key === "Enter") {
                //     submitchangedqty(e);
                //   }
                // }}
                placeholder="Quantity to Transfer"
                className="border p-2 rounded-md w-full h-12"
              />
              <button
                type="submit"
                className="border border-dblack bg-dbase rounded-lg text-white px-5 h-12"
              >
                {loadingSearch ? <Loader /> : <FaSearch />}
              </button>
            </div>

            {inputQuantity > 0 && (
              <div className="mt-2 text-lg font-semibold">
                Counter: {CounterTemp}
              </div>
            )}
          </div>
        </form>
      </div>

      <div className="mt-5 px-6 pb-2">
        <h3 className="text-lg font-semibold mb-3">Transfer Log</h3>
        <table className="w-full  table-auto border-collapse border">
          <thead>
            <tr className="border-b">
              <th className="px-4 py-2 border-r">Product ID</th>
              <th className="px-4 py-2 border-r">Quantity</th>
              <th className="px-4 py-2">Sku</th>
            </tr>
          </thead>
          <tbody>
            {logEntries.map((entry, index) => (
              <tr key={index} className="border-b">
                <td className="px-4 py-2 text-center border-r">
                  {entry.productId}
                </td>
                <td className="px-4 py-2 text-center border-r">{entry.quantity}</td>
                <td className="px-4 py-2 text-center border-r">{entry.sku}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* {ProductInfo && ProductInfo.length > 0 && (
  <div className="overflow-x-auto flex justify-center items-center">
    <table className="min-w-full bg-white text-center">
      <thead>
        <tr>
          <th className="px-4 py-2">Product ID</th>
          <th className="px-4 py-2">Quantity</th>
          <th className="px-4 py-2">SKU</th>
        </tr>
      </thead>
      <tbody>
        {ProductInfo.filter((product) => product.status === status2).map(
          (product, index) => (
            <tr key={index} className="border-t">
              <td className="px-4 py-2">{product.product_id}</td>
              <td className="px-4 py-2">{product.quantity}</td>
              <td className="px-4 py-2">{product.sku}</td>
            </tr>
          )
        )}
      </tbody>
    </table>
  </div>
)} */}


      <MessageAlert
        message={message}
        type={showMessgErr ? "err" : "succ"}
        showMessg={showMessgErr || (showMessgSucc && true)}
      />
    </>
  );
};

export default StatusComponent;
