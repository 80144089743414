
import error from "./../assets/sounds/error_sound.mp3";
import success from "./../assets/sounds/success.mp3"




export const PlaySounds = () => {

    const playErrorSound = () => {
        const audio = new Audio(process.env.PUBLIC_URL + error); 
        audio.addEventListener('error', (event) => {
          console.log(`Failed to load audio: ${event}`);
        });
        audio.play();
      };


      const playSuccessSound = () => {
        const audio = new Audio(process.env.PUBLIC_URL + success); 
        audio.addEventListener('error', (event) => {
          console.log(`Failed to load audio: ${event}`);
        });
        audio.play();
      };

return {playErrorSound,playSuccessSound};

}