import React, { useEffect, useContext, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { CompanyContext } from "../contexts/companyCotext";

const NavigateToFavorite = () => {
  const navigate = useNavigate();
  const [state] = useContext(CompanyContext);

  useLayoutEffect(() => {
    const hasOpenedSession = sessionStorage.getItem("hasOpenedSession");
    const favoritePage = Cookies.get("favoritePage");

    // console.log("hasOpenedSession:", hasOpenedSession);
    // console.log("favoritePage:", favoritePage);

    // check if it is the first time opening the app in the current session
    if (!hasOpenedSession) {
      // set the flag to indicate that the app has been opened in the current session
      sessionStorage.setItem("hasOpenedSession", "true");

      // check if there is a favorite page and the user is logged in
      if (state.loged && favoritePage) {
        // navigate to the favorite page
        setTimeout(()=>{
          navigate(favoritePage);
        },300)
        
      }
    }
  }, [navigate, state.loged]);

   // return null
  return null;
};

export default NavigateToFavorite;
