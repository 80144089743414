import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import hocStarredPage from "../../../components/hocStarredPage";

const OrdersHistoryv2 = () => {
  const [orders, setOrders] = useState([]);
  useEffect(() => {
    const list =
      Cookies.get("ordersHistory") == undefined
        ? "[]"
        : Cookies.get("ordersHistory");

    setOrders(JSON.parse(list));
  }, []);

  const history = useNavigate();
  return (
    <>
      <div className=" h-full bg-white">
        {/* header */}
        <div className=" mb-5 sticky top-0 flex flex-col bg-white">
          <div className=" w-full bg-dbase h-16">
            <div className=" container flex relative gap-7 flex-row justify-start w-full text-white  h-full my-auto">
              <button onClick={() => history(-1)}>
                <FaArrowLeft />
              </button>
              <h2 className=" text-xl    my-auto">Orders History</h2>
            </div>
          </div>
        </div>

        <div className=" container flex flex-col gap-2  ">
          {orders &&
            orders.map((order) => (
              <div className=" px-2 py-2 shadow-md rounded-sm w-full flex flex-row justify-between ">
                <div className=" flex flex-col h-fit my-auto">
                  <p>
                    <strong>{order.id}</strong>
                  </p>
                  <p className=" text-sm">{order.reason}</p>
                  <p className=" text-xs text-dlabelColor">{order.date}</p>
                </div>
                <div className="h-fit flex flex-col justify-between my-auto">
                  <p className=" font-bold">{order.user}</p>
                  <p>{order.success}</p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default hocStarredPage(OrdersHistoryv2);
