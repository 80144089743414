import React, { useContext, useEffect, useRef, useState } from "react";
import { FaArrowLeft, FaCheckCircle, FaSadCry, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import buildLink from "../../../urls";
import axios from "axios";
import { CompanyContext } from "../../../contexts/companyCotext";
import { LogisticContext } from "../../../contexts/logisticContext";
import Loader from "../../../components/loader";
import MessageAlert from "../../../components/messageAlert";
import { OroderStatusHook } from "../../../functions/updateOrodersStatus";
import { axiosServer } from "../../../axiosServer";
import hocStarredPage from "../../../components/hocStarredPage";
import Cookies from "js-cookie";

const ReadyTransfer = () => {
  const transferId = useRef(null);
  const [stateCom,dispatchCom]= useContext(CompanyContext);
  const [stateLogistic, dispatchStateLogistic] = useContext(LogisticContext);
  const [logisticSelect,setLogisticSelect] = useState(null);
  const [ numPackage,setNumPackage] = useState(0);
  const [constNumPack,setConstNumPack] = useState(0)
  const [showMessgSucc, setShowMessageSucc] = useState(false);
  const [message, setMessage] = useState("");
  const [showMessgErr, setShowMessageErr] = useState(false);
  const [loading ,setLoading] = useState(false);
  const handleRadioChange = (event) => {
    setNumPackage(event.target.value);
    setConstNumPack(event.target.value);
  };
 
  const history = useNavigate();

  useEffect(() => {
    transferId.current.focus();
  }, []);
useEffect(()=>{
if(stateLogistic.logisticList.length>0){
  setLogisticSelect(stateLogistic.logisticList[14])
}
},stateLogistic.logisticList)


const getUserIdFromLocalStorage = () => {
  return localStorage.getItem("userID");
};

const updateTransfer=async(e)=>{
  e.preventDefault();
  const userID = getUserIdFromLocalStorage()
  if (numPackage > 1) {
    setNumPackage(numPackage - 1);
  } else if (numPackage === 1) {
const url = buildLink("updateTransfer");
const obj ={
  transfer_id:transferId.current.value,
  logistic_id:logisticSelect.value,
  user_id:userID,
  type: "ready",
  nb_of_pack: constNumPack
}
try{
    setLoading(true)
 await axiosServer.put(url,obj).then((res)=>{
  if(res.data.success){
    setLoading(false)
    setMessage(res.data.message)
    setShowMessageSucc(true)
    setTimeout(() => {
      setShowMessageSucc(false)
    }, 3000);
  }else{
    setLoading(false)
    setMessage(res.data.message)
    setShowMessageErr(true)
    setTimeout(() => {
      setShowMessageErr(false)
    }, 3000);
  }
  })
}catch(e){
  setLoading(false)
setMessage(e.response.data.message);
setShowMessageErr(true)
setTimeout(() => {
  setShowMessageErr(false)
}, 3000);
}

}
}






  const handleTrashClick = () => {
    transferId.current.value = "";
    transferId.current.focus();
  };

  return (
    <>
      <div className=" h-full bg-white">
        {/* header */}
        <div className=" mb-5 sticky top-0 flex flex-col bg-white">
          <div className=" w-full bg-dbase h-16">
            <div className=" container flex relative gap-7 flex-row justify-start w-full text-white  h-full my-auto">
              <button onClick={() => history(-1)}>
                <FaArrowLeft />
              </button>
              <h2 className=" text-xl    my-auto">Ready Transfer</h2>
            </div>
          </div>
        </div>
        <div className=" container">
        
          <div className=" py-3">
            <div>logistics</div>
            <Select
            value={logisticSelect}
              placeholder="Logistics"
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderRadius: "7px",
                  borderColor: state.isFocused ? "grey" : "grey",
                  outline: "red",
                  padding: "8px 5px",
                }),
              }}
              options={stateLogistic.logisticList}
              onChange={(e) => {
                setLogisticSelect(e);
              }}
            />
            <div className="p-1 mt-3">Number of Packages:</div>
            <div className="space-x-3 flex flex-wrap">
              {[1, 2, 3, 4, 5, 6, 7, 8].map((value) => (
                <label
                  htmlFor={`pack${value}`}
                  className="flex gap-2 flex-row"
                  key={value}
                >
                  <input
                    type="radio"
                    id={`pack${value}`}
                    name="number"
                    value={value}
                    // checked={selectedValue === `${value}`}
                    onChange={handleRadioChange}
                  />
                  <div>{value}</div>
                </label>
              ))}
            </div>
          </div>
          <div className="-mt-2 -mb-2">Transfer ID</div>
          <form
            onSubmit={(e) =>
              updateTransfer(e)
            }
            className="gap-2 flex py-3"
          >
            <div className="relative flex-1">
              <input
                ref={transferId}
                type="text"
                className="border-2 w-full rounded-md border-dbase py-2 pr-8 pl-2"
              />
              <FaTrash
                className="absolute top-1/2 transform -translate-y-1/2 right-2 cursor-pointer opacity-20 "
                onClick={handleTrashClick}
              />
            </div>
            <div className="w-fit cursor-pointer px-5 text-white text-center rounded-md bg-dbase">
              <button className="flex  whitespace-nowrap  items-center justify-center h-full">
                {numPackage > 1 || numPackage === 0 ? (
                  numPackage
                ) : loading ? (
                  <Loader />
                ) : (
                  "Mark as ready"
                )}
              </button>
            </div>
          </form>
        </div>
    
        
        <MessageAlert
          message={message}
          type={showMessgErr ? "err" : "succ"}
          showMessg={showMessgErr || (showMessgSucc && true)}
        />
      </div>
    </>
  );
};
export default hocStarredPage(ReadyTransfer);
