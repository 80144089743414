import React, { useContext, useEffect, useState } from "react";
import { Route, Routes, useNavigate }             from "react-router-dom";
import { CompanyContext, CompanyProvider }        from "./contexts/companyCotext";
import { LogisticContext }                        from "./contexts/logisticContext";
import { MdOutlineWifiOff }                       from "react-icons/md";
import { FaWifi }                                 from "react-icons/fa";
import axios                                      from "axios";
import buildLink                                  from "./urls";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import {  useLocation, BrowserRouter as Router,  } from 'react-router-dom';
import { debounce } from "lodash";


// v1 v1 v1 v1 v1 v1 v1 v1 v1 v1 v1 v1 v1 v1 v1 v1 v1 v1 v1 v1 v1 v1 v1 v1 v1 v1 v1 v1 v1 v1 v1 v1 v1 v1 v1 v1 v1 v1 v1 v1 v1 v1 v1 v1 v1 v1 v1 v1

import Home                   from "./pages/home";
import ChangeStatusPage       from "./pages/homePages/changeStatusPages/changeStatusPage";
import Given                  from "./pages/homePages/changeStatusPages/given";
import RecivedFromSeller      from "./pages/homePages/changeStatusPages/recivedFromSellerPage";
import GivenToSeller          from "./pages/homePages/changeStatusPages/givenToSeller";
import Failed                 from "./pages/homePages/changeStatusPages/failed";
import ReceivedInStore        from "./pages/homePages/changeStatusPages/receivedInStore";
import CompleteStore          from "./pages/homePages/changeStatusPages/completeStorePage";
import BackToStock            from "./pages/homePages/changeStatusPages/backToStock";
import ReceivedInStock        from "./pages/homePages/changeStatusPages/receivedInStock";
import Ready                  from "./pages/homePages/changeStatusPages/ready";
import Product                from "./pages/homePages/productPage";
import ManageProduct          from "./pages/homePages/manageProduct";
import ChangeUpc              from "./pages/homePages/changeUpc";
import ChangeOptionUpc        from "./pages/homePages/changeOptionUpc";
import ChangeMainUpc          from "./pages/homePages/changeMainUpc";
import Order                  from "./pages/homePages/order";
import UpcRestock             from "./pages/homePages/upcRestockPages/upcRestock";
import ChangeUpcQuantity      from "./pages/homePages/changeUpcquantity";
import ManageUpcQuantity      from "./pages/homePages/manageUpcQuantity";
import ConfirmRestock         from "./pages/homePages/confirmRestock";
import ChangeBigUpc           from "./pages/homePages/changeBigUpc";
import OrderProduct           from "./pages/homePages/orderProduct";
import OrdersHistory          from "./pages/homePages/ordersHistory";
import Jardeh                 from "./pages/homePages/jardeh";
import StockToEntry           from "./pages/homePages/stockToEntry";
import Arrived                from "./pages/returnPages/arrived";
import SendToControl          from "./pages/returnPages/sendToControlPage";
import ReceivedInControl      from "./pages/returnPages/receivedInControl";
import Control                from "./pages/returnPages/controlPage/control";
import StartPage              from "./pages/startPage";
import LogIn                  from "./pages/login";
import Defect                 from "./pages/returnPages/controlPage/defect";
import Maintenance            from "./pages/returnPages/controlPage/maintenance";
import Restock                from "./pages/returnPages/controlPage/restock";
import OrderProductList       from "./pages/homePages/orderProductList";
import GetOrderPage           from "./pages/homePages/stockToEntryPages/getorder";
import ChangeProductStatus    from "./pages/homePages/stockToEntryPages/changeproductstatus";
import JardehSinglePage       from "./pages/homePages/jardehPages/jardehSinglePage";
import JardehViewAll          from "./pages/homePages/jardehPages/viewAllJardeh";
import JardehAddBatch         from "./pages/homePages/jardehPages/JardehAddBatchPage";
import Assign                 from "./pages/homePages/stockToEntryPages/palletPages.jsx/assignPallet";
import Unassign               from "./pages/homePages/stockToEntryPages/palletPages.jsx/unassignPallet";
import Pallet                 from "./pages/homePages/stockToEntryPages/palletPages.jsx/pallet";
import BinPage                from "./pages/homePages/stockToEntryPages/binPage";
import RecivedFromSellerPart1 from "./pages/homePages/changeStatusPages/receivedFromSeller/receivedFromSeller";
import UpdateUpc              from "./pages/homePages/changeStatusPages/receivedFromSeller/updateUpc";
import RecivedFromSellerPart2 from "./pages/homePages/changeStatusPages/receivedFromSeller/receivedFromSeller2";
import Test                   from "./pages/test";
import Transfer               from "./pages/homePages/transferPages/transfer";
import GivenTransfer          from "./pages/homePages/transferPages/givenTransfer";
import ReadyTransfer          from "./pages/homePages/transferPages/readyTransfer";
import FailedTransfer         from "./pages/homePages/transferPages/failedTransfer";
import Pickuporder            from "./pages/homePages/pickUpOrderPages/pickUpOrder";
// import PickUpOrderProducts    from "./pages/homePages/pickUpOrderPages/pickUpOrderProducts";
import ScanPickUpOrder        from "./pages/homePages/pickUpOrderPages/scanPickUpOrder";
import NavigateToFavorite     from "./components/navigateToFavorite";
import ReceiveProductQuantity from "./pages/homePages/ReceiveProductQuantity";
import RestockHistory         from "./pages/homePages/upcRestockPages/restockHistory";

// v2 v2 v2 v2 v2 v2 v2 v2 v2 v2 v2 v2 v2 v2 v2 v2 v2 v2 v2 v2 v2 v2 v2 v2 v2 v2 v2 v2 v2 v2 v2 v2 v2 v2 v2 v2 v2 v2 v2 v2 v2 v2 v2 v2 v2 v2 v2 v2

import V2Home                      from "./pages/version2pages/v2home";
import OrderPage                   from "./pages/version2pages/orderPages/orderPage";
import OrderSearch                 from "./pages/version2pages/orderPages/order";
import OrderProductv2              from "./pages/version2pages/orderPages/orderProduct";
import OrderProductListv2          from "./pages/version2pages/orderPages/orderProductList";
import OrdersHistoryv2             from "./pages/version2pages/orderPages/ordersHistory";
import ProductPage                 from "./pages/version2pages/productPages/productPage";
import Productv2                   from "./pages/version2pages/productPages/product";
import ProductNew                  from "./pages/version2pages/productPages/productNew";
import ChangeUpcv2                 from "./pages/version2pages/productPages/changeUpc";
import ChangeOptionUpcv2           from "./pages/version2pages/productPages/changeOptionUpc";
import GetOrderPagev2              from "./pages/version2pages/orderPages/getorder";
import ChangeProductStatusv2       from "./pages/version2pages/productPages/changeproductstatus";
import BinPagev2                   from "./pages/version2pages/productPages/binPage";
import Palletv2                    from "./pages/version2pages/otherPages/palletPages/pallet";
import Assignv2                    from "./pages/version2pages/otherPages/palletPages/assignPallet";
import Unassignv2                  from "./pages/version2pages/otherPages/palletPages/unassignPallet";
import ChangeBigUpcv2              from "./pages/version2pages/productPages/changeBigUpc";
import ChangeMainUpcv2             from "./pages/version2pages/productPages/changeMainUpc";
import UpcRestockv2                from "./pages/version2pages/productPages/upcRestockPages/upcRestock";
import RestockHistoryv2            from "./pages/version2pages/productPages/upcRestockPages/restockHistory";
import ChangeUpcQuantityv2         from "./pages/version2pages/productPages/changeUpcquantity";
import ManageUpcQuantityv2         from "./pages/version2pages/productPages/manageUpcQuantity";
import ConfirmRestockv2            from "./pages/version2pages/productPages/checkUpc";
import Jardehv2                    from "./pages/version2pages/productPages/jardeh";
import OtherPage                   from "./pages/version2pages/otherPages/otherPage";
import ReceiveProductQuantityv2    from "./pages/version2pages/productPages/ReceiveProductQuantity";
import ChangeStatusPagev2          from "./pages/version2pages/orderPages/changeStatusPages/changeStatusPage";
import Givenv2                     from "./pages/version2pages/orderPages/changeStatusPages/given";
import RecivedFromSellerv2         from "./pages/version2pages/orderPages/changeStatusPages/recivedFromSellerPage";
import RecivedFromSellerPart1v2    from "./pages/version2pages/orderPages/changeStatusPages/receivedFromSeller/receivedFromSeller"
import RecivedFromSellerPart2v2    from "./pages/version2pages/orderPages/changeStatusPages/receivedFromSeller/receivedFromSeller2"
// import UpdateUpcv2                 from "./pages/version2pages/orderPages/changeStatusPages/receivedFromSeller/updateUpc";
import GivenToSellerv2             from "./pages/version2pages/orderPages/changeStatusPages/givenToSeller";
import Failedv2                    from "./pages/version2pages/orderPages/changeStatusPages/failed";
import ReceivedInStorev2           from "./pages/version2pages/orderPages/changeStatusPages/receivedInStore";
import CompleteStorev2             from "./pages/version2pages/orderPages/changeStatusPages/completeStorePage";
import BackToStockv2               from "./pages/version2pages/orderPages/changeStatusPages/backToStock";
import ReceivedInStockv2           from "./pages/version2pages/orderPages/changeStatusPages/receivedInStock";
import Readyv2                     from "./pages/version2pages/orderPages/changeStatusPages/ready";
import Arrivedv2                   from "./pages/version2pages/returnPages/arrived"
import SendToControlv2             from "./pages/version2pages/returnPages/sendToControlPage"
import ReceivedInControlv2         from "./pages/version2pages/returnPages/receivedInControl"
import Controlv2                   from "./pages/version2pages/returnPages/controlPage/control"
import Defectv2                    from "./pages/version2pages/returnPages/controlPage/defect"
import Maintenancev2               from "./pages/version2pages/returnPages/controlPage/maintenance"
import Restockv2                   from "./pages/version2pages/returnPages/controlPage/restock"
import BigUpcRestockv2             from "./pages/version2pages/productPages/bigUpcRestock/bigUpcRestock";

import ChangeSingleProductStatusv2 from "./pages/version2pages/productPages/changesingleproductstatus";
import ChangeSingleProductStatus   from "./pages/homePages/stockToEntryPages/changesingleproductstatus";
import PickUpOrder                 from "./pages/version2pages/orderPages/pickUpOrderPages/pickUpOrder";
import ScanPickUpOrderv2           from "./pages/version2pages/orderPages/pickUpOrderPages/scanPickUpOrder";
import ReceiveTransfer from "./pages/homePages/transferPages/receiveTransfer";
import UserData from "./pages/version2pages/graphPage";
import GraphPage from "./pages/version2pages/graphPage";
import CheckProduct from "./pages/version2pages/productPages/checkProduct";
import ReceiveTransferScan from "./pages/homePages/transferPages/receiveTransferScan";
import Cookies from "js-cookie";
import ReturnReasonforProduct from "./pages/version2pages/productPages/returnReasonforProduct";
import RefilBin from "./pages/version2pages/productPages/RefilBin";
import ProductToRefil from "./pages/version2pages/productPages/RefilBin/productToRefil";
import Warehouse from "./pages/version2pages/warehousePages/wahrehouseproduct";
import Warehousetransfer from "./pages/version2pages/warehousePages/warehousetransfer";
import ChangeProductAddress from "./pages/version2pages/warehousePages/changeproductaddress";
import WareHouse            from "./pages/version2pages/warehousePages/warehousepage";
import WarehouseProduct from "./pages/version2pages/warehousePages/wahrehouseproduct";
import UpdateBin from "./pages/version2pages/orderPages/changeStatusPages/receivedFromSeller/updateBin";
import ChangeStatusWarehouse from "./pages/version2pages/warehousePages/ChangeStatusWarehouse";

import StatusComponent from "./pages/version2pages/warehousePages/StatusComponent";
import ChangeUpcWarehouse from "./pages/version2pages/warehousePages/ChangeUpcWarehouse";
import ChangeUpcQuantityWarehouse from "./pages/version2pages/warehousePages/ChangeUpcQuantityWarehouse";
import ManageUpcQuantityWarehouse from "./pages/version2pages/warehousePages/ManageUpcQuantityWarehouse";
import CheckUpcWarehouse from "./pages/version2pages/warehousePages/CheckUpcWarehouse";
import UpcRestockWarehouse from "./pages/version2pages/warehousePages/UpcRestockWarehouse";
import ReceiveProductQty from "./pages/version2pages/warehousePages/ReceiveProductQty";
import Refillbinwarehouse from "./pages/version2pages/warehousePages/refillbinwarehouse";
import ProductToRefillWarehouse from "./pages/version2pages/warehousePages/ProductToRefillWarehouse";
function App() {
  const [stateCom, dispatchCom] = useContext(CompanyContext);
  const [stateLogistic, dispatchStateLogistic] = useContext(LogisticContext);
  const [wifiOn, setWifiOn] = useState(false);
  const [wifiOnRemove,setWifiOnRemove] = useState(true);
  const [state, dispatch] = useContext(CompanyContext);
  const history = useNavigate();
  const navigate = useNavigate();
  const location = useLocation();

  // const login = debounce(async () => {
  //   // console.log('Attempting to login');
  //   const username = localStorage.getItem("username");
  //   const password = localStorage.getItem("password");

  //   const obj = {
  //     username,
  //     password,
  //   };
    
  //   if (window.location.host === 'pda.flo-lebanon.com' || window.location.host === "pda.englishhome.com.lb" || window.location.host.startsWith("localhost")) {
  //     obj.web_pda = true;
  //   }
    
  //   try {
  //     const response = await axios.post(buildLink("login"), obj);
  //     if (response.data.success) {
  //       // console.log('Login successful');
  //       // NavigateToFavorite();
  //       dispatchCom({ type: "setUsername", payload: username });
  //       dispatchCom({ type: "setLoged", payload: true });
  //       dispatchCom({ type: "setUserId", payload: response.data.data.user_id });
  //       localStorage.setItem("username", username);
  //       localStorage.setItem("password", password);
  //       localStorage.setItem("userID", response.data.data.user_id);
  //       localStorage.setItem("store_id", response.data.data.store_id);
  //     }
  //   } catch (e) {
  //     console.error(e);
  //   }
  // }, 100);

  // useEffect(() => {
  //      // Check if day has changed and logout if necessary
  //      const storedDay = localStorage.getItem('loginDay');
  //      if (storedDay) {
  //        const currentDay = new Date().getDate();
 
  //        // Compare days
  //        if (parseInt(storedDay) !== currentDay) {
  //          // Perform logout
  //          logout(dispatch, navigate);
  //          return; // Exit the function after logout
  //        }
  //      }

  // }, []);

  // const logout = () => {
  //   // console.log('Logging out');
  //   localStorage.removeItem("username");
  //   localStorage.removeItem("password");
  //   localStorage.removeItem("userID");
  //   dispatchCom({ type: "setUsername", payload: "" });
  //   dispatchCom({ type: "setLoged", payload: false });
  //   dispatchCom({ type: "setUserId", payload: "" });
  //   dispatchCom({ type: "storeList", payload: "" });
  //   navigate("/login", { replace: true });
  // };

  const getLogistic = debounce(async () => {
    // console.log('Fetching logistics');
    const url = buildLink("logistics");
    try {
      const response = await axios.get(url);
      if (response.data.success) {
        const tempLogisticList = response.data.data.map(item => ({
          value: item.id,
          label: item.name
        }));
        dispatchStateLogistic({ type: "setLogistic", payload: tempLogisticList });
      }
    } catch (e) {
      console.error(e);
    }
  }, 1000); // Adjust debounce delay as needed

  // async function getLogistic() {
  //   const url = buildLink("logistics");
  //   await axios.get(url).then((response) => {
  //     if (response.data.success) {
  //       const tempLogisticList = [];
  //       const losisticRes = response.data.data;
  //       losisticRes.map((item) => {
  //         tempLogisticList.push({ value: item.id, label: item.name });
  //       });
  //       dispatchStateLogistic({ type: "setLogistic", payload: tempLogisticList});
  //     }
  //   });
  // }

  // useEffect(() => {
  //   const checkLastAccessDate = () => {
  //     const lastAccessDate = localStorage.getItem('lastAccessDate');
  //     const currentDate = new Date().toDateString();
  //     if (lastAccessDate !== currentDate) {
  //       logout();
  //       localStorage.setItem('lastAccessDate', currentDate);
  //     }
  //   };
  //   checkLastAccessDate();
  // }, [navigate]);

  // useEffect(() => {
  //   const currentDate = new Date().toDateString();
  //   localStorage.setItem('lastAccessDate', currentDate);
  // }, []);

  useEffect(() => {
    const handleOnlineStatusChange = () => {
      setWifiOn(navigator.onLine);
      if (navigator.onLine) {
        setWifiOnRemove(false);
        setTimeout(() => {
          setWifiOnRemove(true);
        }, 10000);
      }
    };
    window.addEventListener("online", handleOnlineStatusChange);
    window.addEventListener("offline", handleOnlineStatusChange);
    setWifiOn(navigator.onLine);
    return () => {
      window.removeEventListener("online", handleOnlineStatusChange);
      window.removeEventListener("offline", handleOnlineStatusChange);
    };
  }, []);


  useEffect(() => {
    getLogistic();
    }, []);

  useEffect(() => {
    // console.log('Running initial setup');
    const host = window.location.host;
    if (host.startsWith("localhost") || host === "pda.ishtari.com" || host === "pda.ishtari.com.gh") {
      dispatchCom({ type: "setCompany", payload: "Ishtari" });
    } else if (host === "energy-plus.com") {
      dispatchCom({ type: "setCompany", payload: "Energy-Plus" });
    } else if (host === "ishtari-usd.com") {
      dispatchCom({ type: "setCompany", payload: "Ishtari-Usd" });
    } else if (host === "pda.flo-lebanon.com") {
      dispatchCom({ type: "setCompany", payload: "Flo" });
    } else if (host === "pda.ishtari.com.gh") {
      dispatchCom({ type: "setCompany", payload: "Ishtari-Ghana" });
    } else if (host === "pda.englishhome.com.lb") {
      dispatchCom({ type: "setCompany", payload: "English-Home" });
    }

    const userCash = localStorage.getItem("username");
    if (!userCash) {
      navigate("/login", { replace: true });
    } else {
      const dateLoged =  Cookies.get("dateLoged");
      const currentDate = new Date().getDate();
      if(dateLoged == currentDate){
        
   }else{
     history("/login", { replace: true });
   }
  
    }
  }, [location.pathname]);


  useEffect(() => {
    // console.log('Checking user ID');
    if (!stateCom.userID) {
      const userIDCache = localStorage.getItem("userID");
      const username = localStorage.getItem("username");
      const password = localStorage.getItem("password");

      if (userIDCache) {
        dispatchCom({ type: "setUsername", payload: username });
        dispatchCom({ type: "setLoged", payload: true });
        dispatchCom({ type: "setUserId", payload: userIDCache });
      }
      
    }
  }, [stateCom.userID]);

  // useEffect(() => {
  //   const host = window.location.host;
  //   const validHosts = ["localhost", "pda.flo-lebanon.com", "pda.englishhome.com.lb"];

  //   if (validHosts.some(validHost => host.startsWith(validHost))) {
  //     if (!stateCom.StoreList || !stateCom.StoreId) {
  //       const storesCache = localStorage.getItem("stores");
  //       const storeidCache = localStorage.getItem("store_id");

  //       if (storesCache) {
  //           dispatch({ type: "setStoreList", payload: storesCache });
  //           dispatch({ type: "setStoreId", payload: storeidCache });
  //           localStorage.setItem('stores', storesCache);
  //           localStorage.setItem("store_id", storeidCache);
  //       }
  //       login();
  //     }
  //   }
  // }, [stateCom.userID, location]);







  return (
    <>
      <Routes>
        <Route path="/"      element={<StartPage />} />
        <Route path="/login" element={<LogIn />} />


        {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        v1 v1 v1 v1 v1  v1 v1 v1 v1 v1 v1 v1 v1 v1 v1  v1 v1 v1 v1 v1 v1 v1 v1 v1 v1  v1 v1 v1 v1 v1 v1 v1 v1 v1 v1  v1 v1 v1 v1 v1 v1 v1 v1 v1 v1  v1 v1 v1 v1 v1 v1 v1 v1 v1 v1  v1 v1 v1 v1 v1
        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

        {/* Version 1 Pages */}

        {/* Home Page */}
        <Route path="/home"                              element={<Home />} />
        <Route path="/home/product"                      element={<Product />} />
        <Route path="home/manageproduct"                 element={<ManageProduct />} />
        <Route path="home/changeupc"                     element={<ChangeUpc />} />
        <Route path="home/changeoptionupc"               element={<ChangeOptionUpc />} />
        <Route path="home/changemainupc"                 element={<ChangeMainUpc />} />
        <Route path="home/order"                         element={<Order />} />
        <Route path="home/upcrestock"                    element={<UpcRestock />} />
        <Route path="home/changeupcquantity"             element={<ChangeUpcQuantity />} />
        <Route path="home/manageupcquantity"             element={<ManageUpcQuantity />} />
        <Route path="home/confirmrestock"                element={<ConfirmRestock />} />
        <Route path="home/changebigupc"                  element={<ChangeBigUpc />} />
        <Route path="home/orderproduct"                  element={<OrderProduct />} />
        <Route path="home/orderproduct/orderproductlist" element={<OrderProductList />}/>
        <Route path="home/ordershistory"                 element={<OrdersHistory />} />
        <Route path="home/jardeh"                        element={<Jardeh />} />
        <Route path="home/stocktoentry"                  element={<StockToEntry />} />
        <Route path="home/transfer"                      element={<Transfer />} />
        <Route path="home/receiveproductquantity"        element={<ReceiveProductQuantity />}/>

        {/* pickup order page */}
        <Route path="home/pickuporder"                      element={<Pickuporder />} />
        {/* <Route path="home/pickuporder/:id"                  element={<PickUpOrderProducts />} /> */}
        <Route path="home/pickuporder/:id/orderproductlist" element={<ScanPickUpOrder />}/>

        {/* upc restock pages */}
        <Route path="home/upcrestock/restockhistory" element={<RestockHistory />}/>

        {/* Return Page */}
        <Route path="home/arrived"           element={<Arrived />} />
        <Route path="home/sendtocontrol"     element={<SendToControl />} />
        <Route path="home/receivedincontrol" element={<ReceivedInControl />} />
        <Route path="home/control"           element={<Control />} />

        {/* controls page */}
        <Route path="home/control/defect"      element={<Defect />} />
        <Route path="home/control/maintenance" element={<Maintenance />} />
        <Route path="home/control/restock"     element={<Restock />} />

        {/* change status pages */}
        <Route path="home/changestatus/receivedfromseller/receivedfromsellerpage"                     element={<RecivedFromSellerPart1 />}/>
        <Route path="home/changestatus/receivedfromseller/receivedfromsellerpage/receivedfromseller2" element={<RecivedFromSellerPart2 />}/>
        <Route path="home/changestatus/receivedfromseller/updateupc"                                  element={<UpdateUpc />}/>
        <Route path="home/changestatus/"                                                              element={<ChangeStatusPage />} />
        <Route path="home/changestatus/given/"                                                        element={<Given />} />
        <Route path="home/changestatus/receivedfromseller/"                                           element={<RecivedFromSeller />}/>
        <Route path="home/changestatus/giventoseller/"                                                element={<GivenToSeller />}/>
        <Route path="home/changestatus/failed/"                                                       element={<Failed />} />
        <Route path="home/changestatus/receivedinstore/"                                              element={<ReceivedInStore />}/>
        <Route path="home/changestatus/completestore/"                                                element={<CompleteStore />}/>
        <Route path="home/changestatus/backtostock/"                                                  element={<BackToStock />}/>
        <Route path="home/changestatus/receivedinstock/"                                              element={<ReceivedInStock />}/>
        <Route path="home/changestatus/ready/"                                                        element={<Ready />} />

        {/* stock to entry pages */}
        <Route path="home/stocktoentry/getorder"                      element={<GetOrderPage />} />
        <Route path="home/stocktoentry/changeproductstatus"           element={<ChangeProductStatus />}/>
        <Route path="home/stocktoentry/changeproductstatus/:name/:id" element={<ChangeSingleProductStatus />}/>
        <Route path="home/stocktoentry/pallet"                        element={<Pallet />} />
        <Route path="home/stocktoentry/bin"                           element={<BinPage />} />

        {/* pallet pages */}
        <Route path="home/stocktoentry/pallet/assign"   element={<Assign />} />
        <Route path="home/stocktoentry/pallet/unassign" element={<Unassign />}/>

        {/* jardeh pages */}
        <Route path="home/jardeh/:idJardeh"                element={<JardehSinglePage />} />
        <Route path="home/jardeh/viewall/:idJardeh"        element={<JardehViewAll />}/>
        <Route path="home/jardeh/jardehAddBadge/:name/:id" element={<JardehAddBatch />}/>
        <Route path="home/test"                            element={<Test />} />

        {/* transfer pages */}
        <Route path="home/transfer/given"  element={<GivenTransfer />} />
        <Route path="home/transfer/ready"  element={<ReadyTransfer />} />
        <Route path="home/transfer/failed" element={<FailedTransfer />} />
        <Route path="home/transfer/receive" element={<ReceiveTransfer />} />
        <Route path="home/transfer/receive/:id/receivescan" element={< ReceiveTransferScan />} />




        {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        v2 v2 v2 v2 v2  v2 v2 v2 v2 v2 v2 v2 v2 v2 v2  v2 v2 v2 v2 v2 v2 v2 v2 v2 v2  v2 v2 v2 v2 v2 v2 v2 v2 v2 v2  v2 v2 v2 v2 v2 v2 v2 v2 v2 v2  v2 v2 v2 v2 v2 v2 v2 v2 v2 v2  v2 v2 v2 v2 v2
        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}

        {/* Version 2 Pages */}
        <Route path="home/v2home/graphpage"                element={<GraphPage />}/>
      


        {/* order */}
        <Route path="/home/v2home"                                         element={<V2Home />} />
        <Route path="/home/v2home/orderpage"                               element={<OrderPage />} />
        <Route path="/home/v2home/orderpage/order"                         element={<OrderSearch />} />
        <Route path="/home/v2home/orderpage/orderproduct"                  element={<OrderProductv2 />}/>
        <Route path="/home/v2home/orderpage/orderproduct/orderproductlistv2" element={<OrderProductListv2 />}/>
        <Route path="/home/v2home/orderpage/ordershistory"                 element={<OrdersHistoryv2 />}/>
        <Route path="/home/v2home/orderpage/getorder"                      element={<GetOrderPagev2 />}/>
        <Route path="/home/v2home/orderpage/changestatus"                  element={<ChangeStatusPagev2 />}/>
        
         {/* pickup order page */}
        <Route path="home/v2home/orderpage/pickuporder"                                     element={<PickUpOrder />} />
        <Route path="home/v2home/orderpage/pickuporder/:id/orderproductlist"                element={<ScanPickUpOrderv2 />}/>

        {/* product */}
        <Route path="/home/v2home/productpage"                                       element={<ProductPage />} />
        <Route path="/home/v2home/productpage/product"                               element={<Productv2 />}/>
        <Route path="/home/v2home/productpage/productnew"                            element={<ProductNew />}/>
        <Route path="/home/v2home/productpage/changeupc"                             element={<ChangeUpcv2 />}/>
        <Route path="/home/v2home/productpage/changeoptionupc"                       element={<ChangeOptionUpcv2 />}/>
        <Route path="/home/v2home/productpage/changebigupc"                          element={<ChangeBigUpcv2 />}/>
        <Route path="/home/v2home/productpage/changemainupc"                         element={<ChangeMainUpcv2 />}/>
        <Route path="/home/v2home/productpage/upcrestock"                            element={<UpcRestockv2 />}/>
        {/* <Route path="/home/v2home/productpage/upcrestock/restockhistory"         element={<BigUpcRestockHistoryv2 />}/> */}
        <Route path="/home/v2home/productpage/changeupcquantity"                     element={<ChangeUpcQuantityv2 />}/>
        <Route path="/home/v2home/productpage/manageupcquantity"                     element={<ManageUpcQuantityv2 />}/>
        <Route path="/home/v2home/productpage/checkupc"                              element={<ConfirmRestockv2 />}/>
        <Route path="/home/v2home/productpage/bin"                                   element={<BinPagev2 />} />
        <Route path="/home/v2home/productpage/refilbin"                              element={<RefilBin />} />
        <Route path="/home/v2home/productpage/refilbin/:sku"                              element={<ProductToRefil />} />
        <Route path="/home/v2home/productpage/checkproduct"                          element={<CheckProduct />} />
        <Route path="/home/v2home/productpage/changeproductstatus"                   element={<ChangeProductStatusv2 />}/>
        <Route path="/home/v2home/productpage/changeproductstatus/:name/:id"         element={<ChangeSingleProductStatusv2 />}/>
        <Route path="/home/v2home/productpage/jardeh"                                element={<Jardehv2 />} />
        <Route path="home/v2home/productpage/jardeh/:idJardeh"                element={<JardehSinglePage />} />

        <Route path="/home/v2home/productpage/receiveproductquantity"                element={<ReceiveProductQuantityv2 />}/>
        <Route path="/home/v2home/productpage/bigupcrestock"                         element={<BigUpcRestockv2 />}/>
        <Route path="/home/v2home/productpage/bigupcrestock/restockhistory"          element={<RestockHistoryv2 />}/>
        <Route path="/home/v2home/productpage/upcrestock/restockhistory"             element={<RestockHistoryv2 />}/>
        <Route path="/home/v2home/productpage/receiveproductquantity/restockhistory" element={<RestockHistoryv2 />}/>

        {/* others */}
        <Route path="/home/v2home/otherpage" element={<OtherPage />} />
        {/* pallet */}
        <Route path="/home/v2home/otherpage/pallet"          element={<Palletv2 />} />
        <Route path="/home/v2home/otherpage/pallet/assign"   element={<Assignv2 />}/>
        <Route path="/home/v2home/otherpage/pallet/unassign" element={<Unassignv2 />}/>

        {/* change status pages */}
        <Route path="home/v2home/orderpage/changestatus"                                                               element={<ChangeStatusPage />} />
        <Route path="home/v2home/orderpage/changestatus/given"                                                         element={<Givenv2 />} />
        <Route path="home/v2home/orderpage/changestatus/receivedfromseller"                                            element={<RecivedFromSellerv2 />}/>
        <Route path="home/v2home/orderpage/changestatus/receivedfromseller/receivedfromsellerpage"                     element={<RecivedFromSellerPart1v2 />}/>
        <Route path="home/v2home/orderpage/changestatus/receivedfromseller/receivedfromsellerpage/receivedfromseller2" element={<RecivedFromSellerPart2v2 />}/>
        <Route path="home/v2home/orderpage/changestatus/receivedfromseller/updatebin"                                  element={<UpdateBin />}/>
        <Route path="home/v2home/orderpage/changestatus/giventoseller"                                                 element={<GivenToSellerv2 />}/>
        <Route path="home/v2home/orderpage/changestatus/failed/"                                                       element={<Failedv2 />} />
        <Route path="home/v2home/orderpage/changestatus/receivedinstore"                                               element={<ReceivedInStorev2 />}/>
        <Route path="home/v2home/orderpage/changestatus/completestore"                                                 element={<CompleteStorev2 />}/>
        <Route path="home/v2home/orderpage/changestatus/backtostock/"                                                  element={<BackToStockv2 />}/>
        <Route path="home/v2home/orderpage/changestatus/receivedinstock/"                                              element={<ReceivedInStockv2 />}/>
        <Route path="home/v2home/orderpage/changestatus/ready/"                                                        element={<Readyv2 />} />



        {/* return pages  */}
        <Route path="home/v2home/arrived"             element={<Arrivedv2 />} />
        <Route path="home/v2home/sendtocontrol"       element={<SendToControlv2 />} />
        <Route path="home/v2home/receivedincontrol"   element={<ReceivedInControlv2 />} />
        <Route path="home/v2home/control"             element={<Controlv2 />} />
        <Route path="home/v2home/rrfp"                element={<ReturnReasonforProduct />}/>

        {/* control  */}
        <Route path="home/v2home/control/defect"                element={<Defectv2 />} />
        <Route path="home/v2home/control/maintenance"           element={<Maintenancev2 />} />
        <Route path="home/v2home/control/restock"               element={<Restockv2 />} />
        <Route path="home/v2home/control/:name/:id/"            element={<Restockv2 />} />





        {/* Warehouse Pages */}
        <Route path="/home/v2home/warehousepage"                                       element={<WareHouse />} />
        <Route path="home/v2home/warehousepage/WarehouseProduct"         element={<WarehouseProduct/>}/>
        <Route path="home/v2home/warehousepage/Warehousetransfer" element={<Warehousetransfer/>}/>
        <Route path="home/v2home/warehousepage/ChangeUpcWarehouse" element={<ChangeUpcWarehouse/>}/>
        <Route path="home/v2home/warehousepage/ChangeUpcQuantityWarehouse" element={<ChangeUpcQuantityWarehouse/>}/>
        <Route path="home/v2home/warehousepage/ManageUpcQuantityWarehouse" element={<ManageUpcQuantityWarehouse/>}/>
        <Route path="home/v2home/warehousepage/CheckUpcWarehouse" element={<CheckUpcWarehouse/>}/>
        <Route path="home/v2home/warehousepage/UpcRestockWarehouse" element={<UpcRestockWarehouse/>}/>
        <Route path="home/v2home/warehousepage/ReceiveProductQty" element={<ReceiveProductQty/>}/>
        <Route path="home/v2home/warehousepage/Changeproductaddress" element={<ChangeProductAddress />} />
        <Route path="home/v2home/warehousepage/ChangeStatusWarehouse" element={<ChangeStatusWarehouse/>}/>
        <Route path="home/v2home/warehousepage/ChangeStatusWarehouse/:name" element={<StatusComponent/>}/>
        <Route path="home/v2home/warehousepage/refillbinwarehouse" element={<Refillbinwarehouse/>}/>
        <Route path="home/v2home/warehousepage/refillbinwarehouse/:sku" element={<ProductToRefillWarehouse/>}/>
        
        
      </Routes>



{!wifiOn && (
    <div className=" fixed bg-dblackOverlay w-full h-full z-[999] left-0 bottom-0 right-0 top-0">
      <div className=" fixed  container  bottom-5 w-full h-16 ">
        <div className=" relative px-2  bg-dbase w-full h-full flex justify-start divide-x-2 divide-dgreyRate rounded-md text-white ">
          <div className=" my-auto px-5">
            {" "}
            <MdOutlineWifiOff className=" text-3xl" />
          </div>
          <p className=" my-auto px-3">You are Offline </p>{" "}
        </div>
      </div>
    </div>
  )}

{wifiOn && !wifiOnRemove && (
    <div className=" fixed h-fit w-fit z-[999] left-0 bottom-0 right-0 top-0">
      <div className=" fixed  container  bottom-5 w-full h-16 ">
        <div className=" relative px-2 bg-dgreen w-full h-full flex justify-start divide-x-2 divide-dgreyRate rounded-md text-white ">
          <div className=" my-auto px-5">
            {" "}
            <FaWifi className=" text-3xl" />
          </div>
          <p className=" my-auto px-3">Back Online </p>{" "}
        </div>
      </div>
    </div>
  )}
    </>
  );
}

export default App;

