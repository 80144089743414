import React, { useRef, useState, useEffect } from "react";
import { FaArrowLeft, FaSearch, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Loader from "../../../../components/loader";
import MessageAlert from "../../../../components/messageAlert";
import { PlaySounds } from "../../../../functions/playSounds";
import buildLink from "../../../../urls";
import { useLocation } from "react-router-dom";
import ReactPaginate from "react-paginate";

const PAGE_SIZE = 10;

const RestockHistoryv2 = () => {
  const { playErrorSound } = PlaySounds();
  const history = useNavigate();
  const codePr = useRef(null);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [showMessgErr, setShowMessageErr] = useState(false);
  const [message, setMessage] = useState("");
  const [showMessgSucc, setShowMessageSucc] = useState(false);
  const [productHistory, setProductHistory] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const location = useLocation();
  const codePrValue = new URLSearchParams(location.search).get("codePr");

  const [activeTab, setActiveTab] = useState(0); 

  const getProductHistory = async (e, page = 1, historyType) => {
    if (e) {
      e.preventDefault();
    }

    setLoadingSearch(true);

    const item = codePr.current.value;

    try {
      const url =
        buildLink("getProductHistory") +
        `&label=${item}&v2=true&page=${page}&history_type=${historyType}`;
      const response = await axios.get(url);

      if (response.data.success) {
        codePr.current.focus();
        setProductHistory(response.data.data.histories);
        setTotalPages(response.data.data.total_pages);
        if (response.data.data.histories.length === 0) {
          setLoadingSearch(false);
          setMessage("There are no histories for this product");
          setShowMessageErr(true);
          setTimeout(() => {
            setShowMessageErr(false);
          }, 3000);
        }
      }
    } catch (error) {
      playErrorSound();
      setLoadingSearch(false);
      setMessage(error.response?.data?.message || "An error occurred");
      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
    }

    setLoadingSearch(false);
  };

  useEffect(() => {
    if (codePrValue) {
      codePr.current.value = codePrValue;
      getProductHistory(null, currentPage, mapHistoryType(activeTab));
    }
  }, [codePrValue, currentPage, activeTab]); // Add activeTab to dependencies

  useEffect(() => {
    codePr.current.focus();
  }, []);

  const handleTrashClick = () => {
    codePr.current.value = "";
    codePr.current.focus();
  };

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
    getProductHistory(
      null,
      selectedPage.selected + 1,
      mapHistoryType(activeTab)
    );
  };

  const handleTabChange = (value) => {
    setActiveTab(value);
    setCurrentPage(1); // Reset to first page on tab change
    getProductHistory(null, 1, mapHistoryType(value));
  };

  const mapHistoryType = (tabIndex) => {
    switch (tabIndex) {
      case 0:
        return "all";
      case 1:
        return "order";
      case 2:
        return "assign";
      case 3:
        return "unassign";
      case 4:
        return "restock";
      case 5:
        return "refill";
      default:
        return "all";
    }
  };

  const renderProductHistory = () => {
    if (!productHistory) {
      return null;
    }

    const productHistoryElements = productHistory.map((ph) => {
      const formattedDate = new Date(ph.date_added).toLocaleDateString(
        "en-US",
        {
          weekday: "short",
          month: "short",
          day: "numeric",
          year: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        }
      );

      return (
        <div className="container" key={ph.id}>
          <div className="bg-white rounded-md shadow-md w-full px-3 py-2 mt-10 border">
            <h2
              className={`ml-auto text-center ${ph.username ? "border-b" : ""}`}
            >
              {ph.username}
            </h2>
            <div className="flex flex-row gap-5">
              <div className="my-auto">
                <div className="flex flex-col justify-center m-1">
                  <h2 className="m-1 mt-7">
                    Product {ph.type} : {ph.type_id}
                  </h2>
                  <h2 className="m-1">SKU : {ph.sku}</h2>
                  {ph.option_value && (
                    <h2 className="m-1">Option: {ph.option_value}</h2>
                  )}
                  <h2 className="m-1">
                    Restock Quantity : {ph.restock_quantity}
                  </h2>
                  {ph.comment && (
                    <h2 className="m-1 text-dbase ">
                      <strong className="text-dblack"> Comment :</strong>{" "}
                      {ph.comment}
                    </h2>
                  )}
                  <h2 className="m-1">History Type : {ph.history_type}</h2>
                  <h2 className="m-1 mt-7">{formattedDate}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });

    return productHistoryElements;
  };

  return (
    <>
      <div className="h-full bg-white">
        <div className="mb-5 sticky top-0 flex flex-col bg-white z-50">
          <div className="w-full bg-dbase h-16">
            <div className="container px-3 relative flex justify-between w-full text-white h-full my-auto">
              <div className="flex gap-7 flex-row justify-start">
                <button onClick={() => history(-1)}>
                  <FaArrowLeft />
                </button>
                <h2 className="text-xl my-auto">Restock History</h2>
              </div>
            </div>
          </div>
        </div>

        <div className="container flex flex-col gap-5">
          <form onSubmit={(e) => getProductHistory(e)}>
            <div className="flex flex-row justify-center gap-3">
              <div className="relative flex-1">
                <input
                  ref={codePr}
                  type="text"
                  className="border border-dlabelColor outline-dbase w-full rounded-md py-3 pr-8 pl-2"
                  placeholder="Enter SKU"
                />
                <div>
                  <FaTrash
                    onClick={() => handleTrashClick()}
                    className="absolute top-1/2 transform -translate-y-1/2 right-3 cursor-pointer opacity-20 "
                  />
                </div>
              </div>
              <button className="bg-dbase rounded-md text-white px-5 ">
                {loadingSearch ? <Loader /> : <FaSearch />}
              </button>
            </div>
          </form>
        </div>

        {productHistory && (
          <div className="md:mx-60 xs:mx-3">
          <div className="flex justify-between mb-5 mt-5 border border-dblackk w-full p-1 mx-auto max-sm:w-full max-sm:p-1 ">
            <button
              className={`tab flex-1 ${
                activeTab === 0
                  ? "bg-dbase text-white p-2 md:p-2 md:text-xl text-sm"
                  : "md:p-2 p-1 text-sm md:text-xl border-r"
              }`}
              onClick={() => handleTabChange(0)}
            >
              All
            </button>
            <button
              className={`tab flex-1 ${
                activeTab === 1
                  ? "bg-dbase text-white p-2 md:p-2 md:text-xl text-sm"
                  : "md:p-2 p-1 text-sm md:text-xl border-r"
              }`}
              onClick={() => handleTabChange(1)}
            >
              Order
            </button>
            <button
              className={`tab flex-1 ${
                activeTab === 2
                  ? "bg-dbase text-white p-2 md:p-2 md:text-xl text-sm"
                  : "md:p-2 p-1 text-sm md:text-xl border-r"
              }`}
              onClick={() => handleTabChange(2)}
            >
              Assign
            </button>
            <button
              className={`tab flex-1 ${
                activeTab === 3
                  ? "bg-dbase text-white p-2 md:p-2 md:text-xl text-sm"
                  : "md:p-2 p-1 text-sm md:text-xl border-r"
              }`}
              onClick={() => handleTabChange(3)}
            >
              Unassign
            </button>
            <button
              className={`tab flex-1 ${
                activeTab === 4
                  ? "bg-dbase text-white p-2 md:p-2 md:text-xl text-sm"
                  : "md:p-2 p-1 text-sm md:text-xl border-r"
              }`}
              onClick={() => handleTabChange(4)}
            >
              Restock
            </button>
            <button
              className={`tab flex-1 ${
                activeTab === 5
                  ? "bg-dbase text-white p-2 md:p-2 md:text-xl text-sm"
                  : "md:p-2 p-1 text-sm md:text-xl"
              }`}
              onClick={() => handleTabChange(5)}
            >
              Refill
            </button>
          </div>
          </div>
        )}

        {loadingSearch ? (
          ""
        ) : (
          <div className="product-history">{renderProductHistory()}</div>
        )}

        {/* Pagination */}
        <div className="flex justify-center mt-10 pagination-container mb-10">
          <ReactPaginate
            className={"category-pagination"}
            pageCount={totalPages}
            pageRangeDisplayed={1}
            marginPagesDisplayed={1}
            onPageChange={handlePageChange}
            containerClassName={"pagination-buttons"}
            subContainerClassName={"pages pagination"}
            activeClassName={"bg-dbase bg-opacity-30"}
            breakClassName={"border-t border-b mx-2"}
            nextLabel=">"
            previousLabel="<"
            breakLabel="..."
            forcePage={currentPage - 1} 
            renderOnZeroPageCount={null}
          />
        </div>

        <MessageAlert
          message={message}
          type={showMessgErr ? "err" : "succ"}
          showMessg={showMessgErr || (showMessgSucc && true)}
        />
      </div>
    </>
  );
};

export default RestockHistoryv2;
