import React, { useContext, useEffect, useRef, useState } from "react";
import { FaArrowLeft, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import buildLink from "../../../urls";
import axios from "axios";
import { CompanyContext } from "../../../contexts/companyCotext";
import { LogisticContext } from "../../../contexts/logisticContext";
import Loader from "../../../components/loader";
import MessageAlert from "../../../components/messageAlert";
import hocStarredPage from "../../../components/hocStarredPage";

const ReceiveTransfer = () => {
  const transferId = useRef(null);
  const [stateCom, dispatchCom] = useContext(CompanyContext);
  const [stateLogistic, dispatchStateLogistic] = useContext(LogisticContext);
  const [showMessgSucc, setShowMessageSucc] = useState(false);
  const [message, setMessage] = useState("");
  const [showMessgErr, setShowMessageErr] = useState(false);
  const [loading, setLoading] = useState(false);
  const [transferInfo, setTransferInfo] = useState(null);
  const [host, setHost] = useState(window.location.host);
  const history = useNavigate();

  useEffect(() => {
    setHost(window.location.host);
  }, [host]);


  useEffect(() => {
    transferId.current.focus();
  }, []);

  const getUserIdFromLocalStorage = () => {
    return localStorage.getItem("userID");
  };

  const updateTransfer = async (e) => {
    setLoading(true);
    e.preventDefault();
    const userID = getUserIdFromLocalStorage();
    try {
      const url =
        buildLink("getTransfer") +
        `&transfer_id=${transferId.current.value}` +
        `&user_id=${userID}`;
      await axios.get(url).then((res) => {
        if (res.data.success) {
          setLoading(false);
          setTransferInfo(res.data.transfer_info);
          // console.log(res.data.transfer_info)
        } else {
          setLoading(false);
          setMessage(res.data.message);
          setShowMessageErr(true);
          setTimeout(() => {
            setShowMessageErr(false);
          }, 3000);
        }
      });
    } catch (e) {
      setLoading(false);
      setMessage(e.response.data.message);
      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
    }
  };

  const handleTrashClick = () => {
    transferId.current.value = "";
    transferId.current.focus();
  };

  return (
    <div className=" h-full bg-white">
      {/* header */}
      <div className=" mb-5 sticky top-0 flex flex-col bg-white">
        <div className=" w-full bg-dbase h-16">
          <div className=" container flex relative gap-7 flex-row justify-start w-full text-white  h-full my-auto">
            <button onClick={() => history(-1)}>
              <FaArrowLeft />
            </button>
            <h2 className=" text-xl    my-auto">Receive Transfer</h2>
          </div>
        </div>
      </div>
      <div className=" container">
        <div className="-mt-2 -mb-2">Transfer ID</div>
        <form onSubmit={(e) => updateTransfer(e)} className="gap-2 flex py-3">
          <div className="relative flex-1">
            <input
              ref={transferId}
              type="text"
              className="border-2 w-full rounded-md border-dbase py-2 pr-8 pl-2"
            />
            <FaTrash
              className="absolute top-1/2 transform -translate-y-1/2 right-2 cursor-pointer opacity-20 "
              onClick={handleTrashClick}
            />
          </div>
          <div
            onClick={(e) => {
              updateTransfer(e);
            }}
            className="w-fit cursor-pointer px-5 text-white text-center rounded-md bg-dbase"
          >
            <button className="flex  whitespace-nowrap  items-center justify-center h-full">
              {loading ? <Loader /> : "Search"}
            </button>
          </div>
        </form>
      </div>

      {transferInfo && (
        <>
          <div className="flex justify-center items-center mt-10">
            <div className="min-w-full bg-white shadow-md rounded">
              <div className="py-2 px-4 flex">
                {" "}
                <div className=" font-bold">From Store:&nbsp;</div>{" "}
                {transferInfo.from_store}
              </div>
              <div className="py-2 px-4 flex">
                {" "}
                <div className=" font-bold">To Store:&nbsp;</div>{" "}
                {transferInfo.to_store}
              </div>
              <div className="py-2 px-4 flex">
                {" "}
                <div className=" font-bold">Total:&nbsp;</div>{" "}
                {transferInfo.total}
              </div>
            </div>
          </div>

          <div className="w-full h-16 flex items-center justify-center cursor-pointer text-white text-center mt-2">
            <button
              onClick={() => history(`${transferId.current.value}/receivescan`)}
              className="flex whitespace-nowrap items-center justify-center h-full w-full bg-dbase"
            >
              {loading ? <Loader /> : "Start"}
            </button>
          </div>
        </>
      )}

      <MessageAlert
        message={message}
        type={showMessgErr ? "err" : "succ"}
        showMessg={showMessgErr || (showMessgSucc && true)}
      />
    </div>
  );
};
export default hocStarredPage(ReceiveTransfer);
